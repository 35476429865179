@import "~style";

$block-name: 'b-header';

.#{$block-name} {
	height: 640px;
	margin-top: -120px;
	margin-bottom: 54px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	@include breakpoint('medium') {
		margin-bottom: 85px;
		height: 800px;
		margin-top: -104px;
		background-position: 70%;
	}

	@include breakpoint('large') {
		margin-bottom: 128px;
		height: 640px;
		background-position: center;
		margin-top: -139px;
	}

	@include breakpoint('xlarge') {
		height: 740px;
	}

	&__content {
		padding-top: 100px;

		@include breakpoint('medium') {
			padding-top: 100px;
		}

		@include breakpoint('large') {
			padding-top: 120px;
		}
	}

	h1 {
		margin: 0;
		padding: 0;
		color: $color-white;
		margin-top: 125px;
		max-width: 618px;

		@include breakpoint('medium') {
			margin-top: 190px;
		}

		@include breakpoint('large') {
			margin-top: 130px;
		}
	}

	&__description {
		color: $color-white;
		margin-top: 16px;
		max-width: 390px;

		@include breakpoint('medium') {
			margin-top: 32px;
		}

		@include breakpoint('large') {
			margin-top: 24px;
		}
	}
}
