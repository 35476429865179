@import "~style";

$block-name: 'b-tags';

.#{$block-name} {
	@include breakpoint(small) {
		// margin-bottom: 20px;

		&__tag {
			font-size: 16px;
			font-weight: 700;
			text-transform: lowercase;
			color: $color-blue-light;

			&:hover {
				color: $color-gray-content;
			}

			&--color-theme_grey {
				color: $color-gray-content;

				&:hover {
					color: $color-blue-light;
				}
			}

			&--color-theme_green {
				color: $color-blue-light;

				&:hover {
					color: $color-gray-content;
				}
			}
		}
	}

	@include breakpoint(xlarge) {
		&__tag {
			&--color-theme_white {
				color: white;

				&:hover {
					color: $color-blue-light;
				}
			}
		}
	}
}
