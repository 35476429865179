@import "~style";

$block-name: 'b-clients';

.#{$block-name} {
	position: relative;
	background-color: $color-light-blue;

	&__item {
		background-color: $color-white;
		transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto;
		opacity: 1;

		&::after {
			content: "";
			display: block;
			padding-bottom: 92%;
		}

		@include breakpoint('large') {
			&:hover {
				background-color: $color-blue;

				img {
					display: none;
				}

				.#{$block-name}__content {
					visibility: visible;
					display: flex;
				}
			}
		}

		img {
			filter: invert(0.8);
			max-width: 51%;
			height: auto;
		}

		&--projects {
			align-items: flex-start;
			min-height: 320px;
			flex-direction: column;
			justify-content: space-between;
			text-decoration: none;

			&::after {
				content: "";
				display: none;
			}

			.#{$block-name}__content {
				position: relative;
				visibility: visible;
				display: block;
				padding: 0 32px 35px;
			}

			.#{$block-name}__title {
				display: none;
			}

			.#{$block-name}__image {
				width: 100%;
				height: 290px;
				display: flex;
				align-items: center;
				justify-content: flex-start;

				@include breakpoint('medium') {
					height: 100%;
					align-items: flex-start;
					justify-content: flex-start;
				}

				@include breakpoint('large') {
					align-items: center;
					justify-content: center;
					flex-direction: column;
				}
			}

			.#{$block-name}__description {
				&.c-body-text {
					color: $color-dark-grey;
					font-size: 14px;
					opacity: 0.9;
				}

				&.top {
					color: $color-dark-grey !important;
					margin-top: 30px;
					padding: 0 25px;
					text-align: center;
					display: none;

					@include breakpoint('large') {
						display: block;
					}
				}
			}

			img {
				padding: 0 32px 10px 32px;
				max-width: 80%;

				@include breakpoint('medium') {
					max-width: 70%;
				}
			}

			@include breakpoint('medium') {
				img {
					padding: 37px 32px 10px 32px;
				}
			}

			@include breakpoint('large') {
				min-height: 300px;

				&::after {
					content: "";
					display: none;
				}

				.#{$block-name}__content {
					position: relative;
					visibility: visible;
					display: block;
					padding: 0 40px 40px;
				}

				img {
					padding: 40px 40px 10px 40px;
					max-width: 80%;

					@include breakpoint('medium') {
						max-width: 70%;
					}
				}
			}

			@include breakpoint('large') {
				align-items: center;
				flex-direction: row;
				justify-content: center;
				text-decoration: none;

				&::after {
					content: "";
					display: block;
					padding-bottom: 92%;
				}

				.#{$block-name}__content {
					position: absolute;
					visibility: visible;
					display: none;
					padding: 0 40px;
				}

				.#{$block-name}__title {
					display: none;

					&.top {
						display: block;
						color: black;
					}
				}

				.#{$block-name}__description {
					&.c-body-text {
						color: $color-white;
						font-size: 14px;
						opacity: 0.9;
					}
				}

				&:hover {
					background-color: $color-blue;
					transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1);

					img {
						display: none;
					}

					.#{$block-name}__description.top {
						display: none;
					}

					.#{$block-name}__content {
						visibility: visible;
						display: flex;
					}

					.#{$block-name}__title {
						display: block;
					}
				}

				img {
					padding: 0;
					filter: invert(0.8);
					max-width: 51%;
					height: auto;
				}
			}
		}
	}

	.clientsSwiper {
		margin: 40px 0 0;

		@include breakpoint('medium') {
			margin: 48px 0 0;
		}

		@include breakpoint('large') {
			margin: 69px 0 0;
		}

		@include breakpoint('xlarge') {
			margin: 64px 0 0;
		}
	}

	.swiper-wrapper {
		z-index: 10;
		flex-direction: row;
		align-items: end;
		height: auto;
	}

	&__container {
		position: relative;
		z-index: 20;
		display: flex;
		justify-content: space-between;
	}

	&__swiper-btn {
		display: flex;
	}

	&__swiper-next {
		display: none;
		cursor: pointer;

		@include breakpoint('medium') {
			display: block;
		}
	}

	&__swiper-prev {
		cursor: pointer;
		display: none;
		margin-right: 40px;

		@include breakpoint('medium') {
			display: block;
		}
	}

	&__pagination {
		margin-top: 56px;
		position: relative;

		& .swiper-pagination-bullet {
			&-active {
				// transition: width 0.6s;
				width: 48px;
				border-radius: 4px;
			}
		}
	}

	&__content {
		position: absolute;
		width: 100%;
		color: $color-white;
		padding: 0 40px;
		flex-direction: column;
		visibility: hidden;
		display: none;
		box-sizing: border-box;

		@include breakpoint('xlarge') {
			padding: 0 40px;
		}
	}

	&__title {
		color: $color-white;
		font-size: 24px;
	}

	&__description {
		&.c-body-text {
			color: $color-white;
			opacity: 1;
			font-size: 16px !important;
		}

		margin-top: 8px;
	}

	.c-active-title {
		& a {
			color: $color-dark-grey;
			text-decoration: none;
		}
	}

	// &__circle-left {
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-repeat: no-repeat;
	// 	display: none;

	// 	@include breakpoint('medium') {
	// 		background-position: top 187px left 0;
	// 		background-image: url('/images/new.croc.img/Vector (1).svg');
	// 		display: block;
	// 	}

	// 	@include breakpoint('large') {
	// 		background-position: top 202px left 0;
	// 		background-image: url('/images/new.croc.img/Group 4002.svg');
	// 	}
	// }

	// &__circle-bottom {
	// 	position: absolute;
	// 	background-image: url('/images/new.croc.img/Group 4002 (1).svg');
	// 	width: 100%;
	// 	height: 100%;
	// 	background-position: bottom 0 right 0;
	// 	background-repeat: no-repeat;

	// 	@include breakpoint('medium') {
	// 		background-position: bottom 0 right 65px;
	// 		background-image: url('/images/new.croc.img/Vector.svg');
	// 	}

	// 	@include breakpoint('large') {
	// 		background-position: bottom 0 right 153px;
	// 	}

	// 	@include breakpoint('xlarge') {
	// 		background-position: bottom 0 right 276px;
	// 	}
	// }

	&__link-project {
		position: relative;
		z-index: 20;
		margin-top: 28px;
		color: $color-dark-grey;
		text-decoration: none;
		border-bottom: 2px solid #08b3f0;
		display: inline-block;
		cursor: pointer;

		&:hover {
			border-bottom: 2px solid transparent;
		}
	}
}
