@import "~style";

$block-name: 'b-section-top';

.#{$block-name} {
	height: 100%;
	opacity: 1;

	@include breakpoint(small) {
		&__article {
			&--numb-2 {
				.b-teaser {
					&__image-container {
						display: none;
					}

					&__article-title {
						@include teaser-article-title--narrow();
					}
				}
			}

			&--numb-3 {
				.b-teaser {
					&__preview-text {
						display: none;
					}

					&__image-container {
						display: none;
					}

					&__article-title {
						@include teaser-article-title--narrow();
					}
				}
			}
		}

		&__container {
			@include xy-cell(full);
			@include xy-grid();
		}
	}

	@include breakpoint(medium) {
		&__container {
			@include xy-grid($direction: horizontal);
		}

		&__article--containerized {
			@include xy-cell();
		}
	}

	@include breakpoint(large) {
		&__article {
			&--wide {
				.b-teaser {
					&__image-container {
						height: 306px;
					}
				}
			}
		}

		&__container {
			@include xy-cell(4);
			@include xy-grid($direction: vertical);
		}

		&__article--containerized {
			@include xy-cell();
		}
	}

	@media (max-width: 1024px) {
		&__article {
			&--numb-1 {
				.b-teaser__image-container {
					height: auto !important;
				}

				.b-teaser__image {
					width: 100% !important;
					height: auto !important;
				}
			}
		}
	}
}
