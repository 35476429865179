@mixin wysiwyg () {
	h1,
	.h1,
	.c-h1 {
		color: $color-dark-grey;
		font-weight: $global-weight-regular;
		line-height: 130%;
		font-size: 24px;
		padding-bottom: 2rem;
		font-family: 'Magistral', arial, sans-serif;

		@include breakpoint('medium') {
			font-size: 48px;
			line-height: 130%;
		}
	}

	h1.big_h1,
	.big_h1 {
		color: $color-white;
		font-weight: $global-weight-regular;
		line-height: 1;
		line-height: 40px;
		font-size: 30px;
		margin: 0;
		font-family: 'Magistral', arial, sans-serif;

		@include breakpoint('medium') {
			font-size: 64px;
			line-height: 70px;
		}
	}

	h2,
	.h2,
	.c-h2 {
		margin: 0;
		color: $color-dark-grey;
		line-height: 130%;
		font-size: 24px;
		margin-bottom: 20px;
		font-weight: $global-weight-regular;
		font-family: 'Magistral', arial, sans-serif;
	}

	h2.big_h2,
	.big_h2 {
		margin: 0;
		color: $color-dark-grey;
		line-height: 35.4px;
		font-size: 34px;
		font-weight: $global-weight-regular;
		margin-bottom: 20px;
		font-family: 'Magistral', arial, sans-serif;

		@include breakpoint('medium') {
			font-size: 48px;
			line-height: 62.4px;
		}
	}

	.h3,
	.c-h3 {
		color: $color-dark-grey;
		font-size: 16px;
		line-height: 130%;
		margin: 0;
		padding: 25px 0;
		font-weight: $global-weight-regular;
		font-family: 'Magistral', arial, sans-serif;
	}

	h3,
	h3.big_h3,
	.big_h3 {
		color: $color-dark-grey;
		font-size: 24px;
		line-height: 33.6px;
		margin: 0;
		margin-bottom: 20px;
		font-weight: $global-weight-regular;
		font-family: 'Magistral', arial, sans-serif;
	}

	h4,
	.h4,
	.c-h4 {
		color: $color-dark-grey;
		font-weight: $global-weight-regular;
		font-size: 16px;
		line-height: 130%;
		margin: 0;
		font-family: 'Magistral', arial, sans-serif;
	}

	big,
	.big,
	.c-big {
		color: $global-weight-regular;
		font-size: 16px;
		line-height: 130%;

		@include breakpoint('large') {
			font-size: 24px;
			line-height: 130%;
		}
	}

	.magistral {
		font-family: 'Magistral', arial, sans-serif;
	}

	b,
	strong {
		font-weight: $global-weight-bold;
	}

	a {
		color: $color-blue;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	p {
		padding-bottom: 16px;

		@include breakpoint('medium') {
			padding-bottom: 24px;
		}
	}

	ul {
		li {
			margin-bottom: 24px;

			&::marker {
				color: $color-blue;
			}
		}

		ul {
			padding-left: 20px;
		}
	}

	ol {
		margin-top: 32px;
		margin-left: 20px;
		// list-style: none;
		counter-reset: my-awesome-counter;

		ul {
			padding-left: 20px;
		}

		ul li {
			padding-left: 0;
		}

		li {
			margin-bottom: 24px;
			counter-increment: my-awesome-counter;
			padding-left: 24px;

			&::marker {
				color: $color-blue;
				font-weight: bold;
				font-size: 16px;
			}

			// &::before {
			// 	padding-right: 24px;
			// 	content: counter(my-awesome-counter) ". ";
			// 	color: $color-blue;
			// 	font-weight: 700;
			// }
		}
	}

	.c-right {
		float: right;
		margin: 0 0 10px 10px;
	}

	.c-left {
		float: left;
		margin: 0 10px 10px 0;
	}

	.c-black {
		color: $color-black;
	}

	.c-gray {
		color: $color-dark-grey;
	}

	.c-hidden {
		display: none;
	}

	.c-button-video {
		background-image: url('/images/new.k2.img/play.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		width: 56px;
		height: 56px;

		@include breakpoint('medium') {
			width: 104px;
			height: 104px;
		}

		@include breakpoint('large') {
			width: 136px;
			height: 136px;
		}
	}

	@each $name, $value in $breakpoints {
		.c-#{$name}-hidden {
			@include breakpoint(#{$name} only) {
				display: none;
			}
		}
	}

	.c-padding-top {
		padding-top: 88px;

		@include breakpoint('medium') {
			padding-top: 104px;
		}

		@include breakpoint('large') {
			padding-top: 128px;
		}
	}

	.c-padding-bottom {
		padding-bottom: 88px;

		@include breakpoint('medium') {
			padding-bottom: 104px;
		}

		@include breakpoint('large') {
			padding-bottom: 128px;
		}
	}

	.c-padding-top-small {
		padding-top: 0;
		margin-top: -30px;
	}

	.c-padding-bottom-small {
		padding-bottom: 0;
	}

	.c-body-text {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		opacity: 0.9;
		color: $color-dark-grey;
	}

	.c-small_text {
		font-size: 12px;
		line-height: 18px;
		font-weight: 700;
		color: $color-blue;
	}

	.c-button-blue-light {
		text-decoration: none;
		display: inline-block;
		color: $color-white;
		background-color: $color-blue;
		padding: 17.5px 24px;
		margin-right: 24px;
		border-radius: 3px;
		border: 1px solid transparent;
		transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);

		&:hover {
			color: $color-dark-grey;
			transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
			background-color: transparent;
			// padding: 16.5px 23px;
			border: 1px solid $color-blue;
		}
	}

	.c-button-white {
		text-decoration: none;
		display: inline-block;
		color: $color-dark-grey;
		background-color: $color-white;
		padding: 17.5px 24px;
		margin-right: 24px;
		border-radius: 3px;
		transition: background-color background-color 0.4s;

		&:hover {
			color: $color-white;
			transition: background-color background-color 0.4s;
			background-color: transparent;
			padding: 16.5px 23px;
			border: 1px solid $color-white;
		}
	}

	.c-button-gray {
		text-decoration: none;
		display: inline-block;
		color: $color-dark-grey;
		background-color: $color-white;
		padding: 17.5px 24px;
		margin-right: 24px;
		border-radius: 3px;
		transition: background-color background-color 0.4s;

		&:hover {
			color: $color-white;
			transition: background-color background-color 0.4s;
			background-color: $color-blue;
			padding: 16.5px 23px;
			border: 1px solid $color-white;
		}
	}

	.c-button-blue-dark {
		text-decoration: none;
		display: inline-block;
		color: $color-white;
		background-color: $color-blue;
		padding: 17.5px 24px;
		margin-right: 24px;
		border-radius: 3px;
		transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);

		&:hover {
			transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);
			background-color: transparent;
			padding: 16.5px 23px;
			border: 1px solid $color-blue;
		}
	}

	.c-botton-transparent-light {
		text-decoration: none;
		transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		display: inline-block;
		color: $color-dark-grey;
		border: 1px solid $color-blue;
		padding: 16.5px 23px;
		margin-right: 24px;
		border-radius: 3px;

		&:hover {
			transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);
			background-color: $color-blue;
		}
	}

	.c-botton-transparent-dark {
		text-decoration: none;
		transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		display: inline-block;
		color: $color-white;
		border: 1px solid $color-blue;
		padding: 16.5px 23px;
		margin-right: 24px;
		border-radius: 3px;

		&:hover {
			transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);
			background-color: $color-blue;
		}
	}

	.c-botton-white {
		text-decoration: none;
		transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		background-color: $color-white;
		display: inline-block;
		color: $color-dark-grey;
		padding: 16px 58px;
		border-radius: 3px;

		&:hover {
			color: $color-white;
			transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);
			background-color: transparent;
			border: 1px solid $color-white;
			padding: 15px 57px;
		}
	}

	.c-lead {
		color: $color-white;
		font-size: 14px;
		font-weight: 700;

		@include breakpoint('medium') {
			font-size: 16px;
		}
	}

	.c-active-title {
		&:hover {
			&::after {
				background-color: $color-blue;
			}
		}

		&::after {
			content: "";
			margin-left: 18px;
			width: 18px;
			height: 18px;
			display: none;
			mask-image: url('/images/new.k2.img/Vector936.svg');
			mask-repeat: no-repeat;
			mask-size: cover;
			-webkit-mask-image: url('/images/new.k2.img/Vector936.svg');
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-size: cover;
			background-color: $color-dark-grey;

			@include breakpoint('medium') {
				display: inline-block;
			}
		}
	}

	img {
		width: auto;
		max-width: 100%;
		height: auto;
		pointer-events: none;
		user-select: none;
	}

	table.standart_table {
		width: 100%;
		border: 1px solid #dbe9f0;
		border-collapse: collapse;
		margin-bottom: 24px;

		@include breakpoint('medium') {
			padding-bottom: 36px;
		}

		@include breakpoint('large') {
			width: 66%;
		}

		&.wide {
			width: 100%;
		}

		td,
		th {
			border: 1px solid #dbe9f0;
			border-collapse: collapse;
			text-align: center;
			vertical-align: top;

			&.white {
				background: #fff !important;
				color: $color-dark-grey;
			}

			&.blue {
				background: #71bffa !important;
				color: #fff;
			}

			&.checkpic {
				background: url('/images/new.k2.img/checkpic.svg') 50% 10px no-repeat;
				height: 54px;
			}

			&.minuspic {
				background: url('/images/new.k2.img/minuspic.svg') 50% 10px no-repeat;
				height: 54px;
			}

			&.pluspic {
				background: url('/images/new.k2.img/pluspic.svg') 50% 10px no-repeat;
				height: 54px;
			}

			&.l {
				text-align: left !important;
			}

			&.r {
				text-align: right !important;
			}

			&.c {
				text-align: center !important;
			}
		}

		tr {
			td {
				&:first-child {
					text-align: left;
				}
			}
		}

		th,
		td.th {
			background: #c8c8c8;
			color: #fff;
			padding: 5px;

			@include breakpoint('large') {
				font-size: 1.2rem;
				padding: 12px;
			}
		}

		td {
			padding: 5px;

			@include breakpoint('large') {
				padding: 10px 5px;
			}
		}
	}

	.scroll-table {
		overflow-x: scroll;

		@include breakpoint('large') {
			overflow-x: auto;
		}
	}

	.sheet-name {
		display: none;
	}

	h1 ~ p {
		margin-top: 0;

		@include breakpoint('large') {
			margin-top: -2rem;
		}
	}
}
