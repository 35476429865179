@import "~style";

$block-name: 'b-block-logos';

.#{$block-name} {
	background-color: $color-light-blue;

	&.white {
		background-color: $color-white;
	}

	position: relative;

	&__service-conrainer {
		display: flex;
		flex-wrap: wrap;
		margin-left: -32px;
		margin-top: -16px;

		@include breakpoint('medium') {
			margin-top: -32px;
		}
	}

	&__left-image {
		position: absolute;
		display: none;
		height: 98px;
		top: 587px;
		left: 0;

		@include breakpoint('large') {
			display: block;
			height: 98px;
			top: 587px;
		}

		@include breakpoint('xlarge') {
			height: 166px;
			top: 623px;
		}
	}

	&__right-image {
		position: absolute;
		display: none;
		height: 274px;
		top: 396px;
		right: 0;

		@include breakpoint('medium') {
			display: block;
		}

		@include breakpoint('large') {
			top: 202px;
		}

		@include breakpoint('xlarge') {
			height: 386px;
			top: 160px;
		}
	}

	&__container {
		margin-top: 32px;

		@include breakpoint('large') {
			margin-top: 64px;
		}
	}

	&__items {
		display: flex;
		align-items: stretch;
		flex-wrap: nowrap;
		// margin-left: -16px;
		// margin-top: -16px;
		height: auto;

		@include breakpoint('medium') {
			margin-left: -32px;
			margin-top: -32px;
			flex-wrap: wrap;
			box-sizing: inherit;
		}
	}

	&__item {
		padding: 20px 0;
		display: flex;
		align-items: center;
		min-height: 200px;
		max-height: 200px;
		justify-content: center;
		width: 100%;
		// margin-left: 16px;
		// margin-top: 16px;
		background-color: $color-white;

		@include breakpoint('medium') {
			width: calc(100% / 2 - 32px);
			margin-left: 32px;
			margin-top: 32px;
		}

		@include breakpoint('large') {
			width: calc(100% / 3 - 32px);
		}

		@include breakpoint('xlarge') {
			width: calc(100% / 4 - 32px);
		}

		&.grey {
			background-color: $color-light-blue;
		}

		img {
			// filter: grayscale(100%);
			max-width: 51%;
			height: auto;
		}
	}

	&__pagination {
		margin-top: 56px;
		position: relative;
		display: block;

		@include breakpoint('medium') {
			display: none;
		}

		& .swiper-pagination-bullet {
			&-active {
				transition: width 0.6s;
				width: 48px;
				border-radius: 4px;
			}
		}
	}
}
