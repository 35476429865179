@import "~style";

$block-name: 'b-resource-configurator';

.#{$block-name} {
	background-color: #08b3f0;

	& h2 {
		color: white;
	}

	&__desc {
		color: $color-white;
		margin-bottom: 30px;
		margin-right: 0;

		@include breakpoint('large') {
			margin-right: calc((100% - 960px) / 2);
		}
	}
}
