@import "~style";

$block-name: 'b-links-block';

.#{$block-name} {
	margin: 40px 0;
	//padding-top: 40px;
	//border-top: $blog-grey solid 1px;

	&__title {
		color: #080808;
		font-size: 22px;
		line-height: 22px;
	}

	&__links-list {
		padding-left: 2px;
	}

	&__link {
		color: #0db14b;
		font-size: 15px;
		line-height: 20px;
	}
}
