@import "~style";

$block-name: 'b-container';

.#{$block-name} {
	padding-top: 0;

	@include breakpoint(large) {
		// padding-top: 60px;
	}

	// @include xy-grid-container($padding:0);

	// @include breakpoint(medium) {
	// 	padding-top: 80px;
	// }

	// @include breakpoint(large) {
	// 	//
	// }

	// @include breakpoint(xlarge) {
	// 	//
	// }
}
