@import "~style";

$block-name: 'b-test-form';

.#{$block-name} {
	width: 100%;
	height: 650px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
