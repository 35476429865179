@import "~style";

$block-name: 'b-main-slider';

.#{$block-name} {
	position: relative;
	margin-top: -$header-height;

	.swiper-wrapper {
		height: 640px;

		@include breakpoint('medium') {
			height: 800px;
		}
	}

	.swiper-pagination-bullets {
		position: absolute;
		width: 100%;
		left: 10px;
		bottom: 40px;

		@include breakpoint('medium') {
			width: auto;
			left: 10px;
			bottom: 80px;
		}
	}

	.swiper-pagination-bullets .swiper-pagination-bullet {
		position: relative;
		background-color: white;
		opacity: 0.4;
		margin: 0 24px 0 0;
		width: 10px;
		height: 10px;

		&::before {
			content: '';
			background-image: url('/images/new.k2.img/Group 3986.png');
			width: 30px;
			height: 30px;
			display: none;
			margin-right: 5px;
			position: absolute;
			background-repeat: no-repeat;
			background-size: contain;
			top: -10px;
			left: -10px;
			z-index: -10;
		}

		&-active {
			opacity: 1;

			&::before {
				display: block;
			}
		}
	}

	&__circles-bg {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 10;
		display: none;

		@include breakpoint(large) {
			display: block;
		}
	}

	&__paginat {
		position: relative;
	}

	&__item {
		background-color: black;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 640px;

		@include breakpoint(medium) {
			height: 850px;
		}

		@include breakpoint(large) {
			background-position-x: 70%;
		}

		&.swiper-slide-duplicate {
			.#{$block-name}__desc {
				opacity: 1 !important;
			}

			h1,
			.big_h1 {
				opacity: 1 !important;
			}

			.#{$block-name}__button {
				opacity: 1 !important;
			}
		}

		&.width {
			& .big_h1 {
				max-width: 800px;
				font-size: 24px !important;
				line-height: 28px !important;

				@include breakpoint(750) {
					font-size: 50px !important;
					line-height: 56px !important;
				}
			}

			& .#{$block-name}__desc {
				max-width: 600px;
			}
		}
	}

	&__bg-black {
		position: absolute;
		background: black;
		height: 100%;
		width: 100%;
		opacity: 0;
		display: none;
	}

	&__desc {
		color: white;
		margin-top: 16px;
		max-width: 390px;
		font-size: 14px;

		&.first {
			opacity: 1;

			@include breakpoint(750) {
				opacity: 0;
			}
		}

		@include breakpoint('medium') {
			margin-top: 32px;
			font-size: 16px;
		}
	}

	h1,
	.big_h1 {
		color: white;
		padding-bottom: 0;
		margin: 250px 0 0;
		max-width: 510px;

		&.first {
			opacity: 1;

			@include breakpoint(750) {
				opacity: 0;
			}
		}

		@include breakpoint('medium') {
			margin: 290px 0 0;
		}
	}

	&__button {
		margin-top: 24px;

		&.first {
			opacity: 1;

			@include breakpoint(750) {
				opacity: 0;
			}
		}

		a {
			font-size: 14px;

			@include breakpoint('medium') {
				font-size: 16px;
			}
		}
	}

	&__pagination {
		position: absolute;
	}
}
