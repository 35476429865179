@import "~style";

$block-name: 'b-certificates-list';

.#{$block-name} {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 25px 0;
	margin-bottom: 32px;
	margin-top: 20px;

	@include breakpoint('medium') {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 25px 10px;
	}

	@include breakpoint('large') {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 50px 20px;
		margin-top: 40px;
	}

	@include breakpoint('xlarge') {
		grid-template-columns: repeat(4, 1fr);
	}
}
