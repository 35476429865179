@import "~style";

$block-name: 'b-questions-list';

.#{$block-name} {
	&__list {
		margin-top: 40px;

		@include breakpoint('medium') {
			margin-top: 48px;
		}

		@include breakpoint('large') {
			margin-top: 64px;
		}
	}

	&__item {
		cursor: pointer;
		// display: flex;
		align-items: center;
		padding: 24px 0;
		justify-content: space-between;
		border-top: 1px solid rgba(0, 0, 0, 0.2);

		@include breakpoint('medium') {
			padding: 32px 0;
		}

		@include breakpoint('large') {
			&:hover {
				.#{$block-name}__title {
					color: $color-blue;
				}
			}
		}

		&:last-child {
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}

		&.active {
			& .#{$block-name}__description {
				opacity: 1;
				margin-top: 16px;
				max-height: calc(100vh * 100);
				animation: show 0.6s 1;
				transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
			}

			& .#{$block-name}__title {
				color: $color-blue;
			}

			& .#{$block-name}__title::after {
				transform: rotate(180deg);
			}
		}
	}

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;

		&::after {
			content: '';
			background-image: url('/images/new.k2.img/arrow_down.svg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			flex: 0 0 auto;
			width: 24px;
			height: 24px;
			margin-left: 20px;
		}

		@include breakpoint('medium') {
			font-size: 24px;
		}

		& img {
			margin-left: 20px;
		}
	}

	&__description {
		opacity: 0;
		max-height: 0;
		transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
	}

	&__text {
		margin-bottom: 16px;
		width: 100%;

		@include breakpoint('large') {
			width: 80%;
		}
	}

	& .c-body-text {
		// opacity: 1;
	}
}
