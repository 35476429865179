@import "~style";

$block-name: 'b-block-services';

.#{$block-name} {
	background-color: $color-light-blue;

	&.white {
		background-color: $color-white;
	}

	position: relative;

	&__service-conrainer {
		display: flex;
		flex-wrap: wrap;
		margin-left: -32px;
		margin-top: -16px;

		@include breakpoint('medium') {
			margin-top: -32px;
		}
	}

	&__left-image {
		position: absolute;
		display: none;
		height: 98px;
		top: 587px;
		left: 0;

		@include breakpoint('large') {
			display: block;
			height: 98px;
			top: 587px;
		}

		@include breakpoint('xlarge') {
			height: 166px;
			top: 623px;
		}
	}

	&__right-image {
		position: absolute;
		display: none;
		height: 274px;
		top: 396px;
		right: 0;

		@include breakpoint('medium') {
			display: block;
		}

		@include breakpoint('large') {
			top: 202px;
		}

		@include breakpoint('xlarge') {
			height: 386px;
			top: 160px;
		}
	}

	&__container {
		margin-top: 32px;

		@include breakpoint('large') {
			margin-top: 64px;
		}
	}

	.swiper-wrapper {
		z-index: 10;
		display: flex;
		height: auto;
	}

	&__swiper-slide {
		&.noswiper {
			width: 100%;
			margin-left: 32px;
			margin-top: 16px;

			@include breakpoint('medium') {
				width: calc(100% / 2 - 32px);
				margin-top: 32px;
			}

			@include breakpoint('large') {
				width: calc(100% / 3 - 32px);
			}
		}
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&.end {
			justify-content: flex-end;
		}
	}

	&__swiper-btn {
		display: flex;
	}

	&__swiper-next {
		display: none;
		cursor: pointer;

		@include breakpoint('medium') {
			display: block;
		}

		@include breakpoint('large') {
			// display: none;
		}
	}

	&__swiper-prev {
		cursor: pointer;
		display: none;
		margin-right: 40px;

		@include breakpoint('medium') {
			display: block;
		}

		@include breakpoint('large') {
			// display: none;
		}
	}

	&__pagination {
		margin-top: 56px;
		position: relative;

		& .swiper-pagination-bullet {
			&-active {
				transition: width 0.6s;
				width: 48px;
				border-radius: 4px;
			}
		}
	}
}
