@import "~style";

$block-name: 'b-customers-detail';

.#{$block-name} {
	padding: 0 0 38px;

	&__breadcrumbs {
		margin-top: 40px;

		a {
			span {
				border-bottom: 1px solid #afafb0;
				font-size: 10px !important;

				@include breakpoint(medium) {
					font-size: 14px !important;
				}

				line-height: 24px !important;
				color: $color-dark-grey;
				font-family: inherit !important;

				&:hover {
					border-bottom: none;
				}
			}

			&::after {
				content: '/';
				position: absolute;
				color: $color-dark-grey;
				right: -15px;
				font-size: 10px !important;

				@include breakpoint(medium) {
					font-size: 14px !important;
				}

				top: 50%;
				transform: translateY(-50%);
			}

			text-decoration: none;
		}

		.bx-breadcrumb-item {
			margin-right: 20px;

			span {
				font-size: 10px !important;

				@include breakpoint(medium) {
					font-size: 14px !important;
				}

				line-height: 24px !important;
				color: $color-dark-grey;
				font-family: inherit !important;
			}

			opacity: 0.4;
			position: relative;

			i {
				display: none;
			}
		}
	}

	&__others {
		padding: 50px 0;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 80px;

		&-right {
			display: flex;
			justify-content: center;

			@include breakpoint(large) {
				justify-content: end;
				margin-bottom: 0;
			}
		}

		&-left,
		&-right {
			width: 100%;

			@include breakpoint(large) {
				max-width: 50%;
			}
		}
	}

	&__title {
		color: $color-dark-grey;
		margin-bottom: 0;
		width: 100%;
		padding-right: 0;

		@include breakpoint(large) {
			max-width: 815px;
			font-size: 32px;
			padding-right: 20px;
		}

		@include breakpoint(xlarge) {
			font-size: 48px;
		}
	}

	&__logo-container {
		height: 240px;
		max-width: 400px;
		width: 100%;
		background-color: $color-light-blue;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		position: static;
		margin-top: 20px;

		img {
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
			filter: grayscale(100%);
		}

		@include breakpoint(large) {
			margin-top: 0;
		}
	}

	&__services-item {
		display: inline-block;
		background: $color-light-blue;
		border-radius: 3px;
		color: $color-dark-grey;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		padding: 7px 8px;
		margin-left: 0;
		border: none;
	}

	&__banner-wrapper {
		text-align: center;
		margin-bottom: 50px;
	}

	&__container {
		padding-bottom: 88px;

		@include breakpoint(large) {
			min-height: 900px;
		}
	}

	&__content-left {
		display: none;
		position: relative;
		z-index: 1;

		@include breakpoint(large) {
			width: calc(100% / 3);
			display: block;
		}
	}

	&__img {
		position: absolute;
		left: -80%;
		top: 60px;
	}

	&__img-pattern {
		height: auto;
		width: auto;
		max-width: 100%;
		// max-height: 772px;

		.pattern-svg__orange-circle {
			display: none;
		}
	}

	&__orange-circle {
		height: 100px;
		width: 100px;
		top: -50px;
		left: -50px;
		position: absolute;

		&::before {
			content: '';
			display: block;
			width: 100%;
			border-radius: 50%;
			height: 100%;
			background: #f95208;
		}
	}

	&__content-right {
		position: relative;
		z-index: 12;

		@include breakpoint(large) {
			width: calc(100% - 100% / 3 - 50px);
			margin-left: auto;
			padding-left: 50px;
		}
	}

	&__property {
		flex-direction: column;
	}

	&__property-name {
		color: $color-dark-grey;

		&::after {
			display: none;
		}
	}

	&__property-secription {
		margin: 0;

		li {
			list-style: disc;

			&::before {
				display: none;
			}
		}

		ul {
			margin: 0;
		}

		display: block;
	}

	&__link-container {
		background: $color-light-blue;
		padding-top: 32px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 155px;
		color: $color-dark-grey;
		// background-image: url(/images/new.croc.img/project_360.svg);
		background-repeat: no-repeat;
		background-position: bottom center;
		background-color: #f6f8fb;

		@include breakpoint(700) {
			padding-top: 50px;
			padding-left: 40px;
			padding-right: 10px;
			padding-bottom: 82px;
			background-position: top right;
			// background-image: url(/images/new.croc.img/project_1440.svg);
		}

		& ~ .#{$block-name}__quote {
			padding: 0;
		}
	}

	&__link-title {
		font-weight: 700;
		font-size: 24px;
		line-height: 33px;
		margin-bottom: 25px;
	}

	&__link {
		color: $color-dark-grey;
		font-size: 16px;
		line-height: 20px;
		border-bottom: 2px solid $color-blue;
		display: inline-block;
		font-weight: 400;
		text-decoration: none;
	}

	&__quote {
		background: none;
		color: $color-dark-grey;
		font-size: 24px;
		line-height: 33px;
		font-weight: 700;
		padding: 48px 0 0 0;
		margin-bottom: 50px;

		&-author {
			margin-bottom: 32px;

			& span {
				font-weight: 400;
			}
		}

		&-description {
			border-left: 4px solid $color-blue;
			padding-left: 40px;
			font-weight: 400;
			font-size: 18px;
			opacity: 0.9;
		}
	}
}
