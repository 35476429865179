@mixin site-fonts () {
	// Подключайте тут все используемые шрифты, ниже представлен пример использования миксина, не рекомендую его править (лучше скопировать строчку)
	//@include font-face($name: "Open Sans", $path: "normal-italic", $weight: normal, $style: italic, $woff2: true);

	@include font-face($name: "Croc", $path: "~/font/Croc-Bold", $weight: 700, $style: normal, $woff2: true);
	@include font-face($name: "Croc", $path: "~/font/Croc-Light", $weight: 300, $style: normal, $woff2: true);
	@include font-face($name: "Croc", $path: "~/font/Croc-Regular", $weight: 400, $style: normal, $woff2: true);
	@include font-face($name: "IBM-Plex-Sans-Bold", $path: "~/font/IBM-Plex-Sans-Bold", $weight: 700, $style: normal, $woff2: true);
	@include font-face($name: "IBM-Plex-Sans-Bold", $path: "~/font/IBM-Plex-Sans-Light", $weight: 300, $style: normal, $woff2: true);
	@include font-face($name: "IBM-Plex-Sans-Bold", $path: "~/font/IBM-Plex-Sans-Regular", $weight: 400, $style: normal, $woff2: true);
	@include font-face($name: "Magistral", $path: "~/font/magistral_bold", $weight: 700, $style: normal, $woff2: true);
	// @include font-face($name: "Magistral", $path: "~/font/magistral_medium", $weight: 500, $style: normal, $woff2: true);
	@include font-face($name: "Magistral", $path: "~/font/magistral_book", $weight: 400, $style: normal, $woff2: true);
}
