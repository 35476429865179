@import "~style";

$block-name: 'b-scrollup';

.#{$block-name} {
	position: fixed;
	bottom: 150px;
	right: 28px;
	display: none;
	z-index: 200;
	cursor: pointer;

	@include breakpoint(medium) {
		bottom: 140px;
		right: 8px;
	}

	@include breakpoint(large) {
		// right: calc(7% - 20px);
	}

	img {
		width: 56px;
		height: 56px;

		@include breakpoint(medium) {
			width: 96px;
			height: 96px;
		}
	}
}
