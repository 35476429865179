@import "~style";

$block-name: 'b-main-services';

.#{$block-name} {
	background-color: $color-light-blue;

	&__container {
		margin-top: 56px;
		display: grid;
		grid-auto-rows: 1fr;
		grid-template-columns: 1fr;
		grid-column-gap: 32px;
		grid-row-gap: 16px;

		@include breakpoint('medium') {
			grid-template-columns: 1fr 1fr;
			grid-row-gap: 32px;
		}

		@include breakpoint(1440) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}
