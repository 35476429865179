@import "~style";

$block-name: 'b-header-top';

.#{$block-name} {
	height: 62px;
	display: none;
	align-items: center;
	justify-content: flex-end;
	position: relative;

	&__bottom-line {
		position: absolute;
		content: '';
		height: 1px;
		width: 100%;
		background-color: rgba(77, 77, 85, 0.2);
		bottom: 0;
		left: 0;
	}

	@include breakpoint(1200) {
		display: flex;
	}

	&.white-text {
		.#{$block-name}__settings-icon {
			background-color: $color-white;
		}

		& a {
			color: white;
		}

		.#{$block-name}__bottom-line {
			background-color: white;
		}

		.#{$block-name}__search-icon-hidden {
			path {
				fill: white;
			}
		}

		.#{$block-name}__setting-icon {
			path {
				fill: white;
			}
		}

		.#{$block-name}__search-icon,
		.#{$block-name}__user-icon {
			circle {
				stroke: white;
			}

			path {
				stroke: white;
			}

			line {
				stroke: white;
			}
		}
		.#{$block-name}__settings-icon {
			g rect {
				fill: white;
			}
		}

		& input {
			visibility: visible;
			color: $color-white;
			border: none;
			border-bottom: 1px solid $color-white;
			background-color: transparent;

			&::placeholder {
				color: $color-white;
			}
		}
	}

	&__settings-icon {
		display: block;
		-webkit-mask-image: url('/images/new.k2.img/Mask Group.png');
		mask-image: url('/images/new.k2.img/Mask Group.png');
		-webkit-mask-size: contain;
		mask-size: contain;
		-webkit-mask-position: center;
		mask-position: center;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		width: 100%;
		height: 100%;
		background-color: $color-dark-grey;
	}

	&__container {
		align-items: center;
		display: flex;
		justify-content: flex-end;

		&.hidden {
			.#{$block-name}__feedback {
				visibility: hidden;
			}
		}
	}

	&__setting-icon {
		width: 16px;
		height: 16px;
	}

	&__feedback {
		display: flex;
		margin-right: 96px;
	}

	&__email {
		margin-right: 40px;

		& a {
			color: #4d4d55;
			text-decoration: none;
		}
	}

	&__search-icon {
		cursor: pointer;
	}

	&__phone {
		& a {
			color: #4d4d55;
			text-decoration: none;
		}
	}

	&__help {
		color: $color-dark-grey;
		display: flex;
		align-items: center;

		& a {
			color: $color-dark-grey;
			text-decoration: none;
		}

		&.hidden {
			.#{$block-name}__search-icon {
				display: none;
			}

			.#{$block-name}__lang {
				visibility: hidden;
			}

			.#{$block-name}__search-icon-hidden {
				width: 20px;
				height: 20px;
				padding-bottom: 5px;
				padding-right: 5px;
				display: block;
				z-index: 20;
				position: relative;
				cursor: pointer;
			}
		}
	}

	&__help-item {
		display: block;
		position: relative;
		margin-right: 32px;
		width: 16px;
		height: 16px;

		&.hidden {
			visibility: hidden;
		}

		& img {
			cursor: pointer;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__lang {
		margin-right: 32px;
	}

	&__search-container {
		position: absolute;
		top: -7px;
		right: 0;
		width: 0;
		transition: 0.5s;
		overflow: hidden;

		&--opened {
			width: 450px;
		}
	}

	&__search-icon-hidden {
		display: none;
	}

	& input {
		visibility: visible;
		color: $color-dark-grey;
		border: none;
		border-bottom: 1px solid $color-dark-grey;
		background-color: transparent;
		padding: 4px 14px;
		width: 100%;

		&:focus {
			outline: 0;
		}

		&::placeholder {
			color: $color-dark-grey;
		}
	}
}
