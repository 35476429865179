@import "~style";

$block-name: 'b-search';

.#{$block-name} {
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 27px;

	@include breakpoint(medium) {
		padding: 0 128px;
	}

	@include breakpoint(large) {
		padding: 0 92px;
	}

	@include breakpoint(xlarge) {
		padding: 0 240px;
	}

	.b-breadcrumbs {
		width: 100%;
	}

	&__wrapper {
		padding-top: 60px;
		padding-bottom: 140px;

		@include breakpoint(medium) {
			padding-bottom: 370px;
			padding-top: 76px;
		}

		@include breakpoint(large) {
			padding-bottom: 220px;
		}

		@include breakpoint(xlarge) {
			padding-right: 122px;
		}
	}

	&__title {
		font-size: 24px;
		font-weight: 700;
		line-height: 1em;
		color: $color-blue-light;
		margin: 0;
		margin-bottom: 114px;

		@include breakpoint(medium) {
			font-size: 36px;
			margin-bottom: 145px;
		}
	}

	&__form {
		margin-bottom: 50px;
		position: relative;

		@include breakpoint(medium) {
			margin-bottom: 110px;
		}
	}

	&__form-input {
		width: 100%;
		margin: 0;
		padding: 11px 25% 11px 16px;
		border-radius: 30px;
		border: 1px solid $color-gray-content;
		font-size: 14px;
		color: $color-gray-content;
		outline: none;

		@include breakpoint(medium) {
			padding: 11px 25% 11px 25px;
		}
	}

	&__form-submit {
		width: 27px;
		height: 29px;
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url(/images/main_redisign/content/search/search.png);
		background-color: transparent;
		border: none;
		cursor: pointer;
		position: absolute;
		top: 5px;
		right: 29px;
		outline: none;

		@include breakpoint(medium) {
			right: 25px;
		}

		@include breakpoint(large) {
			right: 49px;
		}
	}

	&__item-number {
		display: none;

		@include breakpoint(large) {
			display: block;
			font-size: 72px;
			line-height: 1em;
			text-transform: uppercase;
			color: $color-blue-light;
			font-weight: 800;
		}
	}

	&__item {
		color: $color-gray-content;

		@include breakpoint(large) {
			display: flex;
			justify-content: space-between;
		}

		&:not(:last-of-type) {
			margin-bottom: 30px;

			@include breakpoint(medium) {
				margin-bottom: 54px;
			}
		}
	}

	&__item-content {
		@include breakpoint(large) {
			width: 74%;
		}

		@include breakpoint(xlarge) {
			width: 600px;
		}
	}

	&__item-name {
		font-size: 21px;
		font-weight: 700;
		line-height: 1.2em;
		margin-bottom: 22px;
		display: inline-block;

		@include breakpoint(medium) {
			margin-bottom: 12px;
		}
	}

	&__item-description {
		font-size: 16px;
		margin-bottom: 16px;

		@include breakpoint(medium) {
			margin-bottom: 14px;
		}
	}

	&__path-text {
		text-shadow: 0 0 7px rgba(255, 255, 255, 0.35);
		display: inline-block;
	}

	&__path-item {
		display: inline-block;
	}

	&__path-link {
		color: $color-blue-light;
	}

	&__path-list {
		line-height: 1.2em;
	}

	&__not-found {
		color: $color-gray-content;
	}
}
