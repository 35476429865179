@import "~style";

$block-name: 'b-form';

.#{$block-name} {
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 88px 0;
	background-color: $color-blue;

	@include breakpoint(medium) {
		padding: 104px 0;
	}

	@include breakpoint(large) {
		padding: 108px 0;
	}

	.error_text {
		color: red;
		font-size: 14px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	& .h2 {
		font-size: 40px;
		color: $color-white;

		@include breakpoint(medium) {
			font-size: 48px;
		}
	}

	& .c-body-text {
		margin-top: 16px;
		color: $color-white;
		opacity: 0.8;
		max-width: 390px;
	}

	&__content {
		margin-right: 16px;
		display: block;

		&.hidden {
			display: none;
		}
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		position: relative;

		&.active {
			.#{$block-name}__content {
				display: none;
			}

			.#{$block-name}__form {
				display: none;
			}

			&::before {
				content: "";
				inset: 0;
				margin: auto;
				position: absolute;
				width: 200px;
				height: 200px;
				display: inline-block;
				animation: 1.5s ease-in-out infinite rot;
				background-image: url("/images/main_redisign/pages/preloader.png");
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		@include breakpoint(large) {
			flex-direction: row;
		}
	}

	&__submit {
		margin-top: 55px;
		display: flex;
		justify-content: flex-start;

		@include breakpoint(medium) {
			margin-top: 80px;
		}

		@include breakpoint(large) {
			margin-top: 70px;
		}
	}

	&__popup {
		display: none;
		padding: 20px 26px 0;
		font-size: 12px;
		line-height: 1.2em;
		color: $color-gray-content;
	}

	&__submit-button {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
		background-color: $color-white;
		font-size: 16px;
		line-height: 1em;
		color: $color-dark-grey;
		height: 56px;
		width: 195px;
		cursor: pointer;
		outline: none;
		transition: background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1), color 0.6s cubic-bezier(0.65, 0, 0.35, 1);

		&:hover {
			background-color: transparent;
			color: $color-white;
			border: 1px solid $color-white;
			transition: background-color color 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		}

		@include breakpoint(medium) {
			width: 180px;
			font-size: 16px;
		}
	}

	&__field {
		position: relative;
		margin-bottom: 20px;

		&--position-absolute {
			@include breakpoint(large) {
				position: absolute;
				right: 0;
				left: 0;
				padding: 0 85px;
			}

			@include breakpoint(xlarge) {
				padding: 0 120px;
			}
		}
	}

	.b-form__input:focus + .b-form__label,
	.b-form__input--filled + .b-form__label {
		opacity: 0;
	}

	.b-form__checkbox:checked + .b-form__checkbox-label {
		&::before {
			background-image: url('/images/icons/check-act.svg');
		}
	}

	&__checkbox {
		opacity: 0;
	}

	& .not-event {
		pointer-events: none;
	}

	&__sent {
		color: white;
		font-size: 24px;
		line-height: 30px;
		font-weight: 700;
	}

	&__checkbox-label {
		display: inline-block;
		position: static;
		width: 100%;
		left: 0;
		cursor: pointer;
		font-size: 16px;
		line-height: 20px;
		color: $color-white;
		padding-left: 40px;

		&::before {
			content: '';
			width: 24px;
			height: 24px;
			background-image: url('/images/icons/checkbox-noactive.svg');
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			left: 0;
			transition: 0.5s;
		}

		@include breakpoint(medium) {
			font-size: 16px;
			position: absolute;
			padding-left: 40px;
		}

		a {
			color: $color-white;
		}
	}

	&__checkbox-popup {
		color: $color-blue-light;

		@include breakpoint(medium) {
			font-size: 14px;
		}
	}

	&__label {
		position: absolute;
		font-size: 16px;
		line-height: 1.2em;
		color: $color-gray-content;
		top: 16px;
		left: 24px;
		transition: 0.5s;

		&--textarea {
			top: 16px;
			display: flex;
			align-items: flex-end;

			@include breakpoint(large) {
				left: 24px;
			}
		}
	}

	&__field-recaptcha {
		position: relative;
	}

	&__input {
		border: none;
		padding: 16px 24px;
		font-size: 16px;
		color: $color-white;
		outline: none;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.2);
		border-radius: 5px;

		&--textarea {
			height: 136px;
			resize: none;
		}

		&::placeholder {
			color: $color-white;
			opacity: 1;
		}

		@include breakpoint(medium) {
			font-size: 16px;
		}
	}

	&__form {
		margin-top: 48px;
		max-width: 100%;
		width: 100%;
		z-index: 20;

		@include breakpoint(large) {
			margin-top: 0;
			max-width: 640px;
		}
	}

	&__file-wrapper {
		margin-bottom: 20px;
	}

	&__preview {
		margin-top: 10px;
		color: $color-white;
	}

	&__errors {
		margin-top: 10px;
		color: red;
	}

	&__file {
		// margin-bottom: 20px;
		position: relative;

		& input {
			position: absolute;
			left: 0;
			top: 0;
			display: none;
		}

		& label {
			cursor: pointer;
			font-size: 12px;
			color: $color-white;

			& img {
				margin-right: 4px;

				@include breakpoint('xlarge') {
					margin-right: 8px;
				}
			}
		}
	}

	// &__bg-circle {
	// 	background-image: url('/images/new.croc.img/form-bg-small.svg');
	// 	background-position: bottom;
	// 	background-size: contain;
	// 	background-repeat: no-repeat;
	// 	width: 152px;
	// 	height: 100%;
	// 	position: absolute;
	// 	right: 0;
	// 	top: 0;

	// 	@include breakpoint(medium) {
	// 		background-image: url('/images/new.croc.img/form-bg-middle.svg');
	// 		width: 40%;
	// 	}

	// 	@include breakpoint('large') {
	// 		background-position: bottom;
	// 		background-size: contain;
	// 		background-image: url('/images/new.croc.img/form-bg-big.svg');
	// 		width: 42%;
	// 		left: 0;
	// 	}

	// 	@include breakpoint(1440) {
	// 		width: 580px;
	// 	}

	// 	@include breakpoint('xlarge') {
	// 		width: 693px;
	// 	}
	// }
}
