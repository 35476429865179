@import "~style";

$block-name: 'b-header-block';

.#{$block-name} {
	position: relative;
	width: 100%;
	z-index: 50;

	@include breakpoint(medium) {
		transition: background-color 1s cubic-bezier(0.65, 0, 0.35, 1);
	}

	@include breakpoint(1200) {
		border: none;
	}

	&.fixed {
		top: -100%;
		position: fixed;
	}

	&.border {
		border-bottom: 1px solid #fff;

		@include breakpoint(1200) {
			border: none;
		}
	}

	&.active {
		opacity: 0;
		width: 100%;
		position: fixed;
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
		top: -100%;
		left: 0;
	}

	&.transition {
		top: 0;
		opacity: 1;
		// transition-duration: 2s cubic-bezier(0.65, 0, 0.35, 1);
	}

	&.transparent {
		background-color: transparent !important;
	}

	&.gray {
		background-color: #f6f8fb !important;
		// transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
	}
}
