@import "~style";

$block-name: 'b-croc-form';

.#{$block-name} {
	background-color: $color-blue;
	padding: 88px 0;

	@include breakpoint('medium') {
		padding: 104px 0;
	}

	@include breakpoint('large') {
		padding: 108px 0;
	}

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		@include breakpoint('large') {
			flex-direction: row;
		}
	}

	h2 {
		color: $color-white;
		margin-right: 20px;
	}

	&__description {
		margin-right: 20px;
		opacity: 0.8;
		margin-top: 16px;
		color: $color-white;
		max-width: 390px;
	}

	&__send {
		width: 100%;
		max-width: 1000px;
		margin-top: 32px;

		@include breakpoint('large') {
			max-width: 640px;
			margin-top: -16px;
		}
	}

	&__field {
		color: $color-white;

		& input {
			color: $color-white;
			border: 0;
			background: rgba(255, 255, 255, 0.2);
			margin-top: 16px;
			padding: 16px 24px;
			width: 100%;
			border-radius: 5px;

			&:focus {
				outline: 0;
			}

			&::placeholder {
				color: $color-white;
			}
		}

		& textarea {
			border: 0;
			background: rgba(255, 255, 255, 0.2);
			padding: 16px 24px;
			margin-top: 16px;
			width: 100%;
			height: 136px;
			resize: none;
			overflow: auto;
			border-radius: 5px;
			color: $color-white;

			&:focus {
				outline: 0;
			}

			&::placeholder {
				color: $color-white;
			}
		}
	}

	&__file {
		margin-top: 24px;
		position: relative;

		& input {
			position: absolute;
			left: 0;
			top: 0;
			display: none;
		}

		& label {
			cursor: pointer;
			font-size: 12px;
			color: $color-white;

			& img {
				margin-right: 4px;

				@include breakpoint('xlarge') {
					margin-right: 8px;
				}
			}
		}
	}

	&__button {
		display: inline-block;
		margin-top: 36px;
		border-radius: 3px;
		cursor: pointer;
		background-color: $color-white;
		padding: 17.5px 24px;

		@include breakpoint('medium') {
			margin-top: 44px;
		}

		@include breakpoint('large') {
			margin-top: 20px;
		}
	}
}
