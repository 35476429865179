@import "~style";

$block-name: 'b-title-and-text';

.#{$block-name} {
	background-color: $color-light-blue;

	&.white {
		background-color: $color-white;
	}

	&__desc {
		color: $color-dark-grey;
		margin-bottom: 30px;
		margin-right: 0;
		opacity: 0.9;

		@include breakpoint('large') {
			margin-right: calc((100% - 960px) / 2);
		}
	}
}
