@import "~style";

$block-name: 'b-croc-news';

.#{$block-name} {
	& .swiper {
		width: 100%;
		height: 100%;
		margin-right: 0;
		margin-left: 0;
	}

	& .big_h2 {
		a {
			color: $color-dark-grey;
			text-decoration: none;
		}
	}

	&__swiper-wrapper {
		height: auto;
	}

	&__container {
		margin-top: 10px;
		margin-left: 0;
		display: flex;

		@include breakpoint('medium') {
			margin-top: 47px;
			margin-left: -32px;
		}

		@include breakpoint('large') {
			margin-top: 34px;
		}
	}

	&__item {
		width: 100%;
		margin-left: 0;
		height: auto;

		@include breakpoint('medium') {
			width: calc(100% / 2 - 32px) !important;
			margin-left: 32px;
		}

		@include breakpoint('large') {
			width: calc(100% / 3 - 32px) !important;
		}

		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__number {
		&.h3 {
			margin-top: 30px;
			color: $color-blue;
		}
	}

	&__title {
		&.h3 {
			padding-top: 20px;
			padding-right: 20px;

			& a {
				display: block;
				// max-width: 364px;
				color: $color-dark-grey;
				text-decoration: none;
				font-size: 24px;
				line-height: 36px;

				@include breakpoint('medium') {
					max-width: 364px;
				}

				@include breakpoint('large') {
					max-width: 392px;
				}

				@include breakpoint('xlarge') {
					max-width: 364px;
				}
			}
		}
	}

	&__description {
		&.c-body-text {
			margin-top: 16px;
			// padding-right: 20px;
			// -webkit-line-clamp: 3;
			// display: -webkit-box;
			// -webkit-box-orient: vertical;
			// overflow: hidden;
			// -o-text-overflow: ellipsis;
			// text-overflow: ellipsis;

			@include breakpoint('medium') {
				max-width: 364px;
			}

			@include breakpoint('large') {
				max-width: 392px;
			}

			@include breakpoint('xlarge') {
				max-width: 364px;
			}
		}
	}

	&__link {
		margin-top: 16px;

		& a {
			display: inline-block;
			color: $color-dark-grey;
			text-decoration: none;
			opacity: 1;
			border-bottom: 2px solid $color-blue;
			font-size: 16px;
			line-height: 24px;

			&:hover {
				border-bottom: 2px solid transparent;
			}
		}

		@include breakpoint('medium') {
			margin-top: 32px;
		}

		@include breakpoint('large') {
			margin-top: 45px;
		}
	}

	&__pagination {
		margin-top: 56px;
		position: relative;

		& .swiper-pagination-bullet {
			&-active {
				transition: width 0.6s;
				width: 48px;
				border-radius: 4px;
			}
		}
	}

	&__container-navigation {
		position: relative;
		z-index: 20;
		display: flex;
		justify-content: space-between;
	}

	&__swiper-btn {
		display: flex;
	}

	&__swiper-next {
		display: none;
		cursor: pointer;

		@include breakpoint('medium') {
			display: block;
		}

		&.swiper-button-lock {
			display: none;
		}
	}

	&__swiper-prev {
		cursor: pointer;
		display: none;
		margin-right: 40px;

		@include breakpoint('medium') {
			display: block;
		}

		&.swiper-button-lock {
			display: none;
		}
	}
}
