@import "~style";

$block-name: 'b-teaser';

.#{$block-name} {
	height: 100%;
	transition: background-color 1s cubic-bezier(0.65, 0, 0.35, 1);
	background-color: $color-white;
	display: flex;
	flex-direction: column;

	@include breakpoint(800) {
		flex-direction: row;
	}

	&__img {
		// width: 100%;
		// height: auto;
		// object-fit: cover;
		// flex: 0 0 auto;
		// object-position: center;
		width: 100%;
		height: 250px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		@include breakpoint(800) {
			height: 100%;
		}
	}

	&__img-link {
		// display: contents;
		flex: 0 0 auto;
		width: 100%;
		// width: 100%;
		height: 250px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		@include breakpoint(800) {
			height: auto;
		}

		@include breakpoint(800) {
			width: calc(50% + 16px);
		}

		@include breakpoint(1440) {
			width: calc(67% + 16px);
		}
	}

	&.hover {
		@include breakpoint(1440) {
			&:hover {
				background-color: $color-blue;

				.#{$block-name}__preview-text {
					a {
						color: $color-white;
					}
				}
			}
		}
	}

	&:hover {
		.b-main_button__link {
			background: $color-white;
			color: $color-dark-grey;
		}

		.#{$block-name}__new {
			color: $color-blue;
			background-color: $color-white;
		}
	}

	&__counters-container {
		display: flex;
		flex-direction: column;
	}

	&__counters-wrapper {
		display: flex;
		margin-top: 20px;
	}

	&.wide {
		.#{$block-name}__wrapper {
			padding: 32px 24px 24px 24px;
		}

		@include breakpoint(800) {
			.#{$block-name}__wrapper {
				padding: 32px 24px 24px 24px;
			}
		}

		@include breakpoint(1440) {
			.#{$block-name}__wrapper {
				padding: 32px 24px 24px 24px;
			}

			&:hover {
				background-color: $color-blue;

				.#{$block-name}__article-title {
					color: $color-white;

					& span {
						color: $color-white;
					}
				}

				.#{$block-name}__new {
					color: $color-blue;
					background-color: $color-white;
				}

				.#{$block-name}__preview-text {
					color: $color-white;
				}

				.#{$block-name}__date {
					color: $color-white;
					opacity: 1;
				}

				.#{$block-name}__counters {
					&--readtime {
						color: $color-white;
					}

					&--views {
						color: $color-white;
					}
				}

				.#{$block-name}__counters-readtime {
					background-color: $color-white;
				}

				.#{$block-name}__counters-views {
					background-color: $color-white;
				}

				.b-tags__tag {
					color: $color-white;
				}
			}
		}
	}

	&.grey {
		background-color: #f6f8fb;
	}

	&__wrapper {
		width: auto;
		height: auto;
		min-height: 416px;
		padding: 32px 24px 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		// cursor: pointer;
		text-decoration: none;

		@include breakpoint(1440) {
			width: 100%;
			min-height: 436px;

			&:hover {
				.#{$block-name}__article-title {
					color: $color-white;

					& span {
						color: $color-white;
					}
				}

				& a {
					color: $color-white;
				}

				.#{$block-name}__preview-text {
					color: $color-white;
				}

				.#{$block-name}__date {
					color: $color-white;
					opacity: 1;
				}

				.#{$block-name}__counters {
					&--readtime {
						color: $color-white;
					}

					&--views {
						color: $color-white;
					}
				}

				.#{$block-name}__counters-readtime {
					background-color: $color-white;
				}

				.#{$block-name}__counters-views {
					background-color: $color-white;
				}
			}
		}
	}

	&__date {
		margin-bottom: 40px;
		font-size: 12px;
		text-decoration: none;
		color: $color-dark-grey;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-text {
			opacity: 0.4;
			line-height: 100%;
		}
	}

	&__new {
		padding: 2px 15px;
		color: $color-white;
		background-color: #08b7f4;
		border-radius: 15px;
	}

	@include breakpoint(small) {
		width: 100%;

		&__image-container {
			width: 100%;
			margin-bottom: 30px;
		}

		&__image {
			height: 100%;
			object-fit: cover;
		}

		&__main {
			@include xy-cell(auto);
		}

		&__article-title {
			display: block;
			margin-bottom: 17px;
			font-size: 24px;
			text-decoration: none;
			color: $color-dark-grey;
			line-height: 140%;

			& span {
				color: #08b7f4;
			}
		}

		&__preview-text {
			@include teaser-preview-text();

			display: block;
			margin-bottom: 24px;
		}
	}

	&__counters {
		display: flex;
		align-items: center;

		&--readtime {
			font-size: 12px;
			margin-right: 20px;
			color: $color-dark-grey;
		}

		&--views {
			font-size: 12px;
			color: $color-dark-grey;
		}
	}

	&__counters-readtime {
		margin-right: 4px;
		width: 24px;
		height: 24px;
		display: block;
		mask-image: url('/images/k2-icons/time.svg');
		-webkit-mask-image: url('/images/k2-icons/time.svg');
		-webkit-mask-size: cover;
		mask-size: cover;
		-webkit-mask-position: center;
		mask-position: center;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		background-color: $color-dark-grey;
	}

	&__counters-views {
		margin-right: 4px;
		width: 24px;
		height: 24px;
		display: block;
		mask-image: url('/images/k2-icons/eye.svg');
		-webkit-mask-image: url('/images/k2-icons/eye.svg');
		-webkit-mask-size: cover;
		mask-size: cover;
		-webkit-mask-position: center;
		mask-position: center;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		background-color: $color-dark-grey;
	}

	.b-tags__tag {
		font-weight: normal;
	}
}
