@import "~style";

$block-name: 'b-main-text';

.#{$block-name} {
	&__container {
		display: flex;
		flex-direction: column;
		margin-top: -32px;

		@include breakpoint('medium') {
			margin-top: -48px;
		}
	}

	&__item {
		min-height: 100%;
		margin-top: 32px;
		height: auto;
		display: flex;
		flex-direction: column;

		@include breakpoint('medium') {
			min-height: 384px;
			margin-top: 48px;
		}

		@include breakpoint('large') {
			flex-direction: row;
		}

		.#{$block-name}__image {
			width: 100%;

			@include breakpoint('large') {
				width: 64%;
			}
		}

		.#{$block-name}__content {
			width: 100%;

			@include breakpoint('large') {
				width: 36%;
			}
		}

		&--two {
			flex-direction: column-reverse;

			@include breakpoint('large') {
				flex-direction: row-reverse;
			}

			.#{$block-name}__image {
				width: 100%;

				@include breakpoint('large') {
					width: 36%;
				}
			}

			.#{$block-name}__content {
				width: 100%;

				@include breakpoint('large') {
					width: 64%;
				}
			}
		}
	}

	&__image {
		height: 344px;
		display: none;

		@include breakpoint('medium') {
			display: block;
		}

		@include breakpoint('large') {
			height: auto;
		}

		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&__desc {
		margin-top: 24px;
		max-width: 460px;
		font-size: 16px;

		@include breakpoint('medium') {
			font-size: 16px;
		}

		ul {
			margin-top: 0;
			margin-left: 20px;

			@include breakpoint('medium') {
				margin-left: 0;
			}
		}

		li {
			margin-top: 0;
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		font-size: 24px;
		max-width: 500px;
	}

	&__content {
		min-height: 100%;
		padding: 48px 20px;
		background-color: #f6f8fb;

		@include breakpoint('medium') {
			min-height: 384px;
			padding: 64px 20px 40px 56px;
		}

		// &.padding {
		// 	padding: 20px 20px;

		// 	@include breakpoint('medium') {
		// 		padding: 25px 20px 25px 56px;

		// 		& .b-main-text__title {
		// 			padding-top: 0;
		// 			padding-bottom: 0;
		// 		}
		// 	}
		// }
	}
}
