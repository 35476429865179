@import "~style";

$block-name: 'b-breadcrumbs';
.#{$block-name} {
	&__blog-articles {
		margin-top: 24px;
		margin-bottom: 60px;
		color: #afafb0;

		@include breakpoint(medium) {
			margin-top: 40px;
		}

		@include breakpoint(large) {
			margin-top: 65px;
		}

		.navigation-breadcrumbs__home-link {
			border-bottom: 1px solid #aaacaf;

			&:hover {
				border-bottom: none;
			}
		}

		a,
		span.last {
			display: none;
			font-size: 10px;
			line-height: 15px;
			color: #afafb0;
			text-decoration: none;
			position: relative;

			@include breakpoint(medium) {
				display: inline-block;
				font-size: 14px;
				line-height: 24px;
			}

			&.first {
				display: inline-block;
			}

			&.last {
				display: inline-block;
				pointer-events: none;
			}

			&:not(.last) {
				margin-right: 40px;
				border-bottom: 1px solid #aaacaf;

				@include breakpoint(medium) {
					margin-right: 20px;
				}

				&:hover {
					border-bottom: 1px solid transparent;
				}

				&::after {
					content: '/ ... /';
					position: absolute;
					right: -40px;
					font-size: 16px;
					pointer-events: none;

					@include breakpoint(medium) {
						content: '/';
						right: -15px;
					}
				}
			}
		}
	}

	margin: 0 auto 18px;

	@include breakpoint(medium) {
		margin: 0 auto 22px;
	}

	&__link {
		color: currentColor;
		font-size: 14px;
		letter-spacing: 0.04px;
		display: inline-block;
		line-height: 1em;
		text-decoration: none;
	}

	&__item {
		margin: 0;
		display: flex;
		align-items: center;
		flex-shrink: 0;

		&::after {
			content: '|';
			font-size: 12px;
			font-family: Circe;
			line-height: 1em;
			color: currentColor;
			margin: 0 15px;

			@include breakpoint(medium) {
				margin: 0 15px;
			}
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		display: flex;
		list-style-type: none;
	}
}
