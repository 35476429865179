@import "~style";

$block-name: 'b-search-main';

.#{$block-name} {
	h1 {
		margin: 0;
		padding-bottom: 60px;
	}

	&__item {
		display: flex;
		align-items: flex-start;
		text-decoration: none;
		max-width: 1000px;

		p {
			padding-bottom: 15px;
		}

		&:hover {
			.#{$block-name}__link {
				color: #08b7f4;
			}

			.#{$block-name}__number {
				color: #08b7f4;
			}
		}
	}

	&__input-container {
		position: relative;
		max-width: 825px;
		padding-bottom: 60px;
	}

	&__btn {
		border: none;
		width: 16px;
		height: 16px;
		mask-image: '/images/icons/search.svg';
		mask-position: center;
		mask-repeat: no-repeat;
		mask-size: contain;
		-webkit-mask-image: url('/images/icons/search.svg');
		-webkit-mask-position: center;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-size: contain;
		background-color: #868696;
		position: absolute;
		top: 16px;
		right: 16px;
	}

	&__number {
		display: none;
		font-weight: 700;
		font-size: 24px;
		line-height: 130%;
		text-transform: uppercase;
		color: #4d4d55;
		margin-right: 30px;

		@include breakpoint(1000) {
			display: block;
		}
	}

	&__route {
		color: #b7b7ba;
		padding-bottom: 30px;

		span {
			padding-right: 10px;

			&::after {
				content: '/';
				padding-left: 10px;
			}
		}

		span:last-child {
			&::after {
				content: '';
			}
		}
	}

	&__input {
		max-width: 825px;
		width: 100%;
		padding: 13px 16px;
		border: 1px solid #868696;
		border-radius: 3px;
	}

	&__text {
		font-weight: 400;
		font-size: 16px;
		line-height: 130%;
		color: #4d4d55;
	}

	&__link {
		color: #4d4d55;
		text-decoration: none;
		font-weight: 700;
		font-size: 24px;
		line-height: 130%;
	}
}
