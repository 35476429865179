@import "~style";

$block-name: 'b-certificates';

.#{$block-name} {
	& .swiper {
		width: 100%;
		height: 100%;
		margin-right: 0;
		margin-left: 0;
	}

	& .big_h2 {
		display: inline-block;
		color: $color-dark-grey;
		margin-bottom: 0;
		max-width: 390px;
		width: 100%;

		@include breakpoint('large') {
			width: auto;
		}

		a {
			color: $color-dark-grey;
			text-decoration: none;
		}
	}

	&__swiper-wrapper {
		height: auto;
	}

	&__container-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__container {
		margin-top: 10px;
		display: flex;

		@include breakpoint('medium') {
			margin-top: 47px;
		}

		@include breakpoint('large') {
			margin-top: 34px;
		}
	}

	&__title {
		margin-top: 32px;
		font-size: 16px;
		line-height: 130%;
		color: $color-dark-grey;
		max-width: 230px;
	}

	&__item {
		width: 80%;
		margin-left: 0;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include breakpoint('medium') {
			width: 100%;
		}

		& img {
			max-width: 200px;
		}
	}

	&__pagination {
		margin-top: 56px;
		position: relative;

		& .swiper-pagination-bullet {
			&-active {
				transition: width 0.6s;
				width: 48px;
				border-radius: 4px;
			}
		}
	}

	&__swiper-btn {
		display: flex;
	}

	&__swiper-next {
		display: none;
		cursor: pointer;

		@include breakpoint('medium') {
			display: block;
		}
	}

	&__swiper-prev {
		cursor: pointer;
		display: none;
		margin-right: 40px;

		@include breakpoint('medium') {
			display: block;
		}
	}

	&__link-all {
		display: inline-block;
		font-weight: normal;
		font-size: 16px;
		line-height: 130%;
		color: $color-dark-grey;
		text-decoration: none;
		margin-top: 44px;
		border-bottom: 3px solid #08b3f0;

		@include breakpoint('medium') {
			&:hover {
				border-bottom: 3px solid transparent;
			}
		}
	}

	&__button {
		display: flex;
		justify-content: center;

		@include breakpoint('medium') {
			justify-content: flex-start;
		}
	}
}
