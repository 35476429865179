@import "~style";

$block-name: 'b-connection';

.#{$block-name} {
	padding-top: 80px;
	color: $color-gray-content;
	max-width: 1440px;
	margin: 0 auto;

	@include breakpoint(xlarge) {
		padding-top: 40px;
	}

	&__wrapper {
		@include breakpoint(medium) {
			padding: 0 64px;
		}

		@include breakpoint(large) {
			padding: 0 85px;
		}

		@include breakpoint(xlarge) {
			padding: 0 120px;
		}
	}

	&__manager-phone,
	&__manager-mail {
		a {
			text-decoration: none;
			font-weight: 700;
			line-height: 1.2em;

			@include breakpoint(medium) {
				line-height: 1.4em;
			}
		}
	}

	&__manager-position {
		margin-bottom: 20px;
		line-height: 1.2em;
		font-size: 14px;

		@include breakpoint(medium) {
			font-size: 16px;
			margin-bottom: 16px;
			width: 60%;
		}

		@include breakpoint(large) {
			width: 85%;
		}
	}

	&__manager-name {
		font-size: 18px;
		font-weight: 700;
		line-height: 1em;
		margin-bottom: 20px;

		@include breakpoint(medium) {
			font-size: 21px;
			margin-bottom: 18px;
		}
	}

	&__manager-description {
		margin-top: 15px;
		width: 60%;

		@include breakpoint(medium) {
			margin: 0;
			width: 100%;
		}
	}

	&__manager-photo {
		margin-right: 27px;
		width: 20%;

		& img {
			@include breakpoint(xlarge) {
				width: 100%;
			}
		}

		@include breakpoint(medium) {
			display: none;
		}

		@include breakpoint(xlarge) {
			display: block;
			margin-right: 49px;
			width: 120px;
			flex-shrink: 0;
		}
	}

	&__manager-photo-image {
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;
		max-height: 120px;
		max-width: 120px;
	}

	&__managers-list {
		display: flex;
		flex-direction: column;

		@include breakpoint(medium) {
			flex-direction: row;
		}

		@include breakpoint(large) {
			flex-direction: column;
		}
	}

	&__manager {
		display: flex;
		margin-bottom: 40px;

		@include breakpoint(medium) {
			width: 50%;
			margin-bottom: 58px;
		}

		@include breakpoint(large) {
			width: 100%;
			margin: 0;

			&:first-of-type {
				margin-bottom: 55px;
			}
		}
	}

	&__managers {
		@include breakpoint(medium) {
			margin-top: 27px;
		}

		@include breakpoint(large) {
			order: 2;
			width: 25%;
			margin: 20px 0 100px;
		}

		@include breakpoint(xlarge) {
			width: 37.5%;
		}
	}

	&__content {
		padding: 0 27px;
		position: relative;

		@include breakpoint(medium) {
			padding: 0 64px;
		}

		@include breakpoint(large) {
			padding: 0 85px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		@include breakpoint(xlarge) {
			padding: 0 120px;
		}
	}
}
