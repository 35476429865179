@import "~style";

$block-name: 'b-article-footer';

.#{$block-name} {
	@include breakpoint(small) {
		@include xy-grid();

		.b-blog-article-detail__center {
			border-top: 1px solid $blog-grey;
			padding-top: 30px;
		}

		&__author {
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			color: $color-gray-content;
		}

		&__author-name {
			color: $color-gray-content-light;
		}

		&__wrapper {
			@include xy-grid();
		}

		&__properties {
			@include xy-cell();

			order: 1;
		}

		&__like-btn {
			width: 150px;
			height: 30px;
			background: lightgrey;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 20px auto 0;

			&:hover {
				background: $blog-green;
				cursor: pointer;
			}

			span {
				&::before {
					content: '';
					width: 14px;
					height: 10px;
					display: inline-block;
					margin-right: 10px;
					background: url("/upload/blog/teaser/icon-likes.png");
					background-repeat: no-repeat;
				}
			}
		}

		&__tags-container {
			@include xy-cell();
		}
	}

	@include breakpoint(medium) {
		padding-top: 30px;

		&__wrapper {
			@include xy-grid();

			margin-top: 40px;
		}

		&__properties {
			@include xy-cell(6);

			order: 0;
		}

		&__tags-container {
			@include xy-cell(6);
		}
	}

	@include breakpoint(large) {
		//
	}

	@include breakpoint(xlarge) {
		//
	}
}
