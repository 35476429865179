// Файл для подключения шрифтов и библиотек.
// Писать в этом файле свои стили ОЧЕНЬ не рекомендуется, все стили должны быть разнесены по блокам.
@import "~style";

@include site-fonts();
@include foundation-global-styles();

* {
	margin: 0;
	padding: 0;
	outline: none;
}

html {
	height: 100%;
}

body {
	// Почему минимальная ширина не прописана в foundation не знаю, но она нужна, поэтому пришлось запихнуть в этот файл
	color: #5e5e66;
	min-width: 320px;
	line-height: 130%;
}

.l-header {
	height: 64px;

	@include breakpoint(medium) {
		height: 104px;
	}

	@include breakpoint(1200) {
		height: $header-height;
	}
}

@include wysiwyg;

.c-content {
	margin: 0 auto;
	width: calc(100% - 40px);

	@include breakpoint('large') {
		width: calc(86%);
		max-width: 1600px;
	}
}
