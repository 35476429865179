@import "~style";

$block-name: 'b-main_button';

.#{$block-name} {
	&--mfp-close {
		width: 15px;
		height: 15px;
		background-image: url(/images/main_redisign/pages/close_gray.png);
		cursor: pointer;
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		top: 24.5px;
		right: 24.5px;

		@include breakpoint(medium) {
			top: 30px;
			right: 30px;
			width: 26px;
			height: 26px;
		}
	}
}
