@import "~style";

.mfp-content {
	margin: 0 auto;
	width: calc(100% - 40px) !important;
	background: #fff;
	// height: 600px;

	@include breakpoint('large') {
		width: calc(86%) !important;
		max-width: 1600px;
	}
}

.mfp-iframe-scaler {
	iframe {
		box-shadow: none;
	}
}
