@import "~style";

$block-name: 'b-main_digits';

.#{$block-name} {
	&__container {
		overflow: hidden;
		margin-top: 0;
		display: flex;
		flex-wrap: wrap;
		margin-left: 0;

		@include breakpoint('medium') {
			margin-left: -32px;
			margin-top: 15px;
		}

		@include breakpoint('large') {
			margin-top: 64px;
		}
	}

	h2 {
		display: inline-block;

		& a {
			text-decoration: none;
			color: $color-dark-grey;
		}
	}

	&__wrapper {
		width: 100%;
		display: block;
		align-items: flex-start;

		&:last-child {
			.#{$block-name}__item {
				border-bottom: none;
			}
		}

		@include breakpoint('medium') {
			display: flex;
			width: calc(100% / 2 - 32px);
			margin-left: 32px;

			&:nth-child(-n+2) {
				border-bottom: 1px solid #dbdbdd;
			}
		}

		@include breakpoint('large') {
			width: calc(100% / 4 - 32px);
			border-right: 1px solid #dbdbdd;

			&:last-child {
				border-right: none;
			}

			&:nth-child(-n+2) {
				border-bottom: none;
			}

			&:last-child {
				.#{$block-name}__item {
					border-right: none;
				}
			}
		}

		&--three {
			border-right: none;
			width: calc(100% / 2 - 24px);
			border-bottom: 1px solid #dbdbdd;

			&:nth-child(2) {
				justify-content: flex-start;
				border-left: none;
				border-right: none;
				margin-left: 24px;
			}

			.#{$block-name}__item {
				border-bottom: none;
				padding: 40px 0 24px;

				.#{$block-name}__description {
					margin-top: 8px;
				}
			}

			&:last-child {
				justify-content: flex-start;
				border-bottom: 1px solid #dbdbdd;
			}

			@include breakpoint('medium') {
				width: calc(100% / 3 - 32px);
				border-bottom: none !important;

				&:nth-child(2) {
					justify-content: center;
					border-left: 1px solid #dbdbdd;
					border-right: 1px solid #dbdbdd;
				}

				&:last-child {
					border-bottom: none;
				}

				.#{$block-name}__item {
					padding: 40px 0;

					.#{$block-name}__description {
						margin-top: 13px;
					}
				}
			}

			@include breakpoint('large') {
				width: calc(100% / 3 - 32px);

				&:nth-child(2) {
					justify-content: center;
					border-left: 1px solid #dbdbdd;
					border-right: 1px solid #dbdbdd;
				}

				&:last-child {
					justify-content: flex-end;
				}
			}
		}
	}

	&__item {
		padding: 40px 0;
		max-width: 100%;
		border-bottom: 1px solid #dbdbdd;

		@include breakpoint('medium') {
			max-width: 260px;
			border: none;
			padding: 56px 0;
		}

		@include breakpoint('large') {
			max-width: 100%;
			border-bottom: none;
			padding: 66px 0;
		}
	}

	&__title {
		&.h2 {
			color: $color-blue;
			font-size: 40px;

			@include breakpoint('medium') {
				font-size: 48px;
			}
		}
	}

	&__desc {
		padding-top: 32px;
		max-width: 460px;

		@include breakpoint('medium') {
			padding-top: 48px;
			padding-bottom: 0;
		}

		@include breakpoint('large') {
			padding-top: 64px;
			padding-bottom: 70px;
		}
	}

	&__description {
		&.c-body-text {
			margin-top: 13px;
			font-size: 14px;

			@include breakpoint('medium') {
				margin-top: 16px;
				font-size: 16px;
			}

			@include breakpoint('large') {
				margin-top: 16px;
			}
		}
	}

	&__content {
		max-width: 260px;
	}
}
