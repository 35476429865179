@import "~style";

$block-name: 'b-form-email';

.#{$block-name} {
	&__title {
		color: $color-white;
		font-size: 16px;
		font-weight: 700;
		line-height: 150%;
	}

	&__desc {
		margin-bottom: 17px;
		font-size: 12px;
		font-weight: 700;
		color: #a6a6aa;
	}

	&__checkbox-container {
		position: relative;
		display: flex;
		align-items: flex-start;
	}

	.b-form-email__checkbox:checked + .b-form-email__checkbox-label {
		&::before {
			background-image: url('/images/icons/check-act.svg');
		}
	}

	&__checkbox {
		opacity: 0;
	}

	&__form {
		&.hidden {
			display: none;
		}
	}

	&__checkbox-label {
		display: inline-block;
		position: static;
		width: 100%;
		left: 0;
		cursor: pointer;
		font-size: 12px;
		line-height: 18px;
		color: #a6a6aa;
		padding-left: 20px;

		&::before {
			content: '';
			width: 13px;
			height: 13px;
			background-image: url('/images/icons/checkbox-noactive.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			position: absolute;
			top: 2px;
			left: 0;
			transition: 0.5s;
		}

		@include breakpoint(medium) {
			font-size: 12px;
			// position: absolute;
			padding-left: 20px;
		}

		a {
			color: #2a82a4;
		}
	}

	&__success {
		display: none;
		color: $color-white;
		font-size: 16px;
		font-weight: 700;
		line-height: 150%;

		&.active {
			display: block;
		}
	}

	&__btn {
		width: 240px;
		padding: 19px 0;
		margin-left: 30px;
		margin-top: 20px;
		background-color: $color-blue-light;
		display: none;
		align-items: center;
		border-radius: 3px;
		justify-content: center;
		border: 1px solid $color-blue-light;
		color: $color-white;
		transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		cursor: pointer;

		@include breakpoint(medium) {
			display: flex;
		}

		&:hover {
			border: 1px solid $color-blue-light;
			color: $color-white;
			background-color: transparent;
		}

		&.mobile {
			margin-left: 0;
			margin-top: 20px;
			display: flex;

			@include breakpoint(medium) {
				display: none;
			}
		}
	}

	&__input {
		width: 330px;
		margin-left: 30px;
		margin-top: 20px;
		border-radius: 3px;
		font-weight: 700;
		background-color: transparent;
		border: 1px solid #a6a6aa;
		padding: 19px 0;
		font-size: 16px;
		text-align: center;
		color: white;

		&::placeholder {
			font-size: 16px;
			font-weight: 700;
			line-height: 150%;
		}
	}

	&__input-container {
		display: flex;
		flex-wrap: wrap;
		margin-left: -30px;
		margin-top: -20px;
		margin-bottom: 20px;
	}
}
