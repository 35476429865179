@import "~style";

$block-name: 'b-article-services';

.#{$block-name} {
	width: 100%;
	margin-left: auto;
	margin-top: 70px;
	padding: 40px;
	background-color: #f3f6fa;

	@include breakpoint(medium) {
		padding-top: 75px;
		padding-bottom: 75px;
		padding-left: 150px;
		padding-right: 20px;
	}

	@include breakpoint(large) {
		max-width: calc(100% - 100% / 3 - 32px);
	}

	&__title {
		color: #000;
		font-size: 22px;
		font-weight: 400;
		line-height: 36px;
		padding-bottom: 20px;

		@include breakpoint(medium) {
			font-size: 30px;
		}
	}

	&__link {
		color: $color-blue-light;
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;

		&:hover {
			color: $blog-grey;
		}
	}

	ol {
		padding-bottom: 0;
		padding-left: 15px;

		@include breakpoint(medium) {
			padding-left: 30px;
		}
	}
}
