@import "~style";

$block-name: 'b-main_button';

.#{$block-name} {
	display: flex;
	cursor: pointer;

	&--justify-center {
		justify-content: center;
	}

	&--mfp-close {
		width: 40px;
		height: 40px;
		background-image: url(/images/main_redisign/pages/icon_close.svg);
		cursor: pointer;
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		top: 33px;
		right: 30px;

		@include breakpoint(medium) {
			top: 40px;
			right: 50px;
		}
	}

	&__link {
		text-decoration: none;
		display: inline-block;
		color: $color-white;
		background-color: $color-blue;
		padding: 17.5px 24px;
		margin-right: 24px;
		border-radius: 3px;
		transition: background-color 0.4s;
		border: 1px solid transparent;

		&:hover {
			color: $color-dark-grey;
			transition: background-color 0.4s;
			background-color: transparent;
			padding: 16.5px 23px;
			border: 1px solid $color-blue;
		}
	}
}
