@import "~style";

$block-name: 'b-expert-detail';

.#{$block-name} {
	.b-main_title__title-container {
		@include breakpoint(xlarge) {
			padding-left: 34px;
		}
	}

	.b-main_title__title-big {
		display: none;

		@include breakpoint(medium) {
			display: block;
			font-size: 140px;
		}

		@include breakpoint(large) {
			font-size: 170px;
			left: -100px;
		}

		@include breakpoint(xlarge) {
			font-size: 240px;
			left: -140px;
		}
	}

	&__content {
		position: relative;
		overflow: hidden;
	}

	&__title {
		font-size: 24px;
		line-height: 1em;
		font-weight: 700;
		padding-bottom: 35px;
		margin: 0;
		color: currentColor;

		@include breakpoint(medium) {
			font-size: 36px;
			padding-bottom: 46px;
			width: 128px;
		}

		@include breakpoint(large) {
			width: 50%;
			padding-bottom: 56px;
		}
	}

	&__image-container {
		padding-bottom: 20px;

		@include breakpoint('medium') {
			float: right;
			padding-left: 20px;
		}
	}

	&__image {
		width: 213px;
		height: 213px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: cover;

		@include breakpoint(medium) {
			width: 194px;
			height: 194px;
		}

		@include breakpoint(large) {
			width: 341px;
			height: 341px;
		}

		@include breakpoint(xlarge) {
			width: 360px;
			height: 360px;
		}
	}

	&__position {
		font-size: 18px;
		font-weight: 700;
		line-height: 1.2em;
		margin-bottom: 29px;
		width: 80%;

		@include breakpoint(medium) {
			width: 60%;
			font-size: 21px;
			margin-bottom: 76px;
		}

		@include breakpoint(large) {
			width: 50%;
			margin-bottom: 60px;
		}
	}

	&__email {
		margin-bottom: 27px;

		@include breakpoint(medium) {
			margin-bottom: 57px;
		}

		a {
			text-decoration: none;
			font-size: 14px;
			line-height: 1em;

			@include breakpoint(medium) {
				font-size: 16px;
			}
		}
	}

	&__description {
		font-size: 14px;
		line-height: 1.3em;
		padding-bottom: 33px;

		@include breakpoint(medium) {
			font-size: 16px;
			line-height: 1.4em;
			padding-bottom: 50px;
		}

		@include breakpoint(large) {
			padding-bottom: 50px;
			width: 50%;
		}

		@include breakpoint(xlarge) {
			padding-bottom: 70px;
		}

		p {
			margin: 0 0 1em;
		}
	}

	&__description-long {
		display: none;
	}

	&__more-description {
		display: flex;
		align-items: center;
		cursor: pointer;

		@include breakpoint(medium) {
			padding-bottom: 90px;
		}

		@include breakpoint(large) {
			padding-bottom: 70px;
		}

		@include breakpoint(xlarge) {
			padding-bottom: 100px;
		}
	}

	&__more-description-text {
		font-size: 18px;
		font-weight: 700;
		line-height: 1em;

		@include breakpoint(medium) {
			font-size: 21px;
		}
	}
}
