@import "~style";

$block-name: 'b-steps';

.#{$block-name} {
	background-color: $color-light-blue;

	&.white {
		background-color: $color-white;
	}

	&__container {
		position: relative;
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		flex-direction: column-reverse;

		@include breakpoint('medium') {
			flex-direction: column;
			margin-top: 64px;
		}

		@include breakpoint(1440) {
			flex-direction: row;
			overflow: hidden;
		}
	}

	&__swiper-item {
		height: auto !important;
	}

	&__button-bottom {
		display: flex;
		justify-content: center;
		margin-top: 20px;

		@include breakpoint('medium') {
			justify-content: flex-start;
		}

		@include breakpoint(1440) {
			display: none;
		}
	}

	&__botton-top {
		display: none;
		margin-top: 20px;

		@include breakpoint(1440) {
			display: block;
		}
	}

	& .c-button-blue-light {
		display: none;
		margin-right: 0;

		@include breakpoint(1440) {
			display: inline-block;
		}

		&.bottom {
			margin-top: 48px;
			display: inline-block;

			@include breakpoint(1440) {
				display: none;
			}
		}
	}

	&__slider {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 0;

		@include breakpoint(750) {
			flex-direction: column-reverse;
		}

		@include breakpoint(1440) {
			margin-right: 20px;
		}

		& a {
			text-decoration: none;
		}
	}

	&__subtitle {
		color: #4d4d55;
		margin-top: 8px;
		font-size: 16px;
		max-width: 310px;
		line-height: 24px;

		@include breakpoint(1440) {
			font-size: 24px;
		}
	}

	&__description {
		margin-top: 6px;
		max-width: 313px;
		font-size: 14px;

		@include breakpoint('medium') {
			font-size: 16px;
		}

		@include breakpoint(1440) {
			margin-top: 16px;
		}
	}

	&__item {
		&:first-child {
			margin-top: 0;
			padding-top: 0;
		}

		max-width: 180px;
		height: auto;
		padding-left: 0;
		z-index: 20;

		@include breakpoint('large') {
			padding-left: 45px;
			height: 110px;
			max-width: 370px;
		}

		padding-top: 0;

		&.active {
			padding-top: 0;

			.#{$block-name}__subtitle {
				font-size: 16px;

				@include breakpoint('large') {
					font-size: 24px;
				}
			}
		}
	}

	&__pagination {
		display: block;
		margin-top: 40px;
		position: initial;

		@include breakpoint('medium') {
			display: none;
		}

		& .swiper-pagination-bullet {
			&-active {
				transition: width 0.6s;
				width: 48px;
				border-radius: 4px;
			}
		}
	}

	&__list-item {
		margin-bottom: 32px;
	}

	&__contain {
		position: relative;
		overflow: hidden;
		width: 500px;
		display: none;

		&.scrollbar {
			padding-left: 45px;
		}

		@include breakpoint(1440) {
			display: block;
		}
	}

	.swiper {
		display: block;

		@include breakpoint(1440) {
			display: none;
		}
	}

	.swiper-wrapper {
		width: 100%;
		height: auto;
		padding-left: 0;
		padding-top: 33px;

		@include breakpoint(1440) {
			padding-left: 45px;
			padding-top: 0;
			// height: 550px;
			width: 500px;

			&.padding-negativ {
				padding-left: 0;
			}
		}
	}

	&__images {
		margin-top: 0;

		& img {
			width: 100%;
		}

		@include breakpoint('medium') {
			margin-top: 40px;
		}

		@include breakpoint(1440) {
			margin-top: 0;
		}
	}

	&__swiper {
		display: block;

		// @include breakpoint(1440) {
		// 	display: none;
		// }
	}

	&__slide-wrapper {
		max-width: 100%;

		@include breakpoint('medium') {
			max-width: 180px;
		}

		@include breakpoint(1440) {
			max-width: 100%;
		}
	}

	.swiper-vertical > .swiper-scrollbar {
		top: 0;
		left: 0;
		height: 100%;
		background-color: $color-white;
	}

	.swiper-horizontal > .swiper-scrollbar {
		top: 0;
		left: 0;
		width: 100%;
		background-color: $color-white;
		display: none;

		@include breakpoint('medium') {
			display: block;
		}
	}

	.swiper-scrollbar-drag {
		background-color: $color-blue;
		cursor: pointer;
	}

	.ps--active-x > .ps__rail-x,
	.ps--active-y > .ps__rail-y {
		background-color: white;
	}

	.ps__rail-y {
		display: block;
		left: 0;
		background-color: white;
		width: 6px;
		opacity: 1;

		.ps--clicking {
			background-color: white;
			opacity: 1;

			.ps__thumb-y {
				width: 6px;
				background-color: $color-blue;
			}
		}

		&:hover {
			background-color: white;
			opacity: 1;

			.ps__thumb-y {
				background-color: $color-blue;
				width: 6px;
				opacity: 1;
			}
		}
	}

	.ps .ps__rail-y.ps--clicking {
		background-color: white;
		opacity: 1;
	}

	.ps__rail-y.ps--clicking .ps__thumb-y {
		background-color: $color-blue;
		width: 6px;
	}

	.ps:hover > .ps__rail-y {
		opacity: 1;
	}

	.ps__thumb-y {
		cursor: pointer;
		left: 0;
		width: 6px;
		background-color: $color-blue;
	}
}
