@import "~style";

$block-name: 'b-main-service';

.#{$block-name} {
	&__item {
		position: relative;
		background-color: $color-light-blue;
		transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		width: 100%;
		height: 1;
		min-height: 360px;

		@include breakpoint('medium') {
			min-height: 406px;
		}

		@include breakpoint('large') {
			min-height: 360px;
		}

		@include breakpoint('xlarge') {
			min-height: 470px;
		}

		@include breakpoint(1440) {
			&:hover {
				background-color: $color-blue !important;
				transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);

				.#{$block-name}__icons-img {
					display: none;
				}

				.#{$block-name}__text-prev {
					display: none;
				}

				.#{$block-name}__content-top {
					display: flex;
				}

				.#{$block-name}__content-bottom {
					display: block;
				}

				.#{$block-name}__content {
					visibility: visible;
					display: flex;
				}
			}
		}

		&.padding {
			.#{$block-name}__description-prev {
				&.c-body-text {
					padding-right: 24px;
					padding-left: 24px;
					padding-bottom: 40px;

					@include breakpoint('large') {
						padding-right: 40px;
						padding-left: 40px;
						padding-bottom: 56px;
					}
				}
			}

			.#{$block-name}__title-prev {
				&.h3 {
					padding: 0 24px;
					padding-bottom: 0;

					@include breakpoint('large') {
						padding: 0 40px;
					}
				}
			}
		}

		&.white {
			background-color: #fff;
			transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		}

		& a {
			text-decoration: none;
			width: 100%;
			height: 100%;
		}

		&:last-child {
			margin-right: auto;
		}
	}

	&__element {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
	}

	&__icons-img {
		padding-top: 32px;
		padding-left: 24px;

		& img {
			width: 48px;
			height: 48px;

			@include breakpoint('medium') {
				width: 88px;
				height: 88px;
			}

			@include breakpoint('large') {
				width: 102px;
				height: 102px;
			}
		}

		@include breakpoint('medium') {
			padding-left: 32px;
			padding-top: 40px;
		}

		@include breakpoint(1440) {
			padding-left: 40px;
		}
	}

	&__images {
		padding-top: 40px;
		padding-left: 40px;
	}

	&__link {
		padding-top: 40px;
		padding-right: 40px;
	}

	&__content-top {
		width: 100%;
		display: none;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__content-bottom {
		display: block;

		@include breakpoint(1440) {
			display: none;
		}
	}

	// &__content {
	// 	width: 100%;
	// 	height: auto;
	// 	padding: 40px 40px 32px;
	// 	color: $color-white;
	// 	align-items: flex-start;
	// 	justify-content: space-between;
	// 	flex-direction: column;
	// 	visibility: hidden;
	// 	display: none;
	// }

	&__title {
		&.h3 {
			line-height: 36px;
			// color: $color-dark-grey;
			padding: 0 24px;
			font-size: 24px;

			& a {
				color: $color-dark-grey;
				text-decoration: none;

				@include breakpoint(1440) {
					color: $color-white;
				}
			}

			@include breakpoint('medium') {
				padding: 0 32px;
			}

			@include breakpoint(1440) {
				color: white;
				padding: 0 40px;
			}
		}
	}

	&__description {
		&.c-body-text {
			margin-top: 8px;
			color: $color-white;
			opacity: 0.9;
			font-size: 14px;
			line-height: 20px;
			padding-left: 24px;
			padding-right: 24px;
			padding-bottom: 50px;

			@include breakpoint('medium') {
				padding-left: 32px;
				padding-right: 32px;
				padding-bottom: 58px;
				font-size: 16px;
			}

			@include breakpoint(1440) {
				padding-left: 40px;
				padding-right: 40px;
				padding-bottom: 36px;
			}
		}

		& a {
			color: #828288;
			height: auto;
			margin-top: 11px;
			display: inline-block;
			// display: flex;
			// justify-content: flex-start;

			@include breakpoint(1440) {
				color: white;
				margin-top: 8px;
			}
		}

		margin-top: 16px;

		@include breakpoint('medium') {
			margin-top: 8px;
		}
	}

	&__content-prev {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		@include breakpoint('large') {
			justify-content: space-between;
		}
	}

	&__title-prev {
		&.h3 {
			line-height: 36px !important;
			margin-top: auto;
			padding-right: 40px;
			padding-left: 40px;
			padding-bottom: 0;
			font-size: 24px;
		}

		display: none;

		@include breakpoint(1440) {
			display: block;
		}
	}

	&__description-prev {
		display: none;
		margin-top: 8px;

		@include breakpoint(1440) {
			display: block;
		}

		&.c-body-text {
			color: $color-dark-grey;
			padding-right: 40px;
			padding-left: 40px;
			padding-bottom: 56px;
			opacity: 0.9;
			font-size: 14px;
			line-height: 21px;

			@include breakpoint('medium') {
				font-size: 16px;
			}
		}
	}

	&__all {
		display: inline-block;

		& a {
			padding-bottom: 2px;
			border-bottom: 2px solid #08b3f0;

			&:hover {
				border-bottom: 2px solid transparent;
			}

			@include breakpoint(1440) {
				border-bottom: 2px solid #fff;
			}
		}
	}

	&__arrow-link {
		// display: flex;
		// flex-wrap: wrap;
		// justify-content: space-between;

		&::after {
			content: '';
			width: 11px;
			margin-left: 10px;
			height: 11px;
			margin-top: 2px;
			display: inline-block;
			-webkit-mask-image: url('/images/new.k2.img/Vector936.svg');
			mask-image: url('/images/new.k2.img/Vector936.svg');
			-webkit-mask-size: contain;
			mask-size: contain;
			-webkit-mask-position: center;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			background-color: #828288;
			flex: 0 0 auto;

			@include breakpoint(1440) {
				background-color: #fff;
			}
		}
	}
}
