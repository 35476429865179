@import "~style";

$block-name: 'b-podcast';

.#{$block-name} {
	padding: 10px 0 20px;

	@include breakpoint('large') {
		padding: 10px 0 30px;
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		margin-left: -25px;
		margin-top: -10px;
	}

	&__item {
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		border: 1px solid #b8b8bb;
		border-radius: 50%;
		margin-left: 25px;
		margin-top: 10px;
		transition: background-color 0.4s cubic-bezier(0.65, 0, 0.35, 1);

		@include breakpoint('large') {
			&:hover {
				&::after {
					background-color: $color-white;
				}

				border: 1px solid transparent;
				background-color: $color-orange;
			}
		}

		&::after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 28px;
			height: 28px;
			content: "";
			display: block;
			mask-image: var(--icon);
			mask-repeat: no-repeat;
			mask-size: contain;
			mask-position: center;
			// background-image: var(--icon);
			// background-repeat: no-repeat;
			// background-size: contain;
			// background-position: center;
			background-color: $color-blue-light;
		}
	}
}
