// Складируйте сюда все миксины, используемые на сайте
@mixin blog-button() {
	display: block;
	width: 177px;
	height: 48px;
	color: #717073;
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 0.7px;
	text-align: center;
	line-height: 54px;
	border: 1px solid #717073;
}

@mixin blog-title() {
	color: $color-blue-light;
	text-decoration: none;
}

@mixin block-title() {
	@include breakpoint(small) {
		@include blog-title();

		font-size: 28px;
		font-weight: 700;
	}

	@include breakpoint(medium) {
		font-size: 44px;
	}

	@include breakpoint(large) {
		font-size: 48px;
	}

	@include breakpoint(xlarge) {
		font-size: 50px;
	}
}

@mixin teaser-elements($color: $blog-green) {
	&:hover {
		color: $color;
	}
}

@mixin article-title {
	@include breakpoint(small) {
		@include blog-title();

		font-size: 22px;
	}

	@include breakpoint(medium) {
		font-size: 26px;
	}

	@include breakpoint(large) {
		font-size: 28px;
	}

	@include breakpoint(xlarge) {
		font-size: 30px;
	}
}

@mixin teaser-article-title--narrow() {
	@include breakpoint(small) {
		@include blog-title();

		font-size: 18px;
		font-weight: 700;
	}

	@include breakpoint(large) {
		font-size: 20px;
	}

	@include breakpoint(xlarge) {
		font-size: 22px;
	}
}

@mixin teaser-article-title--wide() {
	@include breakpoint(small) {
		@include blog-title();
		@include article-title();

		font-weight: 700;
	}
}

@mixin teaser-title-green($color) {
	color: $color;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: 0.7px;
	border-bottom: solid 1px $color;
}

@mixin teaser-title-green--category($color: $color-gray-content) {
	text-decoration: none;

	@include breakpoint(small) {
		@include teaser-title-green($color);
		@include teaser-elements($color);

		font-size: 12px;
		transition: border-bottom-width 150ms ease-in-out;

		&:hover {
			border-width: 3px;
		}
	}

	@include breakpoint(large) {
		font-size: 14px;
	}
}

@mixin teaser-title-green--author() {
	@include teaser-title-green($blog-green);

	font-size: 14px;
}

@mixin teaser-preview-text {
	@include breakpoint(small) {
		text-decoration: none;
		color: $color-dark-grey;
		font-size: 14px;
		font-weight: 400;
	}

	@include breakpoint(large) {
		font-size: 15px;
	}

	@include breakpoint(xlarge) {
		font-size: 16px;
	}
}
