@import "~style";

$block-name: 'b-see-also';

.#{$block-name} {
	@include xy-grid();

	@include breakpoint(small) {
		&__title {
			@include block-title();
			@include xy-cell();
		}

		&__article {
			@include xy-cell();

			margin-bottom: $teaser-indent;

			.b-teaser {
				&__image-container {
					//height: 180px;
				}

				&__image {
					height: inherit;
				}

				&__tags {
					display: none !important;
				}

				&__author-name {
					display: none;
				}
			}

			&--numb-1 {
				.b-teaser {
					&__image-container {
						//height: 306px;
					}

					&__image {
						height: inherit;
					}

					&__article-title {
						@include teaser-article-title--wide();
					}
				}
			}

			&--numb-2 {
				.b-teaser {
					&__article-title {
						@include teaser-article-title--narrow();
					}
				}
			}

			&--numb-3 {
				.b-teaser {
					&__article-title {
						@include teaser-article-title--narrow();
					}
				}
			}
		}
	}

	@include breakpoint(medium) {
		&__article {
			@include xy-cell(6);

			&--wide {
				@include xy-cell();
			}

			.b-teaser {
				&__image-container {
					height: 180px;

					&__image {
						height: 100%;
					}
				}
			}

			&--numb-1 {
				.b-teaser {
					&__image-container {
						height: 306px;
					}

					&__image {
						height: 100%;
					}
				}
			}
		}
	}

	@include breakpoint(large) {
		&__article {
			@include xy-cell(4);

			&--wide {
				@include xy-cell(8);

				order: 1;
			}

			&--disappeared {
				display: none;
			}
		}
	}
}
