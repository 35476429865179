@import "~style";

$block-name: 'b-form-cloud-resource';

.#{$block-name} {
	display: flex;
	justify-content: center;
	width: 100%;

	&__wrapper {
		max-width: 960px;
		width: 100%;
		background-color: $color-white;
		color: $color-gray-content;
		padding: 30px 20px;
	}

	&__title {
		text-align: center;
	}

	&__range-title {
		font-size: 18px;
		margin-bottom: 30px;

		@include breakpoint(800) {
			margin-bottom: 30px;
		}

		&.bottom-small {
			margin-bottom: 20px;
		}
	}

	&__form {
		&.hidden {
			display: none;
		}
	}

	&__total {
		padding: 5px 10px;
		background-color: #fbfbfb;
		text-align: center;
		margin-top: 10px;
		color: $color-black;

		&.center {
			display: flex;
			justify-content: space-between;
		}

		& span {
			color: rgb(68, 199, 244);
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 50px;
	}

	&__three-items {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;

		@include breakpoint(800) {
			flex-wrap: nowrap;
			gap: 40px;
		}
	}

	&__three-item {
		width: calc(100%);

		@include breakpoint(800) {
			width: calc(100% / 3);
		}
	}

	&__items-input {
		display: flex;
		flex-wrap: wrap;
		gap: 24px 48px;
		margin-bottom: 30px;
		width: 100%;
	}

	&__title-input {
		font-size: 18px;
		margin-bottom: 15px;
	}

	input {
		width: 100%;
		padding: 10px 0 8px;
		border: none;
		border-bottom: 2px solid #dbe3ef;
		color: #777;

		&:focus {
			border-bottom: 2px solid #6598ff;
		}
	}

	&__checkbox-container {
		display: grid;
		position: relative;
		margin-bottom: 90px;

		@include breakpoint(800) {
			margin-bottom: 40px;
		}
	}

	&__inputs-checbox {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&.bottom {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 1fr;
			gap: 16px 50px;

			@include breakpoint(800) {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	&__input-checbox {
		input {
			width: 24px;
			height: 24px;
			background-color: black;
			display: none;
		}

		label {
			position: relative;
			padding-left: 40px;
			display: inline-block;
			cursor: pointer;

			&::before {
				content: '';
				width: 24px;
				height: 24px;
				background-image: url(/images/icons/checkbox.svg);
				background-position: center;
				background-repeat: no-repeat;
				position: absolute;
				left: 0;
				top: -2px;
				transition: 0.5s;
			}
		}

		input:checked + label {
			&::before {
				background-image: url(/images/main_redisign/pages/checkbox_blue_active.svg);
			}
		}
	}

	.b-form-cloud-resource__checkbox:checked + .b-form-cloud-resource__checkbox-label {
		&::before {
			background-image: url(/images/main_redisign/pages/checkbox_blue_active.svg);
		}
	}

	&__checkbox {
		opacity: 0;
		width: auto;
	}

	&__checkbox-label {
		position: absolute;
		width: 100%;
		left: 0;
		cursor: pointer;
		font-size: 14px;
		color: $color-gray-content;
		padding-left: 40px;

		@include breakpoint(medium) {
			font-size: 16px;
		}

		&::before {
			content: '';
			width: 24px;
			height: 24px;
			background-image: url(/images/icons/checkbox.svg);
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			left: 0;
			top: 0;
			transition: 0.5s;
		}
	}

	&__success {
		display: none;
		text-align: center;

		&.active {
			display: block;
		}
	}

	&__btn {
		cursor: pointer;
		margin-right: 0;
	}

	&__btn-container {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	&__item-input {
		width: calc(100% - 24px);

		@include breakpoint(800) {
			width: calc(100% / 2 - 24px);
		}
	}

	.iti {
		width: 100%;

		--iti-path-flags-1x: url("node_modules/intl-tel-input/build/img/flags.png");
		--iti-path-flags-2x: url("node_modules/intl-tel-input/build/img/flags@2x.png");
		--iti-path-globe-1x: url("node_modules/intl-tel-input/build/img/globe.png");
		--iti-path-globe-2x: url("node_modules/intl-tel-input/build/img/globe@2x.png");
	}

	.irs-line {
		height: 2px;
	}

	.irs-min,
	.irs-max {
		display: none;
	}

	.irs-grid-pol.small {
		display: none;
	}

	.irs--flat .irs-bar {
		height: 2px;
		background-color: #6598ff;
	}

	.irs-handle.single {
		width: 21px;
		height: 21px;
		border-radius: 50%;
	}

	.irs--flat .irs-single::before {
		border: 8px solid transparent;
		bottom: -18px;
	}

	.irs--flat .irs-single {
		padding: 7px 10px;
	}

	.irs-single {
		top: -18px;
		background-color: $color-white;
		color: black;
		font-size: 13px;
		box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
	}

	.irs--flat .irs-handle {
		background-color: #6598ff;
		top: 16px;
	}

	.irs-grid-text {
		bottom: 7px;
	}

	.irs-grid-pol {
		top: -14px;
		height: 11px;
		width: 2px;
	}

	.irs--flat .irs-handle > i:first-child {
		background-color: transparent;
	}
}
