//Пользовательские переменные.

//Цвета, по возможности желательно придерживаться этого формата
$color-white: #fff;
$color-black: #000;
$color-dark-grey: #4d4d55;
$color-light-grey: #dbdbdd;
$color-light-blue: #f6f8fb;
$color-blue: #08b7f4;
$color-orange: #f95208;

//old site
$color-purple: #f95208;
$color-blue-light: #08b7f4;
$color-black: #000;
$color-gray: #8c8c8c;
$color-gray-light: #e6e6e6;
$color-gray-dark: #727272;
$color-gray-content: #777;
$color-gray-content-light: #a7a9ac;
$color-gray-content-lighter: #bec0c3;
$color-fiolet: #8a43f9;
//Цвета для блога
$blog-green: #0db14b;
$blog-grey: #9e9ea2;
//Отступ между статьями
$teaser-indent: 80px;

//Foundation - глобальная конфигурация шрифта
$global-font-size: 16px;
$global-font-size-middle: 16px; // для средней версии
$global-font-size-mobile: 14px; // для мобильной версии
$global-lineheight: 1.3;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-weight-regular: 400;

$header-height: 139px;

//Foundation - основные параметры body
$body-background: $color-white;
$body-font-color: $color-dark-grey;

// $body-font-family: 'Croc', arial, sans-serif;
$body-font-family: 'IBM-Plex-Sans-Bold', arial, sans-serif;

//Foundation - настройки сеток
$grid-columns: 12;
$global-width: 1200px;
$breakpoints: (
	small: 0,
	medium: 640px,
	large: 1000px,
	xlarge: 1600px,
	xxlarge: 1920px,
);
$grid-margin-gutters: (
	small: 32px,
	medium: 32px,
	large: 32px,
	xlarge: 32px,
	xxlarge: 32px,
);
$grid-container-padding: (
	small: 32px,
	medium: 32px,
	large: 32px,
	xlarge: 32px,
	xxlarge: 32px,
);
