@import "~style";

$block-name: 'b-digits-blue-bg';

.#{$block-name} {
	background-color: $color-blue;

	&__wrapper {
		display: flex;
		flex-direction: column;

		@include breakpoint('large') {
			flex-direction: row;
		}
	}

	&__title {
		width: 100%;
		font-size: 24px;
		line-height: 140%;
		color: $color-white;
		margin-right: 80px;
		max-width: 500px;

		@include breakpoint('large') {
			width: 50%;
			max-width: 100%;
		}
	}

	&__desc {
		width: 100%;
		font-weight: normal;
		padding-top: 10px;
		font-size: 14px;
		line-height: 150%;
		color: $color-white;
		margin-top: 24px;
		max-width: 500px;

		@include breakpoint('medium') {
			font-size: 16px;
			line-height: 130%;
			margin-top: 32px;
		}

		@include breakpoint('large') {
			max-width: 100%;
			width: 50%;
			margin-top: 0;
		}
	}

	&__items {
		margin-top: 16px;
		display: flex;
		flex-wrap: wrap;
		margin-left: 0;

		@include breakpoint('medium') {
			margin-left: -140px;
		}

		@include breakpoint('large') {
			margin-top: 100px;
			margin-left: -80px;
		}
	}

	&__item {
		margin-left: 0;
		width: calc(100%);
		margin-top: 40px;

		@include breakpoint('medium') {
			margin-left: 140px;
			width: calc(100% / 2 - 140px);
			margin-top: 64px;
		}

		@include breakpoint('large') {
			width: calc(100% / 4 - 80px);
			margin-left: 80px;
			margin-top: 0;
		}
	}

	&__count {
		margin-top: 16px;
		font-weight: bold;
		font-size: 40px;
		line-height: 130%;
		color: $color-white;

		@include breakpoint('medium') {
			font-size: 48px;
			margin-top: 40px;
		}
	}

	&__item-desc {
		margin-top: 8px;
		font-weight: normal;
		font-size: 14px;
		line-height: 150%;
		color: $color-white;
		max-width: 260px;

		@include breakpoint('medium') {
			font-size: 16px;
			line-height: 130%;
			max-width: 100%;
			margin-top: 16px;
		}
	}
}
