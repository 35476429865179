@import "~style";

$block-name: 'b-slider';

.#{$block-name} {
	overflow: hidden;
	background-color: #f3f6fa;
	max-height: 400px;
	padding: 0 20px 20px;
	margin-top: 30px;

	&__controls-bar {
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__slider-counter {
		//
	}

	&__count {
		font-size: 18px;
		font-weight: 400;
		letter-spacing: 1.35px;
		color: #6b6b6b;

		&--current {
			color: $color-blue-light;
		}
	}

	&__content-slider {
		height: 316px;
		overflow: hidden;
	}

	&__slide {
		height: 100%;
		width: 100%;
	}

	&__arrows {
		display: flex;
		flex-wrap: nowrap;
	}

	&__arrow {
		width: 13px;
		height: 10px;

		&:hover {
			cursor: pointer;
		}

		&--left {
			background: url("/images/blog/slider/icon-arrow-left.svg") center no-repeat;
			margin-right: 20px;
		}

		&--right {
			background: url("/images/blog/slider/icon-arrow-right.svg") center no-repeat;
		}
	}

	.draggable {
		height: 100%;
		overflow: hidden;
	}

	.slick-track {
		height: 100%;
		display: flex;
	}

	.slick-slide > div {
		height: 100% !important;
	}
}
