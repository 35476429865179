@import "~style";

$block-name: 'b-services';

.#{$block-name} {
	&__title {
		margin: 62px 0 0;
		padding: 0;

		@include breakpoint(medium) {
			margin: 88px 0 0;
		}
	}
}
