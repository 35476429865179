@import "~style";

$block-name: 'b-about-digits';

.#{$block-name} {
	&__title {
		font-weight: bold;
		font-size: 40px;
		line-height: 130%;
		color: $color-dark-grey;
		margin-bottom: 40px;

		@include breakpoint('medium') {
			font-size: 48px;
			margin-bottom: 64px;
		}

		@include breakpoint('large') {
			margin-bottom: 100px;
		}
	}

	&__items {
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		column-gap: 0;
		margin-top: -40px;

		@include breakpoint('medium') {
			column-gap: 120px;
			grid-template-columns: repeat(2, 1fr);
			margin-top: -64px;
		}

		@include breakpoint('large') {
			column-gap: 240px;
			grid-template-columns: repeat(3, 1fr);
			margin-top: -100px;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 3px;
			background-color: $color-white;
			z-index: 2;
		}
	}

	&__item {
		max-width: 100%;
		border-top: 1px solid #dbdbdd;
		padding-top: 32px;

		@include breakpoint('medium') {
			max-width: 260px;
			padding-top: 56px;
		}
	}

	&__item-title {
		margin-bottom: 16px;
		font-weight: bold;
		font-size: 40px;
		line-height: 130%;
		color: $color-blue;

		@include breakpoint('medium') {
			font-size: 48px;
		}
	}

	&__item-desc {
		color: #999;
		font-weight: normal;
		font-size: 14px;
		line-height: 150%;
		margin-bottom: 32px;
		max-width: 235px;

		@include breakpoint('medium') {
			font-size: 16px;
			line-height: 130%;
			margin-bottom: 56px;
		}
	}
}
