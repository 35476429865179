@import "~style";

$block-name: 'b-banner';

.#{$block-name} {
	@include breakpoint(small) {
		@include xy-cell(12);

		&__icon {
			width: 106px;
			height: 106px;
			object-fit: contain;
		}

		&__category {
			font-size: 14px;
			margin: 30px 0 17px;
			color: $color-gray-content;
			font-weight: 800;
			text-transform: uppercase;
			letter-spacing: 0.7px;
		}

		&__text-underline {
			border-bottom: 1px solid currentColor;
		}

		&__title {
			margin-bottom: 30px;
			font-size: 22px;
			font-weight: 700;
			color: $color-blue-light;
		}

		&__detailed {
			@include blog-button();
		}
	}

	@include breakpoint(medium) {
		//
	}

	@include breakpoint(large) {
		margin-top: 0;
	}
}
