@import "~style";

$block-name: 'b-block-contacts';

.#{$block-name} {
	background-color: #f6f8fb;
	position: relative;

	&__link-title {
		color: $color-dark-grey;
		text-decoration: none;
	}

	& .c-active-title {
		display: inline-block;
	}

	& .c-content {
		position: relative;
		z-index: 30;
	}

	&__text {
		margin-top: 40px;
		font-weight: bold;
		font-size: 24px;
		line-height: 140%;
		color: $color-dark-grey;
		max-width: 500px;

		@include breakpoint('medium') {
			margin-top: 64px;
		}
	}

	&__link {
		font-size: 14px;
		line-height: 130%;
		color: $color-dark-grey;
		display: inline-block;
		text-decoration: none;
		border-bottom: 3px solid #08b3f0;
		margin-top: 32px;
		margin-bottom: 100px;

		@include breakpoint('medium') {
			&:hover {
				border-bottom: 3px solid transparent;
			}

			margin-bottom: 30px;
			font-size: 16px;
		}

		@include breakpoint('large') {
			margin-bottom: 75px;
		}
	}

	&__bg-image {
		position: absolute;
		background-image: url('/images/new.k2.img/contacts/contacts (3).png');
		background-position: bottom right;
		background-repeat: no-repeat;
		right: 0;
		z-index: 0;
		bottom: 0;
		height: 100%;
		width: 100%;

		@include breakpoint('medium') {
			background-image: url('/images/new.k2.img/contacts/contacts (2).png');
		}

		@include breakpoint('large') {
			background-image: url('/images/new.k2.img/contacts/contacts (1).png');
		}
	}
}
