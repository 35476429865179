@import "~style";

$block-name: 'b-articles-switcher';

.#{$block-name} {
	@include breakpoint(small) {
		@include xy-grid();

		display: flex;
		justify-content: space-between;
		margin-top: 70px;
		margin-bottom: 70px;

		&__article {
			@include xy-cell();

			&:first-child {
				margin-bottom: 40px;
			}
		}

		&__link {
			@include teaser-title-green--category();
		}

		&__title {
			@include teaser-article-title--narrow();

			display: block;
			margin: 14px 0;
		}
	}

	@include breakpoint(medium) {
		&__article {
			@include xy-cell(3);

			&:first-child {
				margin-bottom: 40px;
			}
		}
	}
}
