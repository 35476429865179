@import "~style";

$block-name: 'b-page-404';

.#{$block-name} {
	// background: black;
	overflow: hidden;
	position: relative;
	height: 850px;
	margin-top: -171px;
	background-color: $color-blue;
	// background-image: url("/images/new.k2.img/404-big.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	@include breakpoint(768) {
		display: flex;
		justify-content: flex-start;
		height: 700px;
	}

	@include breakpoint(1440) {
		height: 800px;
	}

	@include breakpoint(1600) {
		height: 900px;
	}

	// &__img-right {
	// 	width: 0;
	// 	height: 0;
	// 	background-image: url('/images/404/1440-1-3.svg');
	// 	background-size: contain;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	background-repeat: no-repeat;

	// 	@include breakpoint(768) {
	// 		top: 144px;
	// 		width: 131px;
	// 		height: 570px;
	// 		background-image: url('/images/404/768-3.svg');
	// 	}

	// 	@include breakpoint(1440) {
	// 		top: 0;
	// 		width: 331px;
	// 		height: 481px;
	// 		background-image: url('/images/404/1440-1-3.svg');
	// 	}
	// }

	// &__img-left {
	// 	max-width: 392px;
	// 	width: 100%;
	// 	height: 306px;
	// 	background-image: url('/images/404/360-2.svg');
	// 	background-size: contain;
	// 	position: absolute;
	// 	top: 70px;
	// 	left: 4px;
	// 	background-repeat: no-repeat;
	// 	transform: rotate(9deg);

	// 	@include breakpoint(365) {
	// 		max-width: 392px;
	// 		width: 100%;
	// 		height: 306px;
	// 		// background-image: url('/images/404/768-2.svg');
	// 		top: 50;
	// 		left: 0;
	// 		transform: rotate(0deg);
	// 	}

	// 	@include breakpoint(768) {
	// 		max-width: 612px;
	// 		width: 100%;
	// 		height: 435px;
	// 		background-image: url('/images/404/768-2.svg');
	// 		top: 0;
	// 	}

	// 	@include breakpoint(1440) {
	// 		max-width: 705px;
	// 		width: 100%;
	// 		height: 481px;
	// 		background-image: url('/images/404/1440-1-2.svg');
	// 	}
	// }

	&__img-bottom {
		max-width: 357px;
		width: 100%;
		height: 296px;
		background-image: url('/images/404/image.png');
		background-size: contain;
		position: absolute;
		bottom: 50px;
		right: 50%;
		transform: translate(50%, 0);
		// right: 20px;
		background-repeat: no-repeat;

		@include breakpoint(768) {
			max-width: 717px;
			width: 100%;
			height: 446px;
			background-image: url('/images/404/image.png');
			bottom: 0;
			right: 20%;
			transform: translate(50%, 0);
		}

		@include breakpoint(1440) {
			max-width: 650px;
			width: 100%;
			height: 680px;
			bottom: 0;
			background-image: url('/images/404/image.png');
			right: 10%;
			transform: translate(0, 0);
		}

		@include breakpoint(1600) {
			max-width: 750px;
			width: 100%;
			height: 680px;
			bottom: 0;
			background-image: url('/images/404/image.png');
			right: 10%;
			transform: translate(0, 0);
		}
	}

	&__wrapper {
		padding-top: 250px;

		& p {
			padding-bottom: 0;
		}

		@include breakpoint(768) {
			padding-top: 248px;
		}

		@include breakpoint(1440) {
			padding-top: 270px;
		}
	}

	&__title {
		margin-top: 0;
		color: $color-white;

		&--large {
			color: $color-white;
			margin: 0;
			padding-bottom: 0;
		}

		&--small {
			@include breakpoint(small) {
				font-size: 14px;
				color: $color-white;
				margin-top: 8px;
				max-width: 100%;
			}

			@include breakpoint(768) {
				font-size: 16px;
				margin-top: 16px;
				max-width: 320px;
			}
		}
	}

	&__search-container {
		display: flex;
		flex-wrap: nowrap;
		position: relative;
	}

	&__search {
		max-width: 348px;
		margin-top: 32px;

		@include breakpoint(768) {
			margin-top: 48px;
		}
	}

	&__search-form {
		width: 100%;
	}

	&__search-input {
		@include breakpoint(small) {
			font-size: 12px;
			padding: 13.5px 55px 13.5px 16px;
		}

		@include breakpoint(768) {
			font-size: 14px;
		}

		// border: none;
		background: $color-blue;
		border: $color-white 1px solid;
		border-radius: 3px;
		color: $color-white;
		width: 100%;

		&::placeholder {
			color: $color-white;
			opacity: 1;
		}
	}

	&__search-icon {
		@include breakpoint(small) {
			width: 16px;
			height: 16px;
		}

		display: block;
		background: url(~img/search.png) center no-repeat;
		background-size: contain;
		position: absolute;
		right: 16px;
		top: 16px;
		cursor: pointer;
	}

	&__additional-text,
	&__additional-text a {
		@include breakpoint(small) {
			font-size: 10px;
			max-width: 364px;
			margin-top: 8px;
		}

		@include breakpoint(768) {
			font-weight: 300;
			font-size: 12px;
			margin-top: 16px;
		}

		color: #cef0fc;
		letter-spacing: 0.12px;
	}

	&__additional-text a {
		text-decoration: none;
	}
}

.chrome-stub {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
