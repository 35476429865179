@import "~style";

$block-name: 'b-baner-image';

.#{$block-name} {
	position: relative;
	overflow: hidden;

	&__title.big_h2 {
		color: $color-white;
		max-width: 420px;
		font-size: 24px;
		line-height: 33.6px;

		@include breakpoint('medium') {
			font-size: 48px;
			max-width: 800px;
			line-height: 52.8px;
		}
	}

	&__container-logo {
		position: absolute;
		// height: 30px;
		display: flex;
		justify-content: flex-end;
		width: 300px;
		right: 20px;
		top: 10px;

		@include breakpoint('medium') {
			top: 30px;
			right: 10px;
		}

		@include breakpoint('large') {
			top: -25%;
			right: 0;
		}
	}

	&__logo {
		max-width: 130px;
		// position: absolute;
		justify-content: end;
		top: 10px;
		right: 10px;
		margin-bottom: 20px;
		display: none;

		&.mobile {
			display: flex;
		}

		@include breakpoint('medium') {
			max-width: 150px;
			top: 30px;
			right: 40px;
		}

		@include breakpoint('large') {
			margin-bottom: 0;
			max-width: 300px;
			top: -20%;
			display: flex;

			&.mobile {
				display: none;
			}
		}
	}

	&__text {
		color: $color-white;
		max-width: 100%;

		@include breakpoint('large') {
			max-width: 64%;
		}
	}

	// &__logo {
	// 	position: relative;
	// 	display: block;
	// }

	[data-tooltip]::after {
		content: attr(data-tooltip);
		position: absolute;
		opacity: 0;
		transition: 0.3s;
		pointer-events: none;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
		background-color: $color-blue;
		color: $color-white;
		padding: 5px;
		border-radius: 5px;
		top: calc(100% + 10px);
		max-width: 200px;
		width: 100%;
		display: block;
		z-index: 100000000;
		right: 0;
	}

	[data-tooltip]:hover::after {
		opacity: 1;
		top: calc(100% + 15px);
	}

	&__button {
		margin-top: 32px;

		& a {
			text-decoration: none;
		}

		@include breakpoint('large') {
			margin-top: 40px;
		}
	}

	&__image {
		width: 100%;
		height: 460px;
		z-index: -1;
		left: 0;
		top: 0;
		object-fit: cover;

		@include breakpoint('large') {
			height: auto;
		}
	}

	&__content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
