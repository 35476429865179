@import "~style";

$block-name: 'b-blog-articles-list';

.#{$block-name} {
	margin-bottom: 40px;

	&__teaser-container {
		margin-bottom: 40px;
	}

	&__title-container {
		// @include xy-cell();

		.navigation-breadcrumbs {
			margin: 0 0 18px;
			padding: 0 0 0 4px;
		}
	}

	&__teaser-container {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 18px;

		@include breakpoint(800) {
			grid-template-columns: 1fr 1fr;
			grid-row-gap: 32px;
			grid-column-gap: 32px;

			.b-section-top:nth-child(1) {
				grid-column-start: 1;
				grid-column-end: 3;
			}

			.b-section-top:nth-child(7n+8) {
				grid-column-start: 1;
				grid-column-end: 3;
			}
		}

		@include breakpoint(1440) {
			grid-template-columns: 1fr 1fr 1fr;

			.b-section-top:nth-child(1) {
				grid-column-start: 1;
				grid-column-end: 4;
			}

			.b-section-top:nth-child(7n+8) {
				grid-column-start: 1;
				grid-column-end: 4;
			}
		}
	}

	&:first-of-type {
		// padding-top: 20px;
	}

	&__section-tags {
		margin-bottom: 40px;

		div:first-of-type {
			@include xy-cell();
		}
	}

	.blog-article-title {
		// @include xy-cell();
		padding-bottom: 0;
		font-size: 48px;
		color: $color-dark-grey;
		line-height: 100%;

		@include breakpoint(medium) {
			line-height: 130%;
		}
	}

	.b-teaser {
		&__author-photo {
			display: none;
		}

		&__image-container {
			@include breakpoint(small only) {
				height: 140px;
			}
		}
	}

	&--section-page,
	&--tags-search-page {
		.b-teaser {
			&__tags {
				display: block;
			}
		}
	}

	.b-pager {
		margin: 0 auto 40px;
		color: $blog-grey;
		text-align: center;

		ul,
		.pagebtn {
			display: inline-block;
		}

		.prev {
			margin-right: 20px;
		}

		.next {
			margin-left: 10px;
		}

		.pagebtn {
			&.disabled {
				display: none;
			}
		}

		ul {
			list-style: none;
			text-align: center;
			padding: 0;

			li {
				display: inline;
				margin-right: 10px;
				font-weight: bold;
				color: $blog-grey;

				a {
					color: $blog-grey;

					&:hover {
						color: black;
					}

					.active {
						color: $color-blue-light;
					}
				}

				.active {
					color: $color-blue-light;
				}
			}
		}
	}
}
