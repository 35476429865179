@import "~style";

$block-name: 'b-arrows-animate';

.#{$block-name} {
	&__wrapper {
		display: flex;
		margin: 0 20px;

		&--no-margin {
			margin: 0;
		}
	}

	&__item {
		display: inline-block;
		background: url('/images/main_redisign/pages/arrows-animate.png');
		background-repeat: no-repeat;
		background-position: center;
		width: 18px;
		height: 18px;
		animation: arrow 2s infinite;

		&--color-white {
			background: url('/images/main_redisign/pages/arrows-animate-white.png');
		}

		&:nth-child(1) {
			animation-delay: -1s;
		}

		&:nth-child(2) {
			animation-delay: -0.5s;
		}

		&:nth-child(3) {
			animation-delay: 0s;
		}
	}

	@keyframes arrow {
		0% {
			opacity: 0;
		}

		40% {
			opacity: 1;
		}

		80% {
			opacity: 0;
		}

		100% {
			opacity: 0;
		}
	}
}
