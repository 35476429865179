@import "~style";

$block-name: 'b-picture-block';

.#{$block-name} {
	&__desc {
		margin-top: 16px;
		max-width: 100%;

		@include breakpoint('medium') {
			max-width: 66%;
		}
	}

	// & a {
	// 	display: block;
	// }

	&__image-block {
		//
	}

	&__image {
		margin-top: 40px;
		display: block;
		cursor: pointer;
		pointer-events: visible;

		@include breakpoint('medium') {
			margin-top: 48px;
			pointer-events: none;
			cursor: none;
		}

		@include breakpoint('large') {
			margin-top: 88px;
		}
	}

	&__img {
		width: 100%;
		max-width: 1000px;
	}
}

.fancybox__content {
	width: 100%;
	height: 100%;
}
