@import "~style";

$block-name: 'b-key-feature';

.#{$block-name} {
	&__container {
		margin-top: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 0;
		position: relative;

		@include breakpoint('medium') {
			margin-top: 15px;
			margin-bottom: -60px;
		}

		@include breakpoint('large') {
			margin-top: 24px;
		}

		&::before {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			height: 3px;
			background-color: $color-white;
			width: 100%;
			z-index: 12;
		}

		&.not-margin {
			margin-bottom: 20px;

			@include breakpoint('medium') {
				margin-bottom: 0;
			}
		}

		&.three {
			.#{$block-name}__item {
				border-bottom: none;
				border-top: 1px solid rgba(0, 0, 0, 0.2) !important;

				&:last-child,
				&:nth-last-child(2) {
					border-bottom: none;
				}

				@include breakpoint('medium') {
					width: 47.2%;
					padding: 40px 0;

					&:last-child,
					&:nth-last-child(2) {
						border: none;
					}
				}

				@include breakpoint('large') {
					width: 33.3%;
					flex-direction: row;
				}
			}
		}
	}

	&__desc {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		opacity: 0.9;
		color: #4d4d55;
		max-width: 100%;

		@include breakpoint('large') {
			max-width: 66%;
		}
	}

	&__item {
		padding: 32px 0;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		width: 100%;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);

		& img {
			margin-right: 48px;
		}

		&:last-child,
		&:nth-last-child(2) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}

		@include breakpoint('medium') {
			width: 47.2%;
			padding: 40px 0;

			&:last-child,
			&:nth-last-child(2) {
				border: none;
			}
		}

		@include breakpoint('large') {
			width: 47.5%;
			flex-direction: row;
		}
	}

	&__title {
		margin-top: 32px;

		@include breakpoint('medium') {
			margin-top: 35px;
		}

		@include breakpoint('large') {
			margin-top: 0;
		}
	}

	&__description {
		margin-top: 16px;

		&.nomargin {
			margin-top: 0;
		}

		&.icons {
			display: flex;
			align-items: flex-start;

			& div {
				padding-top: 8px;
			}

			&::before {
				flex: 0 0 auto;
				content: '';
				margin-right: 20px;
				background-image: url('/images/new.k2.img/checkpic.svg');
				background-position: center;
				background-repeat: no-repeat;
				// margin-top: 3px;
				width: 40px;
				height: 40px;
				// background-color: red;
				display: block;
			}
		}
	}

	& h3 {
		padding: 0;
	}

	ul li {
		position: relative;
		margin-left: 14px;

		@include breakpoint('medium') {
			margin-left: 0;
		}

		&::marker {
			font-size: 0;
		}

		&::after {
			top: 11;
			left: -14px;
			content: "";
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: $color-blue;
			position: absolute;

			@include breakpoint('medium') {
				left: -17px;
			}
		}
	}
}
