@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~style";

.ps__rail-y {
	cursor: pointer;
}

.ps__thumb-y {
	background-color: $color-gray-dark;
	opacity: 0.75;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
	background-color: $color-gray-dark;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
	background-color: transparent;
}
