@import "~style";

$block-name: 'b-banner-blog';

.#{$block-name} {
	padding: 50px 30px;
	margin: 20px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	border-radius: 3px;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0);
		z-index: 5;
	}

	&__title {
		position: relative;
		color: $color-white;
		line-height: 130%;
		font-size: 24px;
		z-index: 10;
		margin-bottom: 20px;
	}

	&__text {
		position: relative;
		color: $color-white;
		font-size: 18px;
		line-height: 22px;
		z-index: 10;
		margin-bottom: 30px;
	}

	& .b-link_button {
		position: relative;
		z-index: 10;
	}
}
