@import "~style";

$block-name: 'b-image-block';

.#{$block-name} {
	width: 100%;
	margin-top: -100px;
	position: relative;
	background-repeat: no-repeat;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
	height: 640px;

	@include breakpoint('medium') {
		margin-top: -139px;
		height: 800px;
	}

	@include breakpoint('large') {
		margin-top: -139px;
		height: 800px;
	}

	@include breakpoint('xlarge') {
		height: 900px;
	}

	&__image-container {
		// max-width: 2200px;
		// width: 100%;
		//margin-left: -50px;
		//width: calc(100% + 100px);

		@include breakpoint(430) {
			width: calc(100% + 100px);
			margin-left: -50px;
			margin-top: 0;
		}

		@include breakpoint('medium') {
			margin-top: -40px;
		}
	}

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		// transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);

		@include breakpoint(430) {
			height: 800px;
			object-fit: cover;
		}

		@include breakpoint(1600) {
			height: 1000px;
		}
	}

	&__wrapper {
		position: absolute;
		width: 100%;
		top: 0;
		// height: 100%;
		// bottom: 200px;
	}

	&__logo-container {
		position: absolute;
		display: flex;
		align-items: center;
		top: 70px;
		left: 0;

		// top: 88px;

		@include breakpoint('medium') {
			top: 70px;
		}

		@include breakpoint('large') {
			top: 60px;
		}

		@include breakpoint(1200) {
			top: 60px;
		}

		@include breakpoint('xlarge') {
			top: 125px;
		}
	}

	&__text-logo {
		color: $color-blue;
		line-height: 100%;
		margin-right: 25px;
		font-size: 14px;
		padding-top: 3px;
		font-weight: 400;
	}

	&__logo-image {
		max-width: 105px;

		@include breakpoint('medium') {
			max-width: 130px;
		}

		img {
			height: auto;
		}
	}

	& .c-content {
		position: relative;
		margin: 0 auto;
		width: calc(100% - 40px);

		@include breakpoint('large') {
			width: calc(86%);
			max-width: 1600px;
		}
	}

	&__bread-crumbs {
		&.c-body-text {
			color: $color-white;
		}
	}

	&__title {
		max-width: var(--width);
		margin-top: 98px;

		& h1 {
			padding-bottom: 0;
			line-height: 40px;

			@include breakpoint('medium') {
				line-height: 70px;
			}
		}

		@include breakpoint('medium') {
			margin-top: 108px;
		}

		@include breakpoint('large') {
			margin-top: 87px;
		}

		@include breakpoint(1200) {
			margin-top: 87px;
		}

		@include breakpoint('xlarge') {
			margin-top: 180px;
		}
	}

	&__bread-crumbs {
		margin-top: 115px;

		@include breakpoint('medium') {
			margin-top: 164px;
		}

		@include breakpoint('large') {
			margin-top: 175px;
		}

		@include breakpoint(1200) {
			margin-top: 190px;
		}

		@include breakpoint('xlarge') {
			margin-top: 191px;
		}
	}

	&__description {
		max-width: 615px;
		margin-top: 16px;

		&.c-lead {
			font-weight: 400;
		}

		@include breakpoint('medium') {
			margin-top: 32px;
		}
	}

	&__button {
		margin-top: 37px;
		display: flex;
		flex-wrap: wrap;

		& a {
			margin-top: 13px;
		}

		@include breakpoint('medium') {
			margin-top: 48px;
		}
	}

	&__link {
		& a {
			display: inline-block;
			margin-top: 32px;
			color: $color-white;
			line-height: 150%;
			transition: border-bottom 0.4s;
			border-bottom: 1px solid $color-white;
			text-decoration: none;

			&:hover {
				transition: border-bottom 0.4s;
				border-bottom: 1px solid transparent;
			}
		}
	}
}
