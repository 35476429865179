@import "~style";

$block-name: 'b-main-why';

.#{$block-name} {
	&.grey {
		background-color: #f6f8fb;
	}

	&.arrow {
		& .b-main-why__items {
			margin-top: -70px;

			@include breakpoint('medium') {
				margin-top: -56px;
			}
		}

		& .b-main-why__item {
			border-bottom: none;
			position: relative;
			height: auto;
			min-height: auto;
			margin-top: 70px;

			@include breakpoint('medium') {
				margin-top: 56px;
				min-height: 180px;
				// border-bottom: 1px solid #cfcaca;
			}
		}

		& .b-main-why__title {
			margin-top: 0;

			@include breakpoint('medium') {
				margin-top: 16px;
			}
		}

		& .b-main-why__line {
			position: absolute;
			width: 1px;
			height: 30px;
			background: #cfcaca;
			top: calc(102% + 15px);
			left: 33px;

			@include breakpoint('medium') {
				width: 60px;
				height: 1px;
				top: calc(50% - 1px);
				left: calc(100% + 20px);
			}

			@include breakpoint(1440) {
				width: 100px;
				left: calc(100% + 50px);
			}

			&::before {
				content: "";
				width: 15px;
				height: 1px;
				background: #cfcaca;
				position: absolute;
				bottom: 6px;
				right: -1px;
				transform: rotate(45deg);

				@include breakpoint('medium') {
					transform: rotate(25deg);
					bottom: 3px;
					right: 0;
				}
			}

			&::after {
				content: "";
				width: 15px;
				height: 1px;
				background: #cfcaca;
				position: absolute;
				bottom: 6px;
				right: -13px;
				transform: rotate(-45deg);

				@include breakpoint('medium') {
					transform: rotate(-25deg);
					bottom: -3px;
					right: 0;
				}
			}
		}
	}

	h2 {
		margin-bottom: 40px;
		line-height: 35px;

		@include breakpoint('medium') {
			line-height: 62.4px;
			margin-bottom: 56px;
		}

		@include breakpoint('large') {
			margin-bottom: 80px;
		}

		@include breakpoint('xlarge') {
			margin-bottom: 80px;
		}
	}

	&__items {
		display: flex;
		flex-wrap: nowrap;
		height: auto;
		width: auto;
		margin-top: -56px;
		margin-left: 0;

		&.items {
			flex-wrap: wrap;
		}

		@include breakpoint('medium') {
			margin-left: -120px;
			justify-content: flex-start;
			margin-top: -56px;
			flex-wrap: wrap;
		}

		@include breakpoint('large') {
			margin-left: -100px;
			justify-content: space-between;
		}

		@include breakpoint(1440) {
			margin-left: -240px;
		}

		@include breakpoint('xlarge') {
			margin-left: -240px;
		}

		&.size {
			.b-main-why__title a {
				font-size: 16px;
			}

			.b-main-why__item {
				border-bottom: none;
				height: auto;
			}

			img {
				width: auto;
				height: 60px;
			}

			@include breakpoint('medium') {
				.b-main-why__item {
					height: 180px;
				}
			}

			@include breakpoint('large') {
				.b-main-why__title a {
					font-size: 20px;
				}

				img {
					width: auto;
					height: 80px;
				}
			}
		}
	}

	&__item {
		margin-top: 56px;
		width: 100%;
		height: 180px;
		margin-left: 0;
		border-bottom: 1px solid #efefef;

		@include breakpoint('medium') {
			width: calc(100% / 2 - 120px);
			margin-left: 120px;
			max-width: 260px;
			margin-top: 56px;

			&:nth-last-of-type(-n+2) {
				border-bottom: 1px solid transparent;
			}

			&:nth-child(2n+2) {
				& .b-main-why__line {
					display: none;

					&::after,
					&::before {
						display: none;
					}
				}
			}
		}

		@include breakpoint('large') {
			width: calc(100% / 3 - 100px);
			margin-left: 100px;
			max-width: 100%;

			&:nth-last-of-type(-n+3) {
				border-bottom: 1px solid transparent;
			}

			&:nth-child(2n+2) {
				& .b-main-why__line {
					display: block;

					&::after,
					&::before {
						display: block;
					}
				}
			}

			&:nth-child(3n+3) {
				& .b-main-why__line {
					display: none;

					&::after,
					&::before {
						display: none;
					}
				}
			}
		}

		@include breakpoint(1440) {
			width: calc(100% / 3 - 240px);
			margin-left: 240px;
		}

		@include breakpoint('xlarge') {
			width: calc(100% / 3 - 240px);
			margin-left: 240px;
		}

		&:last-child {
			margin-right: auto;

			& .b-main-why__line {
				display: none;

				&::after,
				&::before {
					display: none;
				}
			}
		}
	}

	&__title {
		margin-top: 16px;
		// padding-bottom: 56px;
		display: flex;
		flex: 0 0 auto;
		justify-content: space-between;

		&.active {
			cursor: pointer;

			&::after {
				content: '';
				flex: 1 1 auto;
				width: 15.5px;
				padding-left: 5px;
				height: 16.5px;
				margin-top: 2px;
				display: block;
				-webkit-mask-image: url('/images/new.k2.img/Vector936.svg');
				mask-image: url('/images/new.k2.img/Vector936.svg');
				-webkit-mask-size: contain;
				mask-size: contain;
				-webkit-mask-position: center;
				mask-position: center;
				-webkit-mask-repeat: no-repeat;
				mask-repeat: no-repeat;
				background-color: #838383;
			}

			&:hover {
				& a {
					color: #08b3f0;
				}

				&.active::after {
					background-color: #08b3f0;
				}
			}
		}

		& a {
			display: inline-block;
			width: 100%;
			text-decoration: none;
			color: $color-dark-grey;
			font-size: 16px;
			opacity: 0.9;
			padding-right: 10px;
		}
	}

	&__pagination {
		margin-top: 40px;
		position: relative;
		display: block;

		@include breakpoint('medium') {
			display: none;
		}

		& .swiper-pagination-bullet {
			&-active {
				transition: width 0.6s;
				width: 48px;
				border-radius: 4px;
			}
		}
	}
}
