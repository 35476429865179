@import "~style";

$block-name: 'b-main-datacenters';
.#{$block-name} {
	min-height: 800px;
	height: auto;
	padding-top: 88px;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 60% center;
	padding-bottom: 80px;

	@include breakpoint(medium) {
		padding-bottom: 100px;
		padding-top: 125px;
		background-position: center;
	}

	&__title {
		font-size: 34px;
		line-height: 34px;
		color: $color-white;
		margin-bottom: 60px;
		padding-right: 35px;
		display: inline-block;
		text-decoration: none;

		img {
			margin-left: 15px;
			display: none;

			@include breakpoint('medium') {
				display: inline-block;
			}
		}

		@include breakpoint(large) {
			margin-bottom: 250px;
			font-size: 48px;
			line-height: 62px;
		}
	}

	&__items {
		display: flex;
		margin-bottom: 80px;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__item {
		margin-right: auto;
		color: $color-white;
		margin-top: 40px;
		width: 100%;
		padding-right: 0;

		@include breakpoint('medium') {
			width: calc(100% / 2);
			padding-right: 20px;
		}

		@include breakpoint('large') {
			width: calc(100% / 3);
		}

		&-inner {
			max-width: 300px;
		}
	}

	&__item-icon {
		margin-bottom: 40px;
	}

	&__item-title {
		font-size: 24px;
		line-height: 33px;
		margin-bottom: 15px;
	}

	&__item-description {
		font-size: 16px;
		line-height: 20px;
		opacity: 0.6;
	}

	&__link {
		font-size: 18px;
		line-height: 20px;
		color: $color-white;
		text-decoration: none;
		border-bottom: 2px solid $color-blue;

		&:hover {
			border-bottom: none;
		}
	}
}
