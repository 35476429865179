@import "~style";

$block-name: 'b-information-about';

.#{$block-name} {
	&__container {
		padding: 0 20px;
		background-color: #f6f8fb;
		margin-top: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include breakpoint('medium') {
			margin-top: 15px;
		}

		@include breakpoint('large') {
			margin-top: 24px;
			padding: 0 112px;
		}

		&.no-margin {
			margin-top: 0;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
	}

	&__desc {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		opacity: 0.9;
		color: #4d4d55;
		max-width: 100%;

		@include breakpoint('large') {
			max-width: 66%;
		}
	}

	& .b-link_button {
		margin-top: 10px;
	}

	&__item {
		padding: 32px 0;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		width: 100%;
		// border-bottom: 1px solid rgba(0, 0, 0, 0.2);

		& img {
			margin-right: 48px;
		}

		&:last-child,
		&:nth-last-child(2) {
			// border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}

		@include breakpoint('medium') {
			padding: 40px 0;

			&:last-child,
			&:nth-last-child(2) {
				border: none;
			}
		}

		@include breakpoint('large') {
			flex-direction: row;
		}
	}

	&__title {
		margin-top: 32px;

		@include breakpoint('medium') {
			margin-top: 35px;
		}

		@include breakpoint('large') {
			margin-top: 0;
		}

		& .big_h3 {
			margin-bottom: 0;
		}
	}

	.big_h2 {
		font-size: 18px;
		line-height: 100%;

		@include breakpoint('medium') {
			font-size: 22px;
			line-height: 100%;
		}

		@include breakpoint('large') {
			font-size: 38px;
			line-height: 100%;
		}
	}

	&__description {
		margin-top: 8px;
	}

	& h3 {
		padding: 0;
	}
}
