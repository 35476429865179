@import "~style";

$block-name: 'b-breeding-block-services';

.#{$block-name} {
	padding: 40px 0 88px;

	@include breakpoint(768) {
		padding: 64px 0 104px;
	}

	@include breakpoint(1440) {
		padding: 64px 0 160px;
	}

	&__container {
		display: grid;
		grid-auto-rows: 1fr;
		grid-template-columns: 1fr;
		grid-column-gap: 32px;
		grid-row-gap: 16px;

		@include breakpoint(768) {
			grid-template-columns: 1fr 1fr;
			grid-row-gap: 32px;
		}

		@include breakpoint(1440) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}
