@import "~style";

$block-name: 'b-teaser-event';

.#{$block-name} {
	height: 100%;
	background-color: $color-light-blue;

	@include breakpoint(large) {
		&:hover {
			background-color: $color-blue;
		}
	}

	&__wrapper-data {
		display: flex;
		margin-bottom: 40px;
		align-items: center;
	}

	&__time {
		display: flex;
		align-items: center;
		font-size: 12px;
		color: $color-dark-grey;

		&::before {
			content: "";
			margin-left: 16px;
			margin-right: 4px;
			width: 24px;
			height: 24px;
			display: block;
			mask-image: url('/images/k2-icons/time.svg');
			-webkit-mask-image: url('/images/k2-icons/time.svg');
			-webkit-mask-size: cover;
			mask-size: cover;
			-webkit-mask-position: center;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			background-color: $color-blue;
		}
	}

	&__wrapper {
		height: 100%;
		min-height: 370px;
		padding: 32px 20px 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		text-decoration: none;

		@include breakpoint(large) {
			min-height: 336px;
			padding: 32px 24px 24px;

			&:hover {
				.#{$block-name}__type {
					color: $color-white;
				}

				.#{$block-name}__button {
					background-color: $color-white;
					color: $color-dark-grey;
				}

				.#{$block-name}__time {
					color: $color-white;

					&::before {
						background-color: $color-white;
					}
				}

				.#{$block-name}__article-title {
					& a {
						color: $color-white;
					}
				}

				.#{$block-name}__preview-text {
					color: $color-white;
				}

				.#{$block-name}__date {
					color: $color-white;
					opacity: 1;

					&::before {
						background-color: $color-white;
					}
				}

				.#{$block-name}__counters {
					&--readtime {
						color: $color-white;
					}

					&--views {
						color: $color-white;
					}
				}

				.#{$block-name}__counters-readtime {
					background-color: $color-white;
				}

				.#{$block-name}__counters-views {
					background-color: $color-white;
				}
			}
		}
	}

	&__type {
		color: $color-blue;
	}

	&__article-title {
		& a {
			color: $color-dark-grey;
			text-decoration: none;
		}
	}

	&__date {
		display: flex;
		align-items: center;
		font-size: 12px;
		text-decoration: none;
		color: $color-dark-grey;

		&::before {
			content: "";
			margin-right: 4px;
			margin-left: 16px;
			width: 24px;
			height: 24px;
			display: block;
			mask-image: url('/images/k2-icons/calendar.svg');
			-webkit-mask-image: url('/images/k2-icons/calendar.svg');
			-webkit-mask-size: cover;
			mask-size: cover;
			-webkit-mask-position: center;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			background-color: $color-blue;
		}
	}

	@include breakpoint(small) {
		@include xy-grid($direction: vertical);

		width: 100%;

		&__image-container {
			width: 100%;
			margin-bottom: 30px;
		}

		&__image {
			height: 100%;
			object-fit: cover;
		}

		&__main {
			@include xy-cell(auto);
		}

		&__article-title {
			display: block;
			margin-bottom: 17px;
			font-size: 24px;
			font-weight: 700;
			text-decoration: none;
			color: $color-dark-grey;
			line-height: 140%;
		}

		&__preview-text {
			@include teaser-preview-text();

			display: block;
			margin-bottom: 24px;
		}
	}

	&__button {
		display: inline-block;
		background-color: $color-blue;
		padding: 9.5px 16px;
		color: $color-white;
		border-radius: 3px;
		text-decoration: none;
	}

	&__counters {
		display: flex;
		align-items: center;

		&--readtime {
			font-size: 12px;
			margin-right: 20px;
			color: $color-dark-grey;
		}

		&--views {
			font-size: 12px;
			color: $color-dark-grey;
		}
	}

	&__counters-readtime {
		margin-right: 4px;
		width: 24px;
		height: 24px;
		display: block;
		mask-image: url('/images/k2-icons/time.svg');
		-webkit-mask-image: url('/images/k2-icons/time.svg');
		-webkit-mask-size: cover;
		mask-size: cover;
		-webkit-mask-position: center;
		mask-position: center;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		background-color: $color-dark-grey;
	}

	&__counters-views {
		margin-right: 4px;
		width: 24px;
		height: 24px;
		display: block;
		mask-image: url('/images/k2-icons/eye.svg');
		-webkit-mask-image: url('/images/k2-icons/eye.svg');
		-webkit-mask-size: cover;
		mask-size: cover;
		-webkit-mask-position: center;
		mask-position: center;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		background-color: $color-dark-grey;
	}
}
