@import "~style";

$block-name: 'b-cookie-notification';

.#{$block-name} {
	// display: flex;
	// flex-direction: column;
	// flex-wrap: nowrap;
	// justify-content: space-between;
	position: fixed;
	bottom: 20px;
	right: 20px;
	padding: 32px 32px;
	margin-left: 20px;
	background: #f6f8fb;
	box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);
	z-index: 9999;
	max-width: 560px;

	@include breakpoint('medium') {
		bottom: 48px;
	}

	@include breakpoint('large') {
		right: 7%;
	}

	&__content {
		@include breakpoint(large) {
			max-width: 80%;
		}

		font-size: 12px;
		color: #6f6f76;
		padding-right: 10px;
	}

	& .h4 {
		color: #6f6f76;
	}

	&__close {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 20px;
		height: 100%;
		background-image: url(~img/icon_close.png);
		background-repeat: no-repeat;
		background-size: contain;
		cursor: pointer;
		flex-shrink: 0;
	}

	&__button {
		display: inline-block;
		background: #08b3f0;
		border-radius: 3px;
		color: $color-white;
		padding: 17.5px 69.5px;
		margin-top: 24px;
		cursor: pointer;
	}

	& a {
		border-bottom: 1px solid #08b3f0;
		text-decoration: none;
	}
}
