@import "~style";

$block-name: 'b-article-header';

.#{$block-name} {
	&__desc {
		margin-right: 0;

		@include breakpoint(large) {
			margin-right: calc((100% - 960px) / 2);
		}
	}

	&__podcasts {
		margin: 30px 0;

		@include breakpoint(large) {
			margin: 50px 0;
		}
	}

	@include breakpoint(small) {
		@include xy-grid();

		.h1 {
			margin: 0.67em 0;
		}

		// margin-bottom: 40px;

		&__content {
			display: flex;
			width: 100%;
			margin-right: -32px;
			flex-wrap: wrap;
		}

		&__left {
			width: 100%;
		}

		&__button {
			margin-bottom: 40px;

			@include breakpoint(medium) {
				margin-bottom: 64px;
			}

			@include breakpoint(large) {
				margin-bottom: 80px;
			}
		}

		&__right {
			width: 100%;
		}

		&__dark {
			margin-bottom: 24px;
			height: 200px;
			max-height: 200px;

			@include breakpoint(medium) {
				margin-bottom: 32px;
			}

			@include breakpoint(large) {
				margin-bottom: 160px;
			}

			@include breakpoint(400) {
				height: 100%;
				max-height: 100%;
			}

			img {
				height: 200px;
				object-fit: cover;

				@include breakpoint(400) {
					height: auto;
					object-fit: cover;
				}
			}
		}

		&__categoryUrl {
			font-size: 12px;
			line-height: 18px;
			color: $color-dark-grey;
			padding: 7px 9px;
			background: $color-light-blue;
			text-decoration: none;
		}

		&__title {
			@include block-title();

			font-weight: 400;
			// max-width: 800px;
			color: $color-dark-grey;
			padding-bottom: 0;

			&.section {
				margin: 1rem 0;

				@include breakpoint(large) {
					margin: 0;
				}
			}

			@include breakpoint(large) {
				padding-bottom: 2rem;
			}
		}

		&__counters-datetime {
			margin-left: -27px;
			margin-top: 10px;

			@include breakpoint(medium) {
				margin-left: 0;
				margin-top: 0;
			}
		}

		&__counters {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			&--event {
				align-items: flex-start;
				flex-direction: column;
				margin-top: -10px;

				@include breakpoint(medium) {
					flex-direction: row;
					align-items: center;
				}
			}

			&--event > * {
				margin-right: 20px;
			}

			div {
				margin-right: 15px;
				padding-left: 27px;
				color: $color-dark-grey;
				position: relative;
				display: flex;
				// margin-top: 10px;
				font-size: 16px !important;
				line-height: 16px !important;

				@include breakpoint(medium) {
					font-size: 20px !important;
					line-height: 20px !important;
				}
			}

			&-date {
				font-size: 16px !important;
				line-height: 16px !important;

				@include breakpoint(medium) {
					font-size: 20px !important;
					line-height: 20px !important;
				}

				&::after {
					position: absolute;
					content: '';
					height: 24px;
					width: 24px;
					left: 0;
					top: 44%;
					transform: translateY(-50%);
					background-image: url('/images/blog/detail/icon-calendar.svg');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				}
			}

			&-view {
				&::after {
					position: absolute;
					content: '';
					height: 20px;
					width: 20px;
					left: 0;
					top: 44%;
					transform: translateY(-50%);
					background-image: url('/images/blog/detail/icon-view.svg');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				}
			}

			&-time {
				font-size: 16px !important;
				line-height: 16px !important;

				@include breakpoint(medium) {
					font-size: 20px !important;
					line-height: 20px !important;
				}

				&::after {
					position: absolute;
					content: '';
					height: 18px;
					width: 16px;
					left: 0;
					top: 44%;
					transform: translateY(-50%);
					background-image: url('/images/blog/detail/icon-time.svg');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				}
			}
		}

		&__eventType {
			font-weight: 700;
			font-size: 24px;
			line-height: 33px;
			color: $color-blue;
		}

		&__tags-container {
			margin-bottom: 32px;
			margin-top: 14px;

			@include breakpoint(medium) {
				margin-bottom: 64px;
			}

			@include breakpoint(large) {
				margin-top: 28px;
				margin-bottom: 0;
			}

			a {
				text-decoration: none;
				margin-right: 15px;
				font-size: 12px;
				line-height: 18px;
				color: $color-dark-grey;
				opacity: 0.4;
			}
		}
	}

	@include breakpoint(large) {
		// margin-bottom: 80px;

		&__right {
			margin-left: 32px;
			max-width: calc(100% / 3 - 32px);
			padding-top: 55px;
		}

		&__left {
			max-width: calc(100% - 100% / 3 - 32px);
		}
	}

	@include breakpoint(xlarge) {
		&--no-picture {
			height: inherit;
			top: 0;
			margin-bottom: 40px;

			.#{$block-name}__content {
				position: static;
			}
		}

		&__background {
			object-fit: cover;
			width: 100%;
			height: 470px;
		}

		&__dark {
			position: relative;
			display: block;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				// background-color: rgba(0, 0, 0, 0.5);
				z-index: 1;
			}
		}

		&__content {
			display: flex;
			z-index: 2;
		}

		&__title {
			font-size: 50px;
			line-height: 54px;

			&--color_white {
				color: white;
			}
		}
	}
}
