@import "~style";

$block-name: 'b-main';

.#{$block-name} {
	.content-note {
		padding: 32px;
		margin: 32px 0;
		background: $color-light-blue;
		border-radius: 3px;
		box-sizing: border-box;
	}

	&__btn-container-top {
		display: none;
		margin-bottom: 20px;

		@include breakpoint(1000) {
			margin-bottom: 30px;
			display: block;
		}
	}

	&__mobile-btn {
		display: block;
		margin-bottom: 20px;

		@include breakpoint(1000) {
			margin-bottom: 30px;
			display: none;
		}
	}

	@include breakpoint(small) {
		& p {
			margin-bottom: 0;
		}

		img.author {
			width: 150px;
			height: 150px;
		}

		.cashoff {
			display: flex;
			align-items: center;

			p {
				padding-left: 24px;
				padding-bottom: 0;
			}
		}

		&__right {
			width: 100%;
			display: flex;
			justify-content: end;
			flex-direction: column;
			margin-left: auto;

			h2,
			h3,
			h4 {
				font-weight: 400;
			}
		}

		&__left {
			width: 100%;

			@include breakpoint(1000) {
				max-width: calc(100% / 3);
			}
		}

		&__title-list {
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			color: $color-dark-grey;
		}

		a {
			span {
				color: $color-gray-content !important;
			}

			span.name {
				color: $color-dark-grey !important;
			}

			&:hover {
				& span.name {
					color: $color-blue-light !important;
				}
			}
		}

		&__author-photo {
			width: 106px;
			height: 106px;
			border-radius: 53px;
			background-size: cover;
			background-repeat: no-repeat;
			margin-bottom: 10px;
		}

		&__name {
			margin: 20px 0;

			& span {
				border-bottom: 1px solid $color-gray-content;
				color: $color-gray-content;
				font-size: 14px;
				text-transform: uppercase;
				letter-spacing: 0.7px;
				font-weight: bold;
			}
		}

		&__post {
			color: #6b6b6b;
			font-size: 15px;
			line-height: 20px;
		}

		&__title {
			font-weight: 700;
			font-size: 24px;
			line-height: 33px;
			color: $color-dark-grey;
			margin-bottom: 30px;
		}

		&__detailText {
			margin-bottom: 20px;
			word-wrap: break-word;

			h3 {
				font-size: 20px;
				line-height: 24px;
			}

			@include breakpoint(1000) {
				font-size: 18px;
				line-height: 22px;
			}

			h2 {
				padding-top: 30px;
			}
		}

		&__author {
			text-decoration: none;
			margin-bottom: 30px;
			display: block;
			max-width: 240px;

			span {
				border: none;
				font-size: 16px;
				text-transform: none;
				color: $color-dark-grey;
			}

			p {
				color: $color-dark-grey !important;
				padding-bottom: 12px;
			}
		}

		&__contents {
			margin-bottom: 40px;
			padding-right: 20px;

			@include breakpoint(1000) {
				margin-bottom: 20px;
				padding-right: 40px;
			}

			.b-main_button {
				position: relative;
				top: 50px;
			}
		}

		&__button {
			margin-top: 10px;
			margin-bottom: 40px;

			@include breakpoint(1000) {
				margin-top: 40px;
			}
		}

		&__contents-mobile {
			margin-bottom: 50px;
		}

		&__item {
			margin: 0;
			list-style: none !important;
			background: none !important;
			padding: 0;
			margin-bottom: 15px;
		}

		&__item-anchor {
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			color: $color-dark-grey;
			text-decoration: none;

			&:hover {
				color: $color-gray-content;
			}

			&--active {
				color: $color-gray-content;
			}
		}

		&__item-anchor-count {
			margin-right: 15px;
			color: $color-dark-grey;
		}

		&__save {
			//
		}

		&__move-block {
			transition: padding-top 1s ease;
			pointer-events: auto;
			position: relative;
			z-index: 1;
			// display: none;
			// opacity: 0;
			display: block;
			opacity: 1;
			width: 100%;

			@include breakpoint(1000) {
				position: absolute;
				width: 25%;

				&--move {
					top: 0;
					padding-top: 160px;
					position: fixed;
				}
			}
		}

		// &__move-block {
		// 	display: block;
		// 	opacity: 1;
		// 	width: 33%;

		// 	@include breakpoint(1000) {
		// 		display: block;
		// 		opacity: 1;
		// 		position: absolute;
		// 		width: 33%;
		// 	}
		// }

		&__content-list {
			list-style: decimal;
			color: $color-gray-content;
		}

		&__share {
			margin-bottom: 50px;
		}

		&__icons {
			display: flex;
			align-items: center;
			padding: 0;
		}

		&__icon {
			display: inline-block;
			list-style: none;
			margin: 0;
			cursor: pointer;
			border: 1px solid rgba($color-dark-grey, 0.4);
			width: 40px;
			height: 40px;
			border-radius: 50%;

			a {
				display: block;
				height: 100%;
				width: 100%;
			}

			&:not(:last-child) {
				margin-right: 15px;
			}

			&:hover {
				border-color: $color-blue;
			}

			&--fb {
				background: url("/images/blog/detail/icon-fb.png") center no-repeat;
			}

			&--vk {
				background: url("/images/blog/detail/icon-vk.png") center no-repeat;
			}

			&--ok {
				background: url("/images/blog/detail/icon-ok.png") center no-repeat;
			}

			&--tw {
				background: url("/images/blog/detail/icon-tw.png") center no-repeat;
			}

			&--bookmark {
				background: url("/images/blog/detail/icon-bookmark.png") center no-repeat;
			}

			&--print {
				background: url("/images/blog/detail/icon-print.png") center no-repeat;
			}
		}

		& ul {
			margin-top: 0;
		}
	}

	@include breakpoint(medium) {
		&__title {
			&--right {
				font-size: 20px;
			}

			margin-bottom: 32px;
		}
	}

	@include breakpoint(large) {
		& ul {
			// margin-top: 32px;
		}

		&__detailText {
			margin-bottom: 30px;
		}

		&__contents-mobile {
			display: none;
		}

		&__share {
			margin-bottom: 0;
		}

		&__icon {
			margin-bottom: 40px;
		}

		&__button {
			max-width: calc(73% - 32px);
			margin-left: auto;
			padding-left: 32px;
		}

		&__right {
			max-width: calc(73% - 32px);
			padding-left: 32px;
		}
	}

	@include breakpoint(xlarge) {
		&__icons {
			flex-direction: row;
		}

		&__icon {
			margin-bottom: 0;
		}

		&__contents {
			display: block;
		}
	}
}

.slick-slide img,
.b-slider,
.b-slider__content-slider {
	height: auto !important;
	max-height: none !important;
}
