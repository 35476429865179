@import "~style";

$block-name: 'b-sitemap';

.#{$block-name} {
	font-size: 16px;
	margin-bottom: 30px;

	li {
		color: $color-dark-grey;
		list-style-type: none;
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 10px;
		padding-left: 0;

		&.level0 {
			font-size: 30px;
			line-height: 38px;

			& > a,
			& > span {
				font-weight: bold;
			}
		}

		&.level1 {
			font-size: 16px;
			line-height: 27px;
			list-style-type: disc;
			margin-left: 20px;
			position: relative;

			&::marker {
				font-size: 0;
			}

			&::before {
				content: "•";
				position: absolute;
				color: $color-black;
				font-size: 16px;
				left: -15px;
			}

			li {
				position: relative;
			}

			li::before {
				content: "•";
				position: absolute;
				color: $color-black;
				font-size: 16px;
				left: -15px;
			}
		}
	}

	ul {
		padding-top: 10px;
		list-style-type: none;

		&.level0 {
			padding-top: 0;
		}

		&.level2 {
			display: flex;
			flex-direction: column;

			ul {
				padding-left: 20px;
			}
		}

		.add_links {
			.level2 {
				& > li {
					@include xy-cell(12);
				}
			}
		}
	}

	a {
		color: $color-dark-grey;
		text-decoration: none;
	}
}
