@import "~style";

$block-name: 'b-block-type-disk';

.#{$block-name} {
	background-color: $color-light-blue;

	&.white {
		background-color: $color-white;
	}

	&.border-none {
		.#{$block-name}__item {
			border: none;
		}
	}

	&.margin-mobile-none {
		.#{$block-name}__item {
			margin-top: 0;

			&:last-child {
				padding-top: 0;
			}

			@include breakpoint('medium') {
				margin-top: 32px;

				&:last-child {
					padding-top: 16px;
				}
			}
		}

		.#{$block-name}__items {
			margin-top: -32px;

			@include breakpoint('medium') {
				margin-top: -32px;
			}
		}
	}

	&__title {
		font-size: 34px;
		line-height: 130%;
		color: $color-dark-grey;
		margin-bottom: 40px;

		@include breakpoint('medium') {
			font-size: 48px;
			margin-bottom: 64px;
		}
	}

	&__desc {
		max-width: 100%;
		color: #808087;
		margin-bottom: 30px;

		@include breakpoint('large') {
			max-width: 66%;
		}
	}

	&__items {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-left: -32px;
		margin-top: -32px;

		// display: grid;
		// grid-template-columns: 1fr;
		// column-gap: 0;
		// margin-top: -40px;

		// @include breakpoint('medium') {
		// 	column-gap: 120px;
		// 	grid-template-columns: repeat(2, 1fr);
		// 	margin-top: -64px;
		// }

		// @include breakpoint('large') {
		// 	column-gap: 240px;
		// 	grid-template-columns: repeat(3, 1fr);
		// 	margin-top: -100px;
		// }

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: -1px;
			width: 3px;
			height: 100%;
			background-color: $color-white;
			z-index: 2;
		}
	}

	&__item {
		// max-width: 100%;
		border-right: 1px solid #dbdbdd;
		// padding-top: 32px;
		width: calc(100% / 1 - 32px);
		margin-top: 32px;
		padding-top: 16px;
		margin-left: 32px;

		&:last-child {
			border-right: none;
		}

		@include breakpoint('medium') {
			width: calc(100% / 2 - 32px);
		}

		@include breakpoint('large') {
			width: calc(100% / 3 - 32px);
		}
	}

	&__item-title {
		margin-bottom: 16px;
		font-size: 24px;
		line-height: 130%;
		color: $color-blue;

		@include breakpoint('medium') {
			font-size: 24px;
		}
	}

	&__item-desc {
		font-weight: normal;
		font-size: 16px;
		line-height: 150%;
		// margin-bottom: 32px;
		padding-right: 5px;

		@include breakpoint('medium') {
			font-size: 16px;
			line-height: 130%;
			// margin-bottom: 56px;
		}
	}
}
