@import "~style";

$block-name: 'b-content-block';

.#{$block-name} {
	.content-note {
		padding: 32px;
		margin: 32px 0;
		background: $color-light-blue;
		border-radius: 3px;
		box-sizing: border-box;
	}

	h2,
	h3,
	h4 {
		font-weight: 400;
	}

	@include breakpoint(small) {
		font-size: 16px;
		line-height: 18px;

		&__title {
			padding-bottom: 0;
		}

		width: 100%;
		display: flex;
		justify-content: end;
		flex-direction: column;
		margin-left: auto;

		&__textInsertion {
			margin: 40px 0;
		}

		&__textInsertion-title {
			color: #080808;
			font-size: 48px;
			font-weight: 400;
			text-align: left;
			margin-bottom: 0;
		}

		&__textInsertion-description {
			color: #6b6b6b;
			font-size: 15px;
			font-weight: 400;
			text-align: left;
		}

		&__title {
			font-weight: 700;
			font-size: 24px;
			line-height: 33px;
			color: $color-dark-grey;
			margin-bottom: 30px;
			margin-top: 20px;

			@include breakpoint('medium') {
				margin-top: 40px;
			}
		}

		&__content {
			word-wrap: break-word;
			padding-bottom: 16px;

			@include breakpoint('medium') {
				padding-bottom: 24px;
			}

			ul {
				list-style: disc;
			}
		}

		&__img-block {
			margin-top: 40px;
			max-height: 280px;
		}

		&__img {
			width: 100%;
			height: 100%;
		}

		&__img-description {
			color: #6b6b6b;
			margin-top: 20px;
			padding-left: 20px;
		}

		h3 {
			font-size: 20px;
			line-height: 22px;
		}

		a {
			color: $color-blue-light;

			span {
				color: $color-blue-light !important;
			}

			&.b-main_button__link {
				color: $color-white;
			}

			&:hover {
				color: $color-dark-grey;
			}
		}

		img.author {
			width: 150px;
			height: 150px;
		}

		.cashoff {
			display: flex;
			align-items: center;

			p {
				padding-left: 24px;
				padding-bottom: 0;
			}
		}
	}

	@include breakpoint(large) {
		font-size: 18px;
		line-height: 20px;
		max-width: calc(73% - 32px);
		padding-left: 32px;
	}

	@include breakpoint(xlarge) {
		.b-blog-article-detail__left {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
	}
}
