@import "~style";

$block-name: 'b-main-baners';

.#{$block-name} {
	position: relative;
	// margin-top: -139px;

	.swiper-wrapper {
		height: 460px;

		@include breakpoint('medium') {
			height: 400px;
		}

		@include breakpoint('large') {
			height: 680px;
		}

		@include breakpoint('xlarge') {
			height: 820px;
		}
	}

	.swiper-pagination-bullets {
		position: absolute;
		width: 100%;
		left: 10px;
		bottom: 40px;

		@include breakpoint('medium') {
			width: auto;
			left: 10px;
		}

		@include breakpoint('large') {
			bottom: 80px;
		}
	}

	.swiper-pagination-bullets .swiper-pagination-bullet {
		position: relative;
		background-color: white;
		opacity: 0.4;
		margin: 0 24px 0 0;
		width: 10px;
		height: 10px;

		&::before {
			content: '';
			background-image: url('/images/new.k2.img/Group 3986.png');
			width: 30px;
			height: 30px;
			display: none;
			margin-right: 5px;
			position: absolute;
			background-repeat: no-repeat;
			background-size: contain;
			top: -10px;
			left: -10px;
			z-index: -10;
		}

		&-active {
			opacity: 1;

			&::before {
				display: block;
			}
		}
	}

	&__paginat {
		position: relative;
	}

	&__content-wrapper {
		position: absolute;
		top: 0;
		left: 20px;

		@include breakpoint(750) {
			top: 35%;
			left: 50%;
		}

		// @include breakpoint('medium') {
		// 	top: 35%;
		// 	left: 50%;
		// }
		// transform: translate(-50%, -50%);
	}

	.simpleParallax {
		height: 100%;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	&__item {
		// background-color: black;
		position: relative;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	&__desc {
		color: white;
		margin-top: 16px;
		max-width: 390px;
		font-size: 14px;

		@include breakpoint('medium') {
			margin-top: 32px;
			font-size: 16px;
		}
	}

	h2 {
		color: white;
		padding-bottom: 0;
		margin: 154px 0 0;
		max-width: 610px;
		font-size: 24px;
		line-height: 33.6px;

		@include breakpoint(750) {
			margin: 104px 0 0;
			font-size: 48px;
			line-height: 62.4px;
		}

		@include breakpoint('large') {
			margin: 280px 0 0;
		}

		@include breakpoint('xlarge') {
			margin: 375px 0 0;
		}
	}

	&__button {
		margin-top: 32px;

		@include breakpoint('medium') {
			margin-top: 40px;
		}

		a {
			font-size: 14px;

			@include breakpoint('medium') {
				font-size: 16px;
			}
		}
	}

	&__pagination {
		position: absolute;
	}

	& .c-botton-transparent-dark {
		padding: 16.5px 57px;
	}
}
