@import "~style";

$block-name: 'b-counters';

.#{$block-name} {
	@include breakpoint(small) {
		$space_property: 10px;

		display: flex;
		flex-wrap: wrap;

		&__property {
			display: flex;
			color: $color-gray-content;
			font-size: 12px;
			// text-transform: uppercase;
			letter-spacing: 1.05px;
			font-weight: 800;
			padding-top: 14px;
			padding-bottom: 5px;

			&::before {
				content: '';
				display: inline-block;
				margin-right: 5px;
				background-repeat: no-repeat;
				background-position: center;
			}

			&--date {
				margin-right: $space_property;

				&::before {
					width: 18px;
					height: 13px;
					background-image: url("/upload/blog/teaser/icon-calendar.png");
				}
			}

			&--reading-time {
				&::before {
					width: 18px;
					height: 13px;
					background-image: url("/images/main_redisign/content/blog/time.png");
				}
			}

			&--views-counter {
				margin: 0 $space_property;

				&::before {
					width: 18px;
					height: 11px;
					background-image: url("/images/main_redisign/content/blog/view.png");
				}

				&.wide {
					margin-right: 2 * $space_property;
				}
			}

			&--likes-counter {
				&::before {
					width: 18px;
					height: 12px;
					background-image: url("/images/main_redisign/content/blog/like.png");
				}
			}

			&--regbutton {
				&::before {
					width: 17px;
					height: 17px;
					background-image: url("/images/main_redisign/content/blog/regbutton.png");
				}

				&.no_icon {
					&::before {
						display: none;
					}
				}

				&.future {
					&::before {
						background-image: url("/images/main_redisign/content/blog/regbutton_future.png");
					}
				}
			}

			&--white {
				&.#{$block-name} {
					&__property {
						color: $color-white;

						&--reading-time {
							&::before {
								background-image: url("/images/main_redisign/content/blog/time-white.png");
							}
						}

						&--views-counter {
							&::before {
								background-image: url("/images/main_redisign/content/blog/view-white.png");
							}
						}

						&--likes-counter {
							&::before {
								background-image: url("/images/main_redisign/content/blog/like-white.png");
							}
						}
					}
				}
			}

			&--article-header {
				&.#{$block-name} {
					&__property {
						color: $color-gray-content;

						&--reading-time {
							&::before {
								background-image: url("/images/main_redisign/content/blog/time.png");
							}
						}

						&--views-counter {
							&::before {
								background-image: url("/images/main_redisign/content/blog/view.png");
							}
						}

						&--likes-counter {
							&::before {
								background-image: url("/images/main_redisign/content/blog/like.png");
							}
						}
					}
				}
			}
		}

		&__article-like-clickable {
			transition: transform 1s;

			&:hover {
				cursor: pointer;
			}
		}
	}

	@include breakpoint(medium) {
		$space_property: 20px;

		&__property {
			font-size: 14px;

			&:not(:last-of-type) {
				margin-right: $space_property;
				margin-left: 0;
			}

			&::before {
				margin-right: 10px;
			}

			&--date {
				margin-right: $space_property;
			}

			&--views-counter {
				//margin: 0 $space_property;
			}
		}
	}

	@include breakpoint(xlarge) {
		&__property {
			&--white {
				&.#{$block-name} {
					&__property {
						color: $color-white;

						&--reading-time {
							&::before {
								background-image: url("/images/main_redisign/content/blog/time-white.png");
							}
						}

						&--views-counter {
							&::before {
								background-image: url("/images/main_redisign/content/blog/view-white.png");
							}
						}

						&--likes-counter {
							&::before {
								background-image: url("/images/main_redisign/content/blog/like-white.png");
							}
						}
					}
				}
			}
		}
	}
}
