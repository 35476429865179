@import "~style";

$block-name: 'b-console-enter';

.#{$block-name} {
	padding: 33px;

	@include breakpoint(medium) {
		padding: 50px;
	}

	&__title {
		font-size: 24px;
		width: 60%;
		line-height: 130%;
		padding-bottom: 50px;
		color: $color-dark-grey;
		text-transform: uppercase;

		@include breakpoint(medium) {
			font-size: 36px;
			width: 100%;
			padding-right: 100px;
		}
	}

	&__text {
		font-size: 22px;
		color: $color-dark-grey;
		margin-bottom: 50px;
	}

	&__button {
		display: inline-block;
		padding: 17.5px 24px;
		border-radius: 3px;
		font-size: 14px;
		font-weight: 700;
		line-height: 1em;
		color: $color-white;
		cursor: pointer;
		outline: none;
		text-decoration: none;

		@include breakpoint(medium) {
			font-size: 16px;
		}

		&--enter {
			margin-right: 12px;

			@include breakpoint(medium) {
				margin-right: 24px;
			}

			@include breakpoint(large) {
				&:hover {
					background-color: $color-blue-light;
					color: $color-white;
				}
			}

			margin-bottom: 20px;
			background-color: transparent;
			color: $color-dark-grey;
			border: 1px solid $color-blue;
		}

		&--registration {
			background-color: $color-blue-light;

			@include breakpoint(large) {
				&:hover {
					border: 1px solid $color-blue;
					background-color: transparent;
					color: $color-dark-grey;
				}
			}
		}
	}
}
