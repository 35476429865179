@import "~style";

$block-name: 'b-pagination';

.#{$block-name} {
	margin: 0 0 0;
	color: $color-dark-grey;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__items {
		list-style: none;
		justify-content: start;
		display: flex;
		align-items: center;
	}

	&__button {
		margin: 0 auto;
		display: flex;

		&.hidden {
			display: none;
		}

		& .c-button-blue-light {
			cursor: pointer;
		}
	}

	ul,
	li {
		margin-top: 0;
		margin-bottom: 0;
	}

	&__ellipsis {
		margin-right: 5px;
	}

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 5px;
		font-size: 16px;
		color: $color-dark-grey;
		width: 55px;
		text-decoration: none;
		height: 55px;
		border-radius: 50%;
	}

	.#{$block-name}__item.active {
		color: $color-white;
		background: $color-blue;
	}

	.b-customers__button {
		.hidden {
			display: none;
		}
	}

	.pagination {
		display: flex;
		margin-top: 20px;
		margin-bottom: 30px;

		& li {
			list-style: none;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 5px;
			font-size: 16px;
			width: 55px;
			text-decoration: none;
			height: 55px;
			border-radius: 50%;

			&.active {
				background: $color-blue;

				& a {
					text-decoration: none;
					color: $color-white;
				}
			}

			& a {
				text-decoration: none;
				color: $color-dark-grey;
			}
		}
	}
}
