@import "~style";

$block-name: 'b-usage-scenarios';

.#{$block-name} {
	position: relative;
	background-color: $color-light-blue;

	&.two-column {
		& .#{$block-name}__img-wrapper {
			position: absolute;
			opacity: 0;
		}

		& .#{$block-name}__container {
			display: grid;
			grid-template-columns: 1fr;
			width: 100%;
			gap: 16px;
			margin-top: 0;

			@include breakpoint('large') {
				grid-template-columns: 1fr 1fr;
				gap: 32px;
			}
		}

		& .#{$block-name}__item {
			width: 100%;
			margin-top: 0;
		}
	}

	&.not_hover {
		& .#{$block-name}__item {
			&:hover {
				transition: 0.8s cubic-bezier(0.65, 0, 0.35, 1);
				background-color: $color-white;

				.#{$block-name}__title {
					color: $color-dark-grey;
					opacity: 0.9;
				}

				.#{$block-name}__desc {
					color: $color-dark-grey;
					opacity: 0.9;

					& a {
						color: $color-blue;
					}

					& a.b-main_button__link {
						background-color: $color-blue;
						color: $color-white !important;
						border: 1px solid $color-white !important;

						&:hover {
							border: 1px solid $color-blue !important;
							background-color: $color-white;
							color: $color-dark-grey !important;
						}
					}
				}
			}
		}
	}

	&__wraper {
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		& h2 {
			margin-right: 20px;
			flex: 1 1;
		}

		@include breakpoint('large') {
			flex-direction: row;
		}
	}

	&__container {
		margin-top: 24px;

		@include breakpoint('medium') {
			display: flex;
			flex-wrap: wrap;
			margin-top: 12px;
			margin-left: -32px;
		}

		@include breakpoint('large') {
			width: 47.5%;
			margin-top: -16px;
			margin-left: 0;
		}
	}

	&__item {
		overflow: hidden;
		width: 100%;
		position: relative;
		background-repeat: no-repeat;
		background-position: top right;
		background-size: cover;
		background-color: $color-white;
		transition: 0.8s cubic-bezier(0.65, 0, 0.35, 1);
		margin-top: 16px;
		padding: 32px 32px 32px 24px;
		cursor: pointer;

		&.active {
			.#{$block-name}__desc {
				display: block;
			}

			.#{$block-name}__title::after {
				transform: rotate(180deg);
			}
		}

		@include breakpoint('medium') {
			cursor: auto;
			width: calc(100% / 2 - 32px);
			margin-left: 32px;
			padding: 32px 24px;
			margin-top: 32px;
		}

		@include breakpoint('large') {
			padding: 40px 88px 48px 48px;
			width: 100%;
			display: block;
			margin-top: 16px;
			margin-left: 0;
		}

		& .c-body-text {
			margin-top: 8px;
		}

		@include breakpoint('large') {
			&:hover {
				transition: 0.8s cubic-bezier(0.65, 0, 0.35, 1);
				background-color: #08b3f0;

				.#{$block-name}__title {
					color: $color-white;
				}

				.#{$block-name}__desc {
					color: $color-white;
					opacity: 1;

					& a {
						color: $color-white;
					}

					& a.b-main_button__link {
						background-color: $color-white;
						color: $color-dark-grey !important;

						&:hover {
							padding: 17.5px 24px !important;
							border: transparent;
							background-color: $color-white;
							color: $color-dark-grey !important;
						}
					}
				}

				.#{$block-name}__image {
					display: block;
				}

				.#{$block-name}__img-defolt {
					display: block;
				}
			}

			& .b-main_button__link {
				&:hover {
					padding: 17.5px 24px !important;
				}
			}
		}
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		top: 0;
		z-index: 1;
		right: 0;
	}

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		position: relative;
		z-index: 2;
		font-size: 24px;

		&.h3 {
			padding: 0;
		}

		&::after {
			content: '';
			display: block;
			background-image: url('/images/new.k2.img/arrow_down.svg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			flex: 0 0 auto;
			width: 24px;
			height: 24px;
			margin-left: 20px;

			@include breakpoint('medium') {
				display: none;
			}
		}
	}

	&__main-desc {
		margin-bottom: 20px;
		width: 100%;
		color: #808087;

		@include breakpoint('large') {
			width: 66%;
			margin-bottom: 30px;
		}
	}

	&__img-wrapper {
		height: 100%;
		top: 0;
		position: relative;

		@include breakpoint('large') {
			position: sticky;
			top: 200px;
		}
	}

	&__img {
		display: none;
		margin-left: -450px;
		margin-top: 50px;

		@include breakpoint('large') {
			display: block;
		}

		.pattern-svg__orange-circle {
			display: none;
		}
	}

	&__orange-circle {
		height: 100px;
		width: 100px;
		top: -50px;
		left: -50px;
		position: absolute;

		&::before {
			content: '';
			display: block;
			background-image: url('/images/icons/orange.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			width: 100%;
			border-radius: 50%;
			height: 100%;
			// background: #f95208;
		}
	}

	&__desc {
		position: relative;
		z-index: 2;
		display: none;
		margin-bottom: 16px;

		@include breakpoint('medium') {
			display: block;
			margin-bottom: 32px;
		}
	}
}
