@import "~style";

$block-name: 'b-preloader';

.#{$block-name} {
	text-align: center;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin: 40px 0;

	&__image {
		display: inline-block;
		animation: 1.5s ease-in-out infinite rot;
	}

	@keyframes rot {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}
