@import "~style";

$block-name: 'b-certificat';

.#{$block-name} {
	width: 80%;
	margin-left: 0;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-decoration: none;
	opacity: 1;

	@include breakpoint('medium') {
		width: 100%;

		&.opacity {
			opacity: 1;
		}
	}

	& img {
		max-width: 200px;
	}

	&__title {
		margin-top: 32px;
		font-size: 16px;
		line-height: 130%;
		color: $color-dark-grey;
		max-width: 230px;
	}
}
