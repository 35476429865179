@import "~style";

$block-name: 'b-three_elements_drop_down';

.#{$block-name} {
	background-color: $color-light-blue;

	&.white {
		background-color: $color-white;
	}

	&__desc {
		color: $color-dark-grey;
		margin-bottom: 30px;
		margin-right: 0;
		opacity: 0.9;

		@include breakpoint('large') {
			margin-right: calc((100% - 960px) / 2);
		}
	}

	&__conatiner-logo {
		position: relative;
		display: block;
	}

	[data-tooltip]::after {
		content: attr(data-tooltip);
		position: absolute;
		opacity: 0;
		transition: 0.3s;
		pointer-events: none;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
		background-color: $color-blue;
		color: $color-white;
		padding: 5px;
		border-radius: 5px;
		top: calc(100% + 10px);
		max-width: 200px;
		width: 100%;
		display: block;
		z-index: 100000000;
	}

	[data-tooltip]:hover::after {
		opacity: 1;
		top: calc(100% + 15px);
	}

	&__desc-image {
		display: block;
		margin-top: 10px;

		@include breakpoint('medium') {
			margin-top: 20px;
		}
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-left: -20px;
		margin-top: 70px;
	}

	&__item {
		display: flex;
		flex-direction: column;
		position: relative;
		margin-left: 20px;
		margin-top: 20px;
		height: auto;
		width: calc(100% - 20px);
		background-color: $color-white;
		padding: 30px 30px 45px 30px;

		@include breakpoint(750) {
			width: calc(100% / 2 - 20px);
		}

		@include breakpoint(1200) {
			width: calc(100% / 3 - 20px);
		}

		&.white {
			background-color: $color-light-blue;
		}

		&.active {
			& .#{$block-name}__arrow {
				transform: rotate(180deg);
			}
		}
	}

	&__item-title {
		font-size: 24px;
		line-height: 30px;
		margin-left: 5px;

		@include breakpoint('medium') {
			font-size: 24px;
			line-height: 35px;
		}

		// @include breakpoint(1400) {
		// 	font-size: 32px;
		// 	line-height: 50px;
		// 	margin-left: 25px;
		// }
	}

	&__item-desc {
		font-size: 16px;
		font-weight: 350;
		line-height: 24px;
		max-width: 430px;
		color: $color-dark-grey;
		margin-bottom: 30px;

		// @include breakpoint('medium') {
		// 	font-size: 18px;
		// 	font-weight: 350;
		// 	line-height: 22px;
		// }
	}

	&__hr {
		background-color: $color-blue;
		height: 2px;
		width: 100%;
		margin: 0 0 30px;
		margin-top: auto;
	}

	&__arrow {
		display: block;
		position: absolute;
		bottom: 25px;
		right: 40px;
		mask-image: url("/images/new.k2.img/arrow_down.svg");
		mask-size: cover;
		mask-position: center;
		mask-repeat: no-repeat;
		-webkit-mask-image: url("/images/new.k2.img/arrow_down.svg");
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-size: cover;
		-webkit-mask-position: center;
		background-color: $color-blue;
		width: 35px;
		height: 20px;
		cursor: pointer;
		transition: transform 0.2s cubic-bezier(0.65, 0, 0.35, 1);
	}

	&__item-header {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		margin-left: -20px;

		img {
			max-width: 80px;

			@include breakpoint(1400) {
				max-width: 100px;
			}
		}
	}

	&__item-hidden-desc {
		display: none;

		ul > li {
			list-style: none;
			display: flex;
			font-size: 16px;
			font-weight: 350;
			line-height: 19px;

			// @include breakpoint('medium') {
			// 	font-size: 18px;
			// 	line-height: 22px;
			// }

			&::before {
				flex: 0 0 auto;
				display: block;
				margin-right: 30px;
				content: '';
				background-image: url("/images/new.k2.img/icon-list.svg");
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				width: 24px;
				height: 24px;
			}
		}
	}
}
