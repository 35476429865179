@import "~style";

$block-name: 'b-footer';

.#{$block-name} {
	background-color: $color-dark-grey;
	padding: 56px 0 24px;
	color: #a6a6aa;

	&__footer-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 45px;

		& .logo {
			width: 160px;
		}
	}

	&__container-form-email {
		max-width: 600px;
	}

	&__button-wraper {
		display: none;
		justify-content: flex-end;
		max-width: 310px;
		width: 100%;

		@include breakpoint('medium') {
			display: flex;
		}

		@include breakpoint('large') {
			justify-content: flex-start;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		// padding: 17.5px 0;
		max-width: 162px;
		width: 100%;
		background-color: $color-blue;
		color: $color-white;
		border-radius: 3px;

		@include breakpoint('large') {
			max-width: 198px;
		}

		@include breakpoint('xlarge') {
			max-width: 162px;
		}
	}

	&__footer-content {
		margin-top: 45px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include breakpoint('large') {
			flex-direction: row;
		}
	}

	&__left-content {
		margin-top: -54px;
		flex-wrap: wrap;
		display: none;
		justify-content: space-between;

		@include breakpoint('medium') {
			display: flex;
		}
	}

	&__item-wraper {
		padding-right: 10px;
		display: flex;
		width: calc(100% / 2);

		&:last-child {
			margin-right: auto;
		}

		&:nth-child(7),
		&:nth-child(8) {
			display: none;
		}

		@include breakpoint('medium') {
			width: calc(100% / 3);

			&:nth-child(7),
			&:nth-child(8) {
				display: block;
			}
		}
	}

	&__item {
		margin-top: 40px;
		max-width: 156px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		@include breakpoint('medium') {
			margin-top: 48px;
			max-width: 230px;
		}

		@include breakpoint('large') {
			margin-top: 54px;
		}

		&.active {
			.#{$block-name}__list {
				display: flex;
			}
		}
	}

	&__item-title {
		@include breakpoint('medium') {
			&:hover {
				color: $color-blue;
			}
		}
	}

	&__title-wrapper {
		position: relative;
	}

	&__title {
		color: $color-white;
		display: flex;
		flex-direction: row;

		@include breakpoint('medium') {
			flex-direction: column;
		}

		& img {
			margin-left: 18px;
			margin-right: 10px;
			width: 16px;
			height: 16px;
			display: block;

			@include breakpoint('medium') {
				display: none;
			}
		}

		& a {
			margin-right: 0;
			font-size: 12px;
			margin-bottom: 0;
			color: $color-white;
			text-decoration: none;

			&:nth-child(2),
			&:nth-child(3) {
				display: none;
			}

			@include breakpoint('medium') {
				margin-right: 10px;
				font-size: 16px;
				margin-bottom: 16px;

				&:nth-child(2),
				&:nth-child(3) {
					display: block;
				}
			}
		}
	}

	&__list {
		font-size: 12px;
		display: none;
		flex-direction: column;

		a {
			text-decoration: none;
			color: #a6a6aa;
			margin-bottom: 10px;

			&:first-child {
				// margin-top: 0;
			}
		}

		@include breakpoint('medium') {
			display: flex;
		}
	}

	&__item-sub-list {
		// margin-top: 10px;

		// &:first-child {
		// 	margin-top: 0;
		// }

		&:hover {
			color: $color-white;
		}
	}

	&__list-item {
		&.active {
			.#{$block-name}__sub-list {
				transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
				height: 100%;
				opacity: 1;
				visibility: visible;
			}

			.#{$block-name}__image-list {
				transform: rotate(180deg);
			}
		}
	}

	&__sub-elem {
		margin-bottom: 10px;
		margin-left: 10px;
		display: block;

		&:hover {
			color: $color-white;
		}
	}

	&__list-sub-item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__image-list {
		cursor: pointer;
		flex: 0 0 auto;
		// transform: none;
	}

	&__sub-list {
		transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		height: 0;
		opacity: 0;
		visibility: hidden;
	}

	&__right-content {
		margin-top: 64px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: flex-start;
		max-width: 1000px;
		width: 100%;

		@include breakpoint('medium') {
			margin-top: 80px;
		}

		@include breakpoint('large') {
			margin-top: 0;
			flex-direction: column;
			max-width: 310px;
		}
	}

	&__feedback {
		color: $color-white;
		display: flex;
		flex-direction: column;
		width: calc(100% / 2);

		@include breakpoint('medium') {
			width: calc(100% / 3);
		}

		@include breakpoint('large') {
			width: 100%;
		}

		& a {
			font-size: 14px;
			margin-right: 10px;
			margin-bottom: 16px;
			color: $color-white;
			text-decoration: none;

			@include breakpoint('medium') {
				font-size: 16px;

				&:hover {
					color: $color-blue;
				}
			}
		}

		& span {
			font-size: 10px;
			color: #949499;

			@include breakpoint('medium') {
				font-size: 12px;
			}
		}
	}

	&__icons-wrapper {
		width: calc(100% / 2);

		@include breakpoint('medium') {
			width: calc(100% / 3);
		}

		@include breakpoint('large') {
			width: 100%;
		}
	}

	&__socicons {
		max-width: 180px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 16px;
		row-gap: 16px;

		// &_item {
		// 	background: url(/images/new.k2.img/soc-icons.png) 0 0 no-repeat;
		// 	width: 40px;
		// 	height: 40px;
		// 	display: inline-block;
		// 	margin-right: 15px;
		// 	margin-bottom: 12px;

		// 	&--telegram {
		// 		background-position: 0 0;
		// 	}

		// 	&--facebook {
		// 		background-position: -56px 0;
		// 	}

		// 	&--youtube {
		// 		background-position: -112px 0;
		// 	}

		// 	&--twitter {
		// 		background-position: 0 -56px;
		// 	}

		// 	&--instagram {
		// 		background-position: -56px -56px;
		// 	}

		// 	&--habrahabr {
		// 		background-position: -112px -56px;
		// 	}
		// }
	}

	&__footer-icon {
		&:hover {
			path {
				fill: white;
				opacity: 1;
			}

			path.fill {
				fill: $color-blue;
			}

			path.fil1 {
				fill: $color-blue !important;
			}

			path.fil0 {
				stroke: $color-blue !important;
			}

			background-color: white;
			border-radius: 50%;
		}
	}

	&__text {
		margin-top: 0;
		color: #a6a6aa;
		font-size: 12px;
		width: 100%;

		@include breakpoint('medium') {
			width: calc(100% / 3);
		}

		@include breakpoint('large') {
			width: 100%;
			margin-top: 32px;
		}
	}

	&__footer-bottom__container {
		@include xy-grid;
		@include xy-gutters($negative: true);

		display: block;

		@include breakpoint(medium) {
			display: flex;
		}

		&__policy {
			& a {
				text-decoration: none;

				@include breakpoint('medium') {
					&:hover {
						color: $color-white;
					}
				}
			}

			@include xy-cell(12);

			@include breakpoint(medium) {
				@include xy-cell(6);
			}

			@include breakpoint(large) {
				@include xy-cell(4);
			}
		}

		&__copyright {
			@include xy-cell(12);

			@include breakpoint(medium) {
				@include xy-cell(6);
			}

			@include breakpoint(large) {
				@include xy-cell(4);
			}
		}

		&__copyright-techart {
			& a {
				text-decoration: none;

				@include breakpoint('medium') {
					&:hover {
						color: $color-white;
					}
				}
			}

			@include xy-cell(12);

			@include breakpoint(medium) {
				@include xy-cell(6);
			}

			@include breakpoint(large) {
				@include xy-cell(4);
			}
		}
	}

	&__footer-bottom {
		margin-top: 40px;
		padding-top: 20px;
		border-top: 2px solid $color-blue;
		color: #a6a6aa;

		& a {
			color: #a6a6aa;
		}
	}

	//мобильный футер
	&__mobile-footer {
		display: block;
		margin-top: -40px;

		@include breakpoint('medium') {
			display: none;
		}
	}

	&__right-mobile-menu {
		display: block;
		width: 50%;
		float: right;
	}

	&__left-mobile-menu {
		width: 50%;
		display: block;
		float: left;
	}

	&__mobile-item {
		&.active {
			.#{$block-name}__mobile-list {
				display: block;
			}

			.#{$block-name}__title-img {
				transform: rotate(180deg);
			}
		}
	}

	&__mobile-title {
		max-width: 170px;
		position: relative;
		font-size: 12px;
		font-weight: 700;
		margin-top: 40px;
		cursor: pointer;
		color: $color-white;

		& a {
			font-weight: 700;
			color: $color-white;
			font-size: 12px;
			text-decoration: none;
		}
	}

	&__title-img {
		position: absolute;
		top: 0;
		right: 25px;
	}

	&__title-text {
		padding-right: 20px;
		margin-right: 23px;
	}

	&__mobile-list {
		display: none;

		& a {
			display: block;
			font-size: 12px;
			margin-top: 12px;
			margin-right: 10px;
			color: #a6a6aa;
			text-decoration: none;
		}
	}
}
