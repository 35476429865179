@import "~style";

$block-name: 'b-events';

.#{$block-name} {
	background-color: $color-white;

	& .swiper {
		width: 100%;
		margin-right: 0;
		margin-left: 0;
	}

	& .big_h2 {
		a {
			color: $color-dark-grey;
			text-decoration: none;
		}
	}

	&__container {
		margin-top: 40px;
		margin-left: 0;
		display: flex;

		@include breakpoint('medium') {
			margin-top: 48px;
			// margin-left: -32px;
		}

		@include breakpoint('large') {
			margin-top: 64px;
		}
	}

	&__container-navigation {
		position: relative;
		z-index: 20;
		display: flex;
		justify-content: space-between;
	}

	&__swiper-btn {
		display: flex;
	}

	&__swiper-next {
		display: none;
		cursor: pointer;

		@include breakpoint('medium') {
			display: block;
		}
	}

	&__swiper-prev {
		cursor: pointer;
		display: none;
		margin-right: 40px;

		@include breakpoint('medium') {
			display: block;
		}
	}

	&__pagination {
		margin-top: 40px;
		position: relative;

		@include breakpoint('large') {
			margin-top: 44px;
		}

		& .swiper-pagination-bullet {
			&-active {
				transition: width 0.6s;
				width: 48px;
				border-radius: 4px;
			}
		}
	}
}
