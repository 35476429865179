@import "~style";

$block-name: 'b-banner-text';

.#{$block-name} {
	padding: 15px;
	color: $color-white;
	margin: 10px 0;
	position: relative;
	overflow: hidden;
	background-color: $color-blue;
	box-sizing: border-box;

	&::before {
		position: absolute;
		bottom: 0;
		right: 0;
		content: "";
		width: 180px;
		height: 100%;
		background-image: url("/images/baner-image.svg");
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: contain;

		@include breakpoint('medium') {
			width: 380px;
		}
	}

	@include breakpoint(large) {
		width: 100%;
		margin-left: auto;
		padding: 30px;
	}

	.h2,
	.h3 {
		color: $color-white;
	}

	& .c-button-gray {
		margin-top: 10px;
		color: $color-black;
		z-index: 123;
		position: relative;

		&:hover {
			color: $color-white;
			background-color: transparent;
		}
	}

	&__content {
		line-height: 130%;
		display: block;

		@include breakpoint('large') {
			display: flex;
			justify-content: space-between;
		}

		.col1 {
			width: 100%;
		}

		.col2 {
			width: 100%;
			white-space: nowrap;
			text-align: left;
			padding-top: 38px;

			@include breakpoint('large') {
				text-align: right;
				padding-left: 40px;
				padding-top: 0;
				width: auto;
			}
		}
	}
}
