@import "~style";

$block-name: 'b-block-video';

.#{$block-name} {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	overflow: hidden;
	z-index: 20;
	// padding-top: 24px;
	// padding-bottom: 20px;
	// cursor: pointer;
	height: 240px;

	@include breakpoint('medium') {
		height: 400px;
	}

	@include breakpoint('large') {
		height: 500px;
	}

	@include breakpoint('xlarge') {
		height: 680px;
	}

	&__recording {
		display: flex;
		align-items: center;

		&.big_h3 {
			color: white;
			font-size: 10px;

			@include breakpoint('medium') {
				font-size: 16px;
			}

			@include breakpoint('large') {
				font-size: 24px;
			}
		}

		&::before {
			margin-right: 16px;
			display: block;
			content: '';
			background-image: url('/images/new.k2.img/24_icon_recording.png');
			width: 24px;
			height: 24px;
		}
	}

	&__button-play {
		// margin-top: 26px;
		display: flex;
		justify-content: center;
		margin-bottom: 20px;

		& img {
			cursor: pointer;
			width: 56px;

			@include breakpoint('medium') {
				width: 104px;
			}

			@include breakpoint('large') {
				width: 136px;
			}
		}

		@include breakpoint('medium') {
			margin-bottom: 0;
		}

		// @include breakpoint('large') {
		// 	margin-top: 162px;
		// }

		// @include breakpoint('xlarge') {
		// 	margin-top: 276px;
		// }
	}

	&__title {
		&.big_h2 {
			max-width: 269px;
			// margin-top: 6px;
			color: $color-white;
			font-size: 16px;
			line-height: 24px;

			@include breakpoint('medium') {
				max-width: 400px;
				// margin-top: 40px;
				font-size: 24px;
				line-height: 33.6px;
			}

			@include breakpoint('large') {
				max-width: 710px;
				// margin-top: 84px;
				font-size: 48px;
				line-height: 62.4px;
			}

			@include breakpoint('xlarge') {
				// margin-top: 165px;
			}
		}
	}

	&__video {
		display: none;
		// padding: 20px;
		// padding: 0 0 56.25%;
		height: 0;
		overflow: hidden;
		border-radius: 5px;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__images {
		z-index: -1;
		// width: 100%;
		height: 100%;
		object-fit: cover;
		// position: absolute;
		// top: 0;
		// left: 0;

		@include breakpoint('xlarge') {
			// height: 900px;
		}
	}

	& .c-content {
		// position: relative;
	}

	&__wrapper {
		padding: 30px 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		z-index: 20;
		position: absolute;
		top: 0;
		left: 0;
		margin: auto;
		bottom: 0;
		right: 0;
		width: calc(100% - 40px);
		cursor: pointer;

		@include breakpoint('large') {
			padding: 80px 0;
			width: calc(86%);
			max-width: 1600px;
		}
	}
}
