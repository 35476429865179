@import "~style";

$block-name: 'b-experts';

.#{$block-name} {
	&__bg {
		background-color: #f6f8fb;
	}

	&__swiper {
		overflow: hidden;
		margin-top: 64px;
	}

	& .big_h2 {
		display: inline-block;
		color: $color-dark-grey;
		margin-bottom: 0;
		width: 300px;

		@include breakpoint('large') {
			width: auto;
		}

		a {
			color: $color-dark-grey;
			text-decoration: none;
		}
	}

	& .swiper-wrapper {
		height: auto;
	}

	&__pagination {
		margin-top: 44px;
		position: relative;

		& .swiper-pagination-bullet {
			&-active {
				transition: width 0.6s;
				width: 48px;
				border-radius: 4px;
			}
		}
	}

	&__container-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__swiper-btn {
		display: flex;
	}

	&__swiper-next {
		display: none;
		cursor: pointer;

		@include breakpoint('medium') {
			display: block;
		}
	}

	&__swiper-prev {
		cursor: pointer;
		display: none;
		margin-right: 40px;

		@include breakpoint('medium') {
			display: block;
		}
	}

	&__link-all {
		display: inline-block;
		font-weight: normal;
		font-size: 16px;
		line-height: 130%;
		color: $color-dark-grey;
		text-decoration: none;
		margin-top: 44px;
		border-bottom: 3px solid #08b3f0;

		@include breakpoint('medium') {
			&:hover {
				border-bottom: 3px solid transparent;
			}
		}
	}

	& .swiper-slide {
		width: 80%;
	}

	&__button {
		display: flex;
		justify-content: center;

		@include breakpoint('medium') {
			justify-content: flex-start;
		}
	}

	&__title {
		font-weight: bold;
		font-size: 24px;
		line-height: 140%;
		color: $color-blue-light;
		padding-bottom: 44px;

		@include breakpoint(medium) {
			padding-bottom: 63px;
		}

		@include breakpoint(large) {
			padding-bottom: 45px;
		}

		@include breakpoint(xlarge) {
			padding-bottom: 65px;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		// margin-bottom: 37px;
		text-decoration: none;
		opacity: 1;
	}

	&__item-image {
		margin-bottom: 29px;
		height: 214px;
		width: 214px;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;
		transition: 0.5s ease;

		@include breakpoint(medium) {
			height: 193px;
			width: 193px;
			margin-bottom: 39px;
		}

		@include breakpoint(large) {
			height: 258px;
			width: 258px;
			margin-bottom: 42px;
		}

		@include breakpoint(xlarge) {
			height: 240px;
			width: 240px;
			margin-bottom: 38px;
		}
	}

	&__item-name {
		font-size: 24px;
		line-height: 140%;
		letter-spacing: 0.06px;
		padding-bottom: 8px;
		max-width: 230px;
		width: 100%;
		transition: 0.5s;
		border-bottom: 1px solid $color-blue;
		color: $color-dark-grey;

		@include breakpoint(xlarge) {
			padding-bottom: 16px;
		}
	}

	&__item-description {
		font-size: 14px;
		line-height: 1.2em;
		color: $color-dark-grey;
		width: 230px;
		margin-top: 8px;

		@include breakpoint(medium) {
			font-size: 16px;
			margin-top: 16px;
		}
	}

	.tao-page-navigator {
		display: none;
	}

	.tao-list-managers,
	.tao-list-managers_en {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: 16px;
		row-gap: 16px;
		margin-bottom: 50px;

		@include breakpoint(medium) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include breakpoint(large) {
			grid-template-columns: repeat(3, 1fr);
			column-gap: 32px;
			row-gap: 32px;
			margin-bottom: 100px;
		}

		@include breakpoint(xlarge) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
