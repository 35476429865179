@import "~style";

$block-name: 'b-service_events';

.#{$block-name} {
	background-color: $color-light-blue;

	&.white {
		background-color: $color-white;
	}

	&__item {
		padding: 40px 0;
		border-bottom: 1px solid $color-light-grey;

		&:first-child {
			border-top: none;
			margin-top: 0;

			@include breakpoint(medium) {
				margin-top: 48px;
				border-top: 1px solid $color-light-grey;
			}

			@include breakpoint(1200) {
				margin-top: 64px;
			}
		}

		@include xy-grid;
		@include xy-gutters($negative: true, $gutters: none);

		display: block;

		@include breakpoint(medium) {
			display: flex;

			&:first-child {
				border-top: 1px solid $color-light-grey;
			}
		}

		@include breakpoint(1200) {
			padding: 12px 0;
		}

		// &::before {
		// 	@include xy-cell(12);

		// 	content: " ";
		// 	font-size: 0;
		// 	line-height: 0;
		// 	background-color: $color-light-grey;
		// 	height: 1px;
		// 	margin-bottom: 42px;

		// 	@include breakpoint(large) {
		// 		margin-bottom: 12px;
		// 	}
		// }
	}

	&__col1 {
		@include xy-cell(0);

		display: none;

		@include breakpoint(medium) {
			@include xy-cell(6);

			display: flex;
		}

		@include breakpoint(1200) {
			@include xy-cell(4);
		}

		// @include breakpoint(large) {
		// 	@include xy-cell(4);
		// }

		.#{$block-name}__date {
			display: none;

			@include breakpoint(1200) {
				display: block;
			}
		}
	}

	&__col2 {
		position: relative;

		@include xy-cell(12);

		display: block;

		@include breakpoint(medium) {
			@include xy-cell(6);
		}

		// @include breakpoint(large) {
		// 	@include xy-cell(4);
		// }

		@include breakpoint(1200) {
			@include xy-cell(4);
		}

		// @include breakpoint(1200) {
		// 	background-color: black;
		// }

		.#{$block-name}__date {
			display: block;

			@include breakpoint(1200) {
				display: none;
			}
		}

		.#{$block-name}__type {
			display: block;

			@include breakpoint(1200) {
				display: none;
			}
		}

		.#{$block-name}__button {
			display: inline-block;
			position: relative;

			@include breakpoint(medium) {
				position: absolute;
				bottom: 0;
				left: 0;
			}

			@include breakpoint(1200) {
				display: none;
			}
		}
	}

	&__col3 {
		@include xy-cell(0);

		display: none;

		@include breakpoint(medium) {
			@include xy-cell(0);

			display: none;
		}

		@include breakpoint(1200) {
			@include xy-cell(4);

			display: flex;
			justify-content: space-between;
		}

		// @include breakpoint(large) {
		// 	@include xy-cell(4);

		// 	display: flex;
		// 	justify-content: space-between;
		// }

		.#{$block-name}__type {
			display: none;

			@include breakpoint(1200) {
				display: block;
			}
		}

		.#{$block-name}__button {
			display: none;

			@include breakpoint(1200) {
				display: block;
			}
		}
	}

	&__image {
		display: block;
		background-size: contain;
		background-repeat: no-repeat;
		max-width: 100%;

		@include breakpoint('medium') {
			width: 348px;
			height: 232px;
			background-size: cover;
			background-position: center;
		}

		@include breakpoint(1200) {
			width: 212px;
			height: 132px;
			background-size: cover;
			background-position: center;
			display: inline-block;
		}
	}

	&__title {
		display: block;
		margin-top: 30px;
		max-width: 100%;
		color: $color-black;
		text-decoration: none;

		@include breakpoint(1200) {
			margin-top: 50px;
			max-width: 312px;
		}
	}

	&__type {
		color: $color-blue;
		margin-top: 0;

		@include breakpoint(1200) {
			margin-top: 50px;
		}
	}

	&__button {
		margin-top: 50px;
		min-width: 208px;
		text-align: center;
	}

	&__data-item {
		display: flex;
		justify-content: space-between;
	}

	&__date {
		color: $color-blue;
		font-size: 14px;
		line-height: 21px;
		display: flex;
		justify-content: space-between;
		padding-top: 0;

		@include breakpoint(1200) {
			padding-left: 48px;
			padding-top: 50px;
			display: inline-block;
		}

		&_day {
			float: left;
			font-weight: bold;
			font-size: 40px;
			line-height: 130%;
		}
	}

	&__date_month {
		display: flex;
		padding-left: 8px;
		padding-right: 8px;
		margin-right: 0;
	}

	& .c-active-title {
		& a {
			text-decoration: none;
			color: $color-dark-grey;
		}
	}

	& .c-button-blue-light {
		margin-right: 0;
		// display: inline-block;
	}
}
