@import "~style";

$block-name: 'b-request-form';

.#{$block-name} {
	padding: 40px 24px;

	@include breakpoint(medium) {
		padding: 88px 40px;
	}

	@include breakpoint(large) {
		padding: 88px 80px 150px 80px;
	}

	@include breakpoint(1200) {
		padding: 88px 180px 150px 100px;
	}

	.error_text {
		color: red;
		font-size: 14px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.success {
		color: $color-gray-content;
		margin: 0;

		@include breakpoint(large) {
			margin: 0 16px;
		}
	}

	&__subscribe-block {
		margin-top: 20px;
	}

	&__title {
		width: 60%;
		line-height: 1em;
		font-weight: 700;
		// padding-bottom: 24px;
		color: $color-gray-content;
	}

	&__text {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 24px;

		&.hidden {
			display: none;
		}
	}

	&__services-name {
		color: $color-gray-content;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		font-size: 14px;
		line-height: 1em;

		@include breakpoint(medium) {
			font-size: 16px;
		}

		&::after {
			content: '';
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-left: 10px;
			background-image: url(/images/new.k2.img/arrow_down.svg);
			background-repeat: no-repeat;
			background-position: center;

			@include breakpoint(medium) {
				width: 24px;
				height: 24px;
			}
		}
	}

	&__services-list,
	&__services-lists {
		position: absolute;
		width: 100%;
		max-width: 392px;
		display: none;
		border-radius: 5px;
		height: 216px;
		box-shadow: 0 8px 8px 1px rgba(34, 60, 80, 0.2);
		// box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);
		left: -2px;
		background-color: $color-white;
		z-index: 20;
		top: 72px;

		@include breakpoint(385) {
			top: 58px;
		}

		@include breakpoint(large) {
			font-size: 14px;
			width: calc(100% + 2px);
			max-width: calc(100% + 2px);
		}

		&.quetion {
			height: 120px;
		}
	}

	&__services-lists {
		& .#{$block-name}__checkbox-label {
			height: 24px;
		}
	}

	&__services-item {
		padding-top: 30px;

		&.pointer-none {
			.#{$block-name}__checkbox-label {
				pointer-events: none;
			}

			.#{$block-name}__checkbox {
				pointer-events: none;
			}
		}
	}

	&__input {
		&.empty {
			border: 2px solid $color-orange;
		}
	}

	&__text-submited {
		font-weight: 700;
		font-size: 16px;
	}

	&__popup {
		display: none;
		padding: 20px 26px 0;
		font-size: 12px;
		line-height: 1.2em;
		color: $color-gray-content;
	}

	&__submit {
		display: flex;
		width: 100%;
		justify-content: flex-start;
		margin: 30px 5% 0;

		@include breakpoint(medium) {
			margin-left: 55%;
		}
	}

	&__submit-button {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
		background-color: $color-blue;
		line-height: 1em;
		color: $color-white;
		padding: 17.5px 24px;
		cursor: pointer;
		outline: none;
		width: 180px;
		font-size: 16px;

		@include breakpoint(medium) {
			margin-top: 10px;
		}
	}

	&__field {
		position: relative;
		width: 100%;
		margin: 0 0 16px;

		@include breakpoint(large) {
			margin: 0 16px 16px;
			width: calc(100% / 2 - 32px);
		}

		&.active-items {
			display: flex;
			align-items: center;
			order: 1;
			margin-top: 16px;

			@include breakpoint(large) {
				order: 0;
				margin-top: 16px;
			}
		}

		&.button {
			order: 1;
			margin-top: 20px;

			@include breakpoint(large) {
				order: 0;
				margin-top: 0;
			}
		}

		&--checkbox {
			width: 100%;

			@include breakpoint(medium) {
				margin-left: 55%;
			}
		}

		&--selector {
			height: auto;
			border-radius: 5px;
			border: 2px solid rgba(8, 179, 240, 0.4);
			padding: 16px 24px;
		}

		&--selectors {
			height: auto;
			border-radius: 5px;
			border: 2px solid rgba(8, 179, 240, 0.4);
			padding: 16px 24px;

			&.hidden {
				display: none;
			}

			&.quetion {
				padding: 13px 24px;
			}
		}

		&--empty {
			border: 2px solid $color-orange;
		}

		&--textarea {
			width: 100%;
		}

		&.hidden {
			display: none;
		}
	}

	.b-request-form__input:focus + .b-request-form__label,
	.b-request-form__input--filled + .b-request-form__label,
	.b-request-form__textarea:focus + .b-request-form__label,
	.b-request-form__textarea--filled + .b-request-form__label {
		opacity: 0;
	}

	.b-request-form__checkbox:checked + .b-request-form__checkbox-label {
		&::before {
			background-image: url(/images/main_redisign/pages/checkbox_blue_active.svg);
		}
	}

	&__checkbox {
		opacity: 0;
	}

	& .not-event {
		pointer-events: none;
	}

	&__checkbox-label {
		position: absolute;
		width: 100%;
		left: 0;
		cursor: pointer;
		font-size: 14px;
		line-height: 1.2em;
		color: $color-gray-content;
		padding-left: 40px;
		height: 100%;

		@include breakpoint(medium) {
			font-size: 16px;
		}

		&.left {
			left: 24px;
		}

		&.empty {
			&::before {
				background-image: none;
				border: 1px solid $color-orange;
				border-radius: 4px;
			}
		}

		&::before {
			content: '';
			width: 24px;
			height: 24px;
			background-image: url(/images/icons/checkbox.svg);
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			left: 0;
			top: 0;
			transition: 0.5s;
		}
	}

	&__checkbox-popup {
		color: $color-blue-light;

		@include breakpoint(medium) {
			font-size: 14px;
		}
	}

	&__label {
		position: absolute;
		font-size: 14px;
		line-height: 1.2em;
		color: $color-gray-content;
		top: 20px;
		left: 24px;
		transition: 0.5s;
		z-index: -1;

		@include breakpoint(medium) {
			font-size: 16px;
		}

		&--textarea {
			bottom: 20px;
			display: flex;
			align-items: flex-end;

			@include breakpoint(large) {
				left: 85px;
			}

			@include breakpoint(xlarge) {
				left: 120px;
			}
		}
	}

	&__input {
		border: none;
		border: 2px solid rgba(8, 179, 240, 0.4);
		padding: 16px 24px;
		font-size: 14px;
		color: $color-gray-content;
		line-height: 1.2em;
		outline: none;
		width: 100%;
		border-radius: 5px;
		background-color: transparent;

		&--hidden {
			display: none;
		}

		&--textarea {
			height: 90px;
		}

		@include breakpoint(medium) {
			font-size: 16px;
		}
	}

	&__textarea {
		border: none;
		border: 2px solid rgba(8, 179, 240, 0.4);
		padding: 16px 24px;
		font-size: 14px;
		color: $color-gray-content;
		line-height: 1.2em;
		outline: none;
		width: 100%;
		border-radius: 5px;
		height: 80px;

		&.empty {
			border: 2px solid $color-orange;
		}

		&--hidden {
			display: none;
		}

		@include breakpoint(medium) {
			font-size: 16px;
		}
	}

	&__form {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin: 0;

		@include breakpoint(large) {
			margin: 0 -16px;
		}
	}
}
