@import "~style";

$block-name: 'b-header-bottom';

.#{$block-name} {
	height: 64px;
	align-items: flex-start;
	flex-direction: column;
	display: flex;
	justify-content: space-around;

	&.active {
		height: 120px;
	}

	&__logo {
		img {
			width: 160px;
		}
	}

	@include breakpoint('medium') {
		border: none;
		height: 104px;
		justify-content: center;
		align-items: center;

		&.active {
			height: 104px;
		}
	}

	&__sidebar-content-container {
		position: relative;
		width: 100%;

		& img {
			width: 100%;
		}
	}

	&__new {
		position: absolute;
		top: 10px;
		right: 10px;
		padding: 2px 15px;
		color: $color-white;
		background-color: $color-blue;
		border-radius: 15px;
	}

	@include breakpoint(1200) {
		height: 77px;
	}

	& .link-title {
		line-height: 78px;

		& a {
			display: inline-block;
		}
	}

	&__wraper {
		height: 64px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		// border-bottom: 1px solid $color-dark-grey;
		border: none;

		&.border {
			border-bottom: 1px solid $color-dark-grey;
		}

		& a {
			text-decoration: none;
		}

		@include breakpoint('medium') {
			border: none;
			height: 100%;

			&.border {
				border: none;
			}
		}
	}

	&.white-text {
		.#{$block-name}__settings-icon {
			background-color: $color-white;
		}

		a {
			color: white;
		}

		.#{$block-name}__wraper {
			border: none;

			&.border {
				border-bottom: 1px solid white;
			}

			@include breakpoint('medium') {
				border: none;

				&.border {
					border: none;
				}
			}
		}

		.#{$block-name}__navigation-link {
			&.nav-active {
				.link-title::after {
					background-color: white;
				}
			}
		}

		.#{$block-name}__seting-icon {
			path {
				fill: white;
			}
		}

		.#{$block-name}__setting-icon {
			path {
				fill: white;
			}
		}

		.#{$block-name}__search-icon,
		.#{$block-name}__user-icon {
			circle {
				stroke: white;
			}

			path {
				stroke: white;
			}

			line {
				stroke: white;
			}
		}
		.#{$block-name}__bar-top,
		.#{$block-name}__bar-mid,
		.#{$block-name}__bar-bot {
			background-color: white;
		}

		.#{$block-name}__search-container {
			& input {
				color: $color-white;
				border: none;
				border-bottom: 1px solid $color-white;
				background-color: transparent;

				&:focus {
					outline: 0;
				}

				&::placeholder {
					color: $color-white;
				}
			}
		}

		.#{$block-name}__search-icon-hidden {
			path {
				fill: white;
			}
		}
	}

	// .#{$block-name}__navigation-link {
	// 	&.nav-active {
	// 		padding-right: 20px;
	// 	}
	// }

	&.black-text {
		.#{$block-name}__settings-icon {
			background-color: $color-dark-grey;
		}

		.#{$block-name}__search-icon-hidden {
			path {
				fill: $color-dark-grey;
			}
		}

		a {
			color: $color-dark-grey;
		}

		.#{$block-name}__wraper {
			// border-bottom: 1px solid $color-dark-grey;
			border: none;

			&.border {
				border-bottom: 1px solid $color-dark-grey;
			}

			@include breakpoint('medium') {
				border: none;

				&.border {
					border: none;
				}
			}
		}

		.#{$block-name}__navigation-link {
			&.nav-active.link-title::after {
				background-color: $color-dark-grey;
			}
		}

		.#{$block-name}__setting-icon {
			path {
				fill: $color-dark-grey;
			}
		}

		.#{$block-name}__search-icon,
		.#{$block-name}__user-icon {
			circle {
				stroke: $color-dark-grey;
			}

			path {
				stroke: $color-dark-grey;
			}

			line {
				stroke: $color-dark-grey;
			}
		}
		.#{$block-name}__bar-top,
		.#{$block-name}__bar-mid,
		.#{$block-name}__bar-bot {
			background-color: $color-dark-grey;
		}

		.#{$block-name}__search-container {
			& input {
				color: $color-dark-grey;
				border: none;
				border-bottom: 1px solid $color-dark-grey;
				background-color: transparent;

				&:focus {
					outline: 0;
				}

				&::placeholder {
					color: $color-dark-grey;
				}
			}
		}
	}

	&__navigation {
		display: none;
		margin-right: auto;
		padding-left: 56px;

		@include breakpoint(1200) {
			display: flex;
		}
	}

	&__navigation-link {
		display: flex;
		align-items: center;
		padding-right: 35px;
		font-size: 12px;

		@include breakpoint(1500) {
			font-size: 16px;
		}

		&:last-child {
			margin-right: 0;
		}

		&.nav-active {
			padding-right: 55px;
		}

		& a {
			color: #4d4d55;
			padding-right: 0;
			text-decoration: none;
		}
	}

	& .c-button-blue {
		padding: 10px 15px;
		margin-right: 0;

		&:hover {
			padding: 9px 14px !important;
		}
	}

	.nav-active {
		& a {
			padding-right: 0 !important;

			&.pointer {
				cursor: pointer;
			}
		}

		&:hover {
			& a {
				color: #08b3f0;
			}

			.#{$block-name}__menu {
				transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
				opacity: 1;
				max-height: 5000px;
				z-index: 50;
				visibility: visible;
			}

			.link-title::after {
				transform: rotate(180deg);
				top: 45%;
				background-color: #08b3f0;
			}
		}

		.link-title {
			position: relative;
		}

		.link-title::after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -20px;
			display: block;
			width: 10px;
			height: 10px;
			margin-left: 8px;
			-webkit-mask-image: url('/images/new.k2.img/Vector 1.svg');
			mask-image: url('/images/new.k2.img/Vector 1.svg');
			-webkit-mask-size: cover;
			mask-size: cover;
			-webkit-mask-position: center;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			background-color: $color-dark-grey;
		}
	}

	.border-left {
		border-left: 4px solid transparent;
	}

	&__sidebar-link-a {
		& .active-img {
			margin-left: 8px;
			display: none;
		}

		&.podcasts {
			position: relative;
			display: block;

			&::before {
				position: absolute;
				top: 3px;
				left: -20px;
				content: '';
				background-color: $color-orange;
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				transform: scale(1);
				animation: pulse 2s infinite;
			}
		}
	}

	@keyframes pulse {
		0% {
			transform: scale(0.7);
		}

		70% {
			transform: scale(1);
		}

		100% {
			transform: scale(0.7);
		}
	}

	.active-menu-item {
		background-color: #f6f8fb;
		border-left: 4px solid $color-blue;
		display: flex;
		align-items: center;

		&.visible {
			& .active-img {
				display: inline-block;
			}
		}
	}

	.hidden {
		display: none;
	}

	.active {
		display: block;
	}

	&__menu {
		background-color: white;
		width: 100%;
		padding: 40px 0 40px;
		position: absolute;
		left: 0;
		top: 139px;
		visibility: hidden;
		height: auto;
		display: flex;
		justify-content: center;
		// transition: visibility 0.2s;
		opacity: 0;
		max-height: 0;
		z-index: -1;
		box-shadow: 0 5px 10px 0 rgba(0 0 0 / 10%);
		transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
		// box-shadow: 0 0 25px rgb(0 0 0 / 10%);

		&:hover {
			opacity: 1;
			max-height: 5000px;
			z-index: 50;
			visibility: visible;
		}
	}

	&__sidebar-wrapper {
		display: flex;
		width: calc(86%);
		max-width: 1600px;
	}

	&__sidebar-link {
		padding: 16px 0;
		font-size: 16px;
		cursor: pointer;

		& a {
			color: $color-dark-grey !important;
		}
	}

	&__sidebar {
		display: flex;
		flex-direction: column;
		max-width: 312px;
		flex: 0 0 auto;
		width: 100%;
		border-right: 1px solid rgba(77, 77, 85, 0.4);

		& input {
			margin-top: 24px;
			width: 260px;
			height: 48px;
			padding-right: 45px;
			border-radius: 3px;
			border: 2px solid rgba(77, 77, 85, 0.2);
			padding-left: 16px;

			&:focus {
				outline: none;
			}

			&::placeholder {
				padding: 14px 0;
				color: rgba(77, 77, 85, 1);
				font-size: fs(16);
				line-height: 20px;
			}
		}
	}

	&__sidebar-content {
		width: 100%;
		display: flex;
		margin-top: -40px;
		justify-content: flex-start;
		margin-left: -20px;
		flex-wrap: wrap;
		height: 1%;

		& a {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;

			&.news {
				justify-content: space-between;
			}
		}

		&.title-svg {
			width: 16px;
			height: 18px;
		}

		& a:hover {
			p {
				color: #08b3f0;
			}

			.title-svg circle {
				stroke: #08b3f0;
			}

			.title-svg path {
				stroke: #08b3f0;
			}

			.title-svg line {
				stroke: #08b3f0;
			}
		}
	}

	&__sidebar-content-input {
		position: relative;
	}

	&__search {
		position: absolute;
		cursor: pointer;
		top: 38px;
		right: 70px;
		content: "";
		width: 24px;
		height: 24px;
		display: block;
		// background-image: url('/images/k2-icons/search.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	&__sidebar-content-teg {
		display: block !important;
		width: 100%;
		margin-top: 40px;
		height: 1px;
	}

	&__img-events {
		// width: 100%;
	}

	&__sidebar-content-item {
		margin-top: 40px;
		width: calc(100% / 3 - 64px);
		margin-left: 64px;

		&.negative-margin {
			margin-top: 0 !important;
		}

		&.teg {
			margin-top: 0;
		}

		&.last {
			margin-right: auto;
		}

		&:hover {
			.#{$block-name}__img-pic {
				background-color: $color-blue;
			}
		}

		.#{$block-name}__img-pic {
			width: 24px;
			height: 24px;
			margin-right: 11px;
			flex: 0 0 auto;
			// filter: brightness(0);

			display: block;
			// -webkit-mask-image: url('/images/new.k2.img/Mask Group.png');
			// mask-image: url('/images/new.k2.img/Mask Group.png');
			-webkit-mask-size: contain;
			mask-size: contain;
			-webkit-mask-position: center;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			// width: 100%;
			// height: 100%;
			background-color: black;
		}

		&.opacity {
			opacity: 0.6;
		}
	}

	&__sidebar-content-title {
		font-size: 16px;
		font-weight: 700;
		display: flex;
		align-items: center;
		padding-top: 16px;

		& img {
			padding-top: 2px;
		}

		&.news {
			padding-top: 8px;

			& p {
				margin-left: 0;
			}
		}

		&.teg {
			&:hover {
				color: #08b3f0;
			}
		}

		&.c-body-text.teg {
			font-weight: 400;
		}

		& p {
			padding-bottom: 0;
			// margin-left: 11px;
			color: #4d4d55;
		}
	}

	&__menu-about {
		width: calc(86%);
		max-width: 1600px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 80px;
		grid-row-gap: 40px;
	}

	&__sidebar-content-header {
		display: flex;
		align-items: center;
	}

	&__sidebar-content-about {
		font-size: 16px;
		font-weight: 700;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		padding-top: 16px;

		& img {
			padding-top: 2px;
			width: 24px;
			height: 24px;
			filter: brightness(0);
		}

		& p {
			padding-bottom: 0;
			margin-left: 11px;
			line-height: 100%;
			color: #4d4d55;
		}

		&:hover {
			& p {
				color: $color-blue;
			}

			& img {
				filter: brightness(1);
			}
		}
	}

	&__sidebar-content-announce {
		font-size: 16px;
		color: #08b3f0;
		margin-top: 16px;

		&.news {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			justify-content: space-between;

			& span {
				color: $color-dark-grey;
			}
		}
	}

	&__sidebar-content-description {
		color: $color-dark-grey;
		font-size: 16px;
		font-weight: 400;
		margin-top: 8px;
		opacity: 0.9;
	}

	&__menu-news-items {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__demo {
		margin-right: 0;
		padding: 10px 16px;
		display: none;

		@include breakpoint(1200) {
			display: block;
		}

		&:hover {
			padding: 9px 15px;
		}
	}

	//бургер мобил
	&__navigation-mobile {
		display: flex;
		flex-direction: column;
	}

	&__wraper-mobile {
		display: flex;
	}

	&__nav-toggle {
		cursor: pointer;
		margin-left: 88px;
		position: relative;
		display: block;

		@include breakpoint(1200) {
			display: none;
		}

		&::before {
			content: "";
			width: calc(100% + 30px);
			height: calc(100% + 30px);
			position: absolute;
			top: -15px;
			right: -15px;
		}

		&.active {
			padding-bottom: 6px;
			.#{$block-name}__bar-top {
				transform: rotate(45deg);
				transform-origin: -24% 215%;
			}
			.#{$block-name}__bar-mid {
				opacity: 0;
			}
			.#{$block-name}__bar-bot {
				transform: rotate(-45deg);
				transform-origin: 12% 95%;
			}
		}
	}

	&__bar-top,
	&__bar-mid,
	&__bar-bot {
		background-color: $color-dark-grey;
		display: block;
		height: 2px;
		width: 24px;
		margin-top: 8px;
	}

	&__bar-top {
		margin-top: 0;
	}

	&__help {
		color: $color-dark-grey;
		display: none;
		align-items: center;

		@include breakpoint('medium') {
			display: flex;
		}

		@include breakpoint(1200) {
			display: none;
		}

		& a {
			color: $color-dark-grey;
			text-decoration: none;
		}
	}

	&__help-item {
		display: block;
		position: relative;
		margin-right: 32px;
		width: 16px;
		height: 16px;
		cursor: pointer;
		border-bottom: 1px solid transparent;

		&.hiden {
			visibility: hidden;
		}

		& img {
			cursor: pointer;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__lang {
		margin-right: 32px;
	}

	&__search-container {
		position: absolute;
		top: -5px;
		left: 0;
		width: 0;
		transition: 0.5s;
		overflow: hidden;
		cursor: pointer;

		@include breakpoint('medium') {
			top: -7px;
			left: -20px;
		}

		&--opened {
			width: 200px;

			.#{$block-name}__search-icon-hidden {
				display: block;
			}
		}
	}

	&__search-icon-hidden {
		width: 16px;
		height: 16px;
		position: absolute;
		top: 4px;
		right: 0;
		display: none;
		visibility: visible;

		// path {
		// 	fill: white;
		// }
	}

	&__help-item {
		& input {
			visibility: visible;
			color: $color-dark-grey;
			border: none;
			border-radius: 0;
			-webkit-appearance: none;
			border-bottom: 1px solid $color-dark-grey;
			background-color: transparent;
			padding: 2px 18px;

			&:focus {
				outline: 0;
			}

			&::placeholder {
				color: $color-dark-grey;
			}
		}
	}

	&__help-mobile {
		// display: flex;
		align-items: center;
		display: none;

		&.active {
			display: flex;
		}

		@include breakpoint('medium') {
			display: none;

			&.active {
				display: none;
			}
		}

		& a {
			color: $color-dark-grey;
			text-decoration: none;
		}
	}

	&__menu-mobile {
		display: none;
		overflow: auto;
		position: absolute;
		flex-direction: column;
		background-color: white;
		width: 100%;
		height: 100%;
		top: 120px;
		left: 0;

		&.active {
			display: flex !important;
			align-items: center;
		}

		@include breakpoint('medium') {
			top: 104px;
		}
	}

	&__menu-mobile-container {
		position: relative;
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		padding-left: 20px;
		padding-right: 20px;

		@include breakpoint('large') {
			padding-left: 0;
			padding-right: 0;
			width: calc(86%);
		}
	}

	&__menu-mobile-wrapper {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		align-items: flex-start;
		// overflow-y: auto;
	}

	&__setting-icon {
		width: 16px;
		height: 16px;
	}

	// &__settings-icon {
	// 	// display: block;
	// 	// background-image: url('/images/new.k2.img/Mask Group.png');
	// 	// background-size: contain;
	// 	// background-repeat: no-repeat;
	// 	// background-position: center;
	// 	// width: 100%;
	// 	// height: 100%;
	// 	-webkit-mask-image: url('/images/new.k2.img/Mask Group.png');
	// 	mask-image: url('/images/new.k2.img/Mask Group.png');
	// 	-webkit-mask-size: contain;
	// 	mask-size: contain;
	// 	-webkit-mask-position: center;
	// 	mask-position: center;
	// 	-webkit-mask-repeat: no-repeat;
	// 	mask-repeat: no-repeat;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: $color-dark-grey;
	// }

	&__menu-mobile {
		& .h1 {
			padding-bottom: 0;
			margin: 0;
			margin-top: 32px;
			font-size: 24px;
			line-height: 33px;
			cursor: pointer;

			& a {
				font-size: 24px;
				line-height: 33px;
			}

			&.arrow {
				display: flex;
				align-items: center;

				&::after {
					content: "";
					flex: 0 0 auto;
					display: block;
					width: 16px;
					height: 16px;
					margin-left: 8px;
					-webkit-mask-image: url('/images/new.k2.img/Vector 1.svg');
					mask-image: url('/images/new.k2.img/Vector 1.svg');
					-webkit-mask-size: cover;
					mask-size: cover;
					-webkit-mask-position: center;
					mask-position: center;
					-webkit-mask-repeat: no-repeat;
					mask-repeat: no-repeat;
					background-color: #4d4d55;
				}
			}
		}

		& .h4 {
			font-size: 16px;
			line-height: 24px;
			margin-top: 24px;
			cursor: pointer;

			&.arrow {
				display: flex;
				align-items: center;

				&::after {
					content: "";
					flex: 0 0 auto;
					display: block;
					width: 10px;
					height: 10px;
					margin-left: 8px;
					-webkit-mask-image: url('/images/new.k2.img/Vector 1.svg');
					mask-image: url('/images/new.k2.img/Vector 1.svg');
					-webkit-mask-size: cover;
					mask-size: cover;
					-webkit-mask-position: center;
					mask-position: center;
					-webkit-mask-repeat: no-repeat;
					mask-repeat: no-repeat;
					background-color: #4d4d55;
				}
			}
		}

		& a {
			font-size: 16px;
			line-height: 24px;
			color: $color-dark-grey;
			display: block;
			text-decoration: none;
		}
	}

	&__menu-mobile-sub {
		display: none;
		margin-top: 8px;
		padding-left: 60px;

		& a {
			font-size: 16px;
			line-height: 24px;
			margin-top: 24px;
			display: block;
			text-decoration: none;
		}
	}

	&__menu-mobile-title {
		&:first-child {
			& .h1 {
				margin-top: 0;
			}
		}

		&.active {
			.#{$block-name}__menu-mobile-sub {
				display: block;
			}

			& .h1 {
				border-left: none;
				margin-left: 0;
				padding-left: 0;
				color: $color-blue;

				@include breakpoint('medium') {
					// border-left: 4px solid $color-blue;
					// margin-left: -20px;
					// padding-left: 16px;
				}

				&.arrow {
					&::after {
						background-color: $color-blue;
					}
				}
			}
		}
	}

	&__menu-mobile-sub-title {
		&.active {
			.#{$block-name}__menu-mobile-elem {
				display: block;
			}
		}
	}

	&__menu-mobile-elem {
		display: none;
		margin-top: 8px;

		& a {
			margin-top: 16px;
			display: block;
			text-decoration: none;
			font-weight: 400;
		}
	}

	&__menu-mobile-sub-link {
		display: inline-block;
	}

	&__mobile-button {
		font-weight: 400;
		margin-top: 32px;

		&.c-button-blue-light {
			padding: 9.5px 16px;

			&:hover {
				padding: 8.5px 15px;
			}
		}
	}

	&__mobile-help {
		border-top: 1px solid #dbdbdd;
		margin-top: auto;
		height: 69px;
		flex: 0 0 auto;
		padding-right: 6px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include breakpoint('medium') {
			height: 85px;
		}

		& a {
			margin-bottom: 0;
			margin-top: 0;
			font-weight: 400;
			font-size: 14px;

			@include breakpoint('medium') {
				font-size: 16px;
			}
		}
	}

	.ps__rail-y {
		display: block;
		// left: 0;
		background-color: white;
		width: 6px;
		opacity: 1;

		.ps--clicking {
			background-color: #f6f8fb;
			opacity: 1;

			.ps__thumb-y {
				width: 6px;
				background-color: $color-blue;
			}
		}

		&:hover {
			background-color: #f6f8fb;
			opacity: 1;

			.ps__thumb-y {
				background-color: $color-blue;
				width: 6px;
				opacity: 1;
			}
		}
	}

	.ps .ps__rail-y.ps--clicking {
		background-color: #f6f8fb;
		opacity: 1;
	}

	.ps__rail-y.ps--clicking .ps__thumb-y {
		background-color: $color-blue;
		width: 6px;
	}

	.ps:hover > .ps__rail-y {
		opacity: 1;
	}

	.ps__thumb-y {
		cursor: pointer;
		left: 0;
		width: 6px;
		background-color: $color-blue;
	}
}
