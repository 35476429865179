@import "~style";

$block-name: 'b-customers';

.#{$block-name} {
	background-color: $color-light-blue;
	overflow: hidden;
	position: relative;
	// min-height: 100vh;

	&__wrapper {
		// padding-top: 60px;
		padding-bottom: 128px;
	}

	&__container {
		margin: 0 auto;
	}

	&__list {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.tao-list {
		margin-bottom: 48px;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 32px;
		grid-row-gap: 32px;

		@include breakpoint(medium) {
			grid-template-columns: 1fr 1fr;
		}

		@include breakpoint(large) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	&__pagination {
		// display: flex;
		// justify-content: space-between;
		// align-items: center;
		// flex-direction: column;

		// @include breakpoint(medium) {
		// 	justify-content: space-between;
		// 	align-items: center;
		// 	flex-direction: row;
		// }
	}

	&__button {
		margin: 0 auto;
		display: flex;

		&.hidden {
			display: none;
		}
	}

	& .c-button-blue-light {
		margin: 0 auto;
		margin-bottom: 32px;
		//margin-right: 0;
		&:hover {
			& a {
				color: $color-dark-grey;
			}
		}

		& a {
			color: white;
			text-decoration: none;
		}
	}

	&__show {
		margin-left: 20px;
		margin-top: 32px;

		@include breakpoint(medium) {
			margin-top: 0;
		}
	}

	&__item {
		height: 180px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.5s;
		position: relative;
		margin-bottom: 10px;
		background: rgba(0, 0, 0, 0.1);
		width: 100%;
		border-radius: 3px;

		&:hover {
			background: $color-white;

			.b-customers__item-text {
				opacity: 1;
			}

			.b-customers__item-image {
				opacity: 0;
			}
		}

		@include breakpoint(medium) {
			width: calc(50% - 10px);
			margin: 0 5px 10px;
			height: 210px;
		}

		@include breakpoint(large) {
			width: calc(50% - 70px);
			margin: 0 35px 70px;
			height: 260px;
		}

		@include breakpoint(xlarge) {
			width: calc(33% - 60px);
			margin: 0 30px 60px;
			height: 237px;
		}
	}

	&__item-image {
		transition: 0.5s;
		max-height: 100%;
	}

	&__item-text {
		font-size: 16px;
		line-height: 21px;
		color: $color-gray;
		letter-spacing: 0.05px;
		display: block;
		opacity: 0;
		text-align: center;
		position: absolute;
		width: 210px;
		transition: 0.5s;
		top: 50px;
		left: calc(50% - 105px);

		@include breakpoint(large) {
			top: 90px;
		}
	}

	&__title {
		font-size: 40px;
		line-height: 52px;
		padding-bottom: 0;
		margin-top: 62px;
		margin-bottom: 32px;
		font-weight: 400;

		@include breakpoint(medium) {
			font-size: 48px;
			line-height: 62px;
			margin-bottom: 56px;
			margin-top: 88px;
		}

		@include breakpoint(large) {
			font-size: 64px;
			line-height: 70px;
			margin-bottom: 64px;
		}
	}

	&__filter-container {
		@include breakpoint(medium) {
			display: flex;
			align-items: flex-start;
			margin: 0 -16px;
		}

		@include breakpoint(xlarge) {
			justify-content: inherit;
			position: relative;
			height: 60px;
			margin-left: 0 -16px;
		}
	}

	&__filter-item {
		display: flex;
		justify-content: center;
		align-items: center;
		// background: rgba(0, 0, 0, 0.1);
		flex-direction: column;
		transition: 0.5s;
		border-radius: 3px;
		position: relative;

		&--opened {
			background-color: $color-white;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		@include breakpoint(medium) {
			width: 100%;

			@include breakpoint(medium) {
				width: calc(100% / 2 - 32px);
				margin: 0 16px;
			}

			@include breakpoint(large) {
				width: calc(100% / 3 - 32px);
			}
		}
	}

	&__filter-solution {
		@include breakpoint(xlarge) {
			// left: 119px;
		}

		&.b-customers__filter-item--opened {
			@include breakpoint(xlarge) {
				// left: 0;
				// width: calc(50% - 7.5px);
			}
		}
	}

	&__filter-branch {
		margin-top: 10px;

		@include breakpoint(medium) {
			margin-top: 0;
		}

		@include breakpoint(xlarge) {
			// right: 119px;
		}

		&.b-customers__filter-item--opened {
			@include breakpoint(xlarge) {
				right: 0;
				// width: calc(50% - 7.5px);
			}
		}
	}

	&__filter-list-container {
		// border: 1px solid black;
		position: relative;
		padding: 24px;
		height: 295px;
		font-size: 16px;
		color: $color-gray-content;
		box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);
		border-radius: 5px;

		@include breakpoint(large) {
			height: 300px;
		}
	}

	&__filter-list {
		position: absolute;
		display: none;
		opacity: 0;
		transition: 0.5s opacity;
		background-color: $color-white;
		z-index: 10;
		top: 60px;
		width: 100%;
		border-radius: 3px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		left: 0;
		overflow: hidden;
		box-shadow: 0 5px 10px 0 rgba(0 0 0 / 10%);
		// box-shadow: 4px 4px 25px rgb(0 0 0 / 10%);

		&--opened {
			opacity: 1;
		}
	}

	&__filter-list-item {
		cursor: pointer;
		margin-bottom: 15px;
		line-height: 1em;
		display: flex;
		align-items: center;
		color: $color-dark-grey;

		&::before {
			content: '';
			width: 24px;
			height: 24px;
			background-image: url('/images/icons/checkbox.svg');
			background-position: center;
			background-repeat: no-repeat;
			flex-shrink: 0;
			margin-right: 16px;
			display: inline-block;
		}

		&--selected {
			&::before {
				background-image: url('/images/icons/icon_checkbox.svg');
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		@include breakpoint(xlarge) {
			margin-bottom: 30px;
		}
	}

	&__filter-text {
		font-size: 16px;
		color: $color-dark-grey;
		padding: 0 24px;
		line-height: 1em;
		width: 100%;
		height: 56px;
		display: flex;
		border: 2px solid $color-blue;
		border-radius: 5px;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		&::after {
			content: '';
			display: inline-block;
			width: 24px;
			height: 24px;
			margin-left: 20px;
			background-image: url('/images/new.k2.img/arrow_down.svg');
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&__filter {
		margin-bottom: 40px;

		@include breakpoint(medium) {
			margin-bottom: 48px;
		}

		@include breakpoint(large) {
			margin-bottom: 64px;
		}
	}

	&__filter-items-selected {
		@include breakpoint(medium) {
			display: flex;
			flex-flow: wrap row;
			margin: 0 -10px;
		}

		@include breakpoint(xlarge) {
			width: calc(80% + 35px);
			margin: 0 auto;
			margin-bottom: 30px;
		}
	}

	&__selected-item {
		font-size: 12px;
		line-height: 1em;
		color: $color-light-blue;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 3px;
		background: $color-dark-grey;
		border: 1px solid;
		padding: 7px 8px;
		margin: 20px 0;

		&:last-of-type {
			margin-bottom: 29px;

			@include breakpoint(medium) {
				margin: 10px;
			}

			@include breakpoint(xlarge) {
				margin: 10px 17.5px;
			}
		}

		@include breakpoint(medium) {
			margin: 10px;
		}

		@include breakpoint(xlarge) {
			margin: 10px 17.5px;
		}
	}

	&__selected-item-close {
		width: 12px;
		height: 12px;
		background-image: url(/images/main_redisign/pages/close.png);
		background-repeat: no-repeat;
		background-position: center;
		margin-left: 20px;
		flex-shrink: 0;
		cursor: pointer;
	}

	.tao-page-navigator {
		display: none;
	}

	.ps__rail-y {
		display: block;
		opacity: 1 !important;
		background-color: $color-blue;
		min-width: 6px;
		border-radius: 3px;
	}

	.ps__thumb-y {
		max-width: 6px;
		// transform: translateX(1px);
		background-color: $color-blue;
		border-radius: 3px;
		height: 100px !important;
		// cursor: grab !important;
	}

	.ps__rail-y {
		height: 295px !important;
		background-color: transparent;

		@include breakpoint(large) {
			height: 300px !important;
		}
	}

	.ps__rail-y:hover > .ps__thumb-y {
		background-color: $color-blue;
		width: 6px;
	}

	.ps__rail-y.ps--clicking .ps__thumb-y {
		background-color: $color-blue;
	}
}
